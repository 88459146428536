import Stack, { StackProps } from '@mui/material/Stack';

interface TabPanelUniqueProps {
  name: string;
  value: string;
  ariaName?: string;
}

type TabPanelProps = TabPanelUniqueProps & StackProps;

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, name, ariaName, ...other } = props;
  const isCurrentTab = value === name;
  return (
    <Stack
      role='tabpanel'
      hidden={!isCurrentTab}
      id={`tabpanel-${ariaName ?? name}`}
      aria-labelledby={`tab-${ariaName ?? name}`}
      position='relative'
      {...other}
    >
      {isCurrentTab && children}
    </Stack>
  );
};
