import { Outlet } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { AuthProvider } from '@/contexts/auth';
import { Layout } from '@/components/Layout';
import { Protected } from '@/components/Protected';
import { ScoringProvider } from '@/contexts/scoring/provider';
import { PreferencesProvider } from '@/contexts/preferences/provider';
import { SnacksProvider } from '@/contexts/snacks/provider';
import { StatsProvider } from '@/contexts/stats/provider';
import { VisualNotificationsProvider } from '@/contexts/visualNotifications/provider';
import { ComposeComponents } from './utils/ComposeComponents';
import { UiStateProvider } from './contexts/UIState/provider';
import { ChecklistProvider } from './contexts/checklist/provider';
import { NotificationsProvider } from './contexts/notifications/provider';

export function App() {
  return (
    <ComposeComponents
      components={[
        AuthProvider,
        PreferencesProvider,
        CssBaseline,
        SnacksProvider,
        Protected,
        NotificationsProvider,
        ScoringProvider,
        VisualNotificationsProvider,
        UiStateProvider,
        ChecklistProvider,
        StatsProvider,
      ]}
    >
      <Layout>
        <Outlet />
      </Layout>
    </ComposeComponents>
  );
}
