import { CSSProperties } from 'react';
import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  FixtureScoreTeamColorBox,
  FixtureScoreTeamInPlayColorBox,
} from '@/components/FixtureScore/FixtureScoreStyledComponents';

const ColorBoxWrapper = styled(Stack)(({ theme }) => ({
  width: '0.53em',
  alignItems: 'center',
}));

interface FixtureScoreTeamBoxProps {
  inPlay?: boolean;
  teamColor?: string;
  homeTeam?: boolean;
}

export const FixtureScoreTeamBox = ({
  inPlay,
  homeTeam,
  teamColor,
}: FixtureScoreTeamBoxProps) => {
  if (inPlay) {
    return (
      <ColorBoxWrapper>
        <FixtureScoreTeamInPlayColorBox
          homeTeam={homeTeam}
          style={
            { '--teamColor': teamColor && `rgb(${teamColor})` } as CSSProperties
          }
        />
      </ColorBoxWrapper>
    );
  }

  return (
    <ColorBoxWrapper>
      <FixtureScoreTeamColorBox
        style={
          { '--teamColor': teamColor && `rgb(${teamColor})` } as CSSProperties
        }
      />
    </ColorBoxWrapper>
  );
};
