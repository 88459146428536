import { AmericanFootballStatsButtons } from '@/components/StatsButtons/AmericanFootballStatsButtons';
import { AmericanFootballStatsTable } from '@/components/AmericanFootballStatsTable/AmericanFootballStatsTable';

export const FixtureAmericanFootballStats = () => {
  return (
    <>
      <AmericanFootballStatsButtons />
      <AmericanFootballStatsTable />
    </>
  );
};
