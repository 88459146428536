import { TableRow } from '@mui/material';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { getActionName } from '@/components/FixtureTabs/helpers/getActionName';
import { getActionSubTypeName } from '@/components/FixtureTabs/helpers/getActionSubTypeName';
import { ScoringContext } from '@/contexts/scoring/context';
import { useAuthToken } from '@/contexts/auth/useAuthToken';
import { useFixtureConfig } from '@/service/hooks/useFixtureConfig';
import { getPlayerName } from '@/components/FixtureTabs/helpers/getPlayerNameAndNumber';
import { getTeamName } from '@/components/FixtureTabs/helpers/getTeamName';
import {
  filterAdvancedTableData,
  generateTableHeader,
} from '@/components/AdvancedStatsTable/utils';
import {
  AdvancedStatsData,
  AdvancedStatsTableHeader,
} from '@/components/AdvancedStatsTable/types';
import {
  StatsTable,
  StatsTableContainer,
  StatsTableHead,
  StyledCol,
  TableRowWithHover,
} from '@/components/StatsTableCells/common';
import { StatsContext } from '@/contexts/stats/context';
import {
  StatsTableDataCell,
  StatsTableHeaderActionCell,
  StatsTableStickyDataCell,
  StatsTableStickyHeaderActionCell,
} from '@/components/StatsTableCells/StatsTableCells';
import { ActiveColumnType } from '@/components/StatsTableCells/types';

import { StatsTableBody } from '@/components/StatsTableBody/StatsTableBody';
import { InfoMessage } from '@/components/common/InfoMessage';

export const AdvancedStatsTable = () => {
  const {
    state: { fixtureId, fixtureSummary },
  } = useContext(ScoringContext);
  const {
    activeButtons: { quarter, type, team },
  } = useContext(StatsContext);
  const token = useAuthToken();
  const { data: fixtureConfig } = useFixtureConfig({ token, fixtureId });
  const [newTableHeader, setNewTableHeader] =
    useState<AdvancedStatsTableHeader>();
  const [activeColumn, setActiveColumn] = useState<ActiveColumnType>(null);
  const colGroupRef = useRef<HTMLTableColElement>(null);
  const advancedStatsData: AdvancedStatsData[] | undefined = useMemo(() => {
    return filterAdvancedTableData({
      activeQuarter: quarter,
      activeType: type,
      activeTeam: team,
      actions: fixtureSummary?.teamActionAggregates,
    });
  }, [fixtureSummary, quarter, type, team]);

  useEffect(() => {
    if (advancedStatsData) {
      setNewTableHeader(generateTableHeader(advancedStatsData));
    }
  }, [advancedStatsData]);

  const columnsInGroup = useMemo(() => {
    let columnsCounter = 1;
    return newTableHeader
      ?.map((action) => {
        let columns: number[] = [];
        action?.subActions.map(() => columns.push(columnsCounter++));
        columns.push(columnsCounter++);
        return columns;
      })
      .flat();
  }, [newTableHeader]);

  if (!advancedStatsData || advancedStatsData.length === 0) {
    return <InfoMessage message={`Advanced stats data doesn't exist...`} />;
  }

  return (
    <StatsTableContainer>
      <StatsTable>
        <colgroup ref={colGroupRef}>
          <col key={'col-names'} />
          {columnsInGroup?.map((index) => (
            <StyledCol
              key={`col-${index}`}
              index={index}
              activeColumn={activeColumn}
            />
          ))}
        </colgroup>
        <StatsTableHead>
          <TableRow>
            <StatsTableStickyHeaderActionCell rowSpan={2} key={'header-name'}>
              Name
            </StatsTableStickyHeaderActionCell>
            {newTableHeader?.map((action) => (
              <StatsTableHeaderActionCell
                colSpan={
                  !!action?.subActions.length ? action.subActions.length + 1 : 1
                }
                key={`header-${action?.actionId}`}
              >
                {action?.actionId &&
                  getActionName(action.actionId, fixtureConfig)}
              </StatsTableHeaderActionCell>
            ))}
          </TableRow>
          <TableRow>
            {newTableHeader?.map((action) => (
              <React.Fragment
                key={`subheader-action-wrapper-${action?.actionId}`}
              >
                {action?.subActions.map((subAction) => (
                  <StatsTableHeaderActionCell
                    key={`subheader-${subAction}-${action.actionId}`}
                  >
                    {getActionSubTypeName(subAction, fixtureConfig) ||
                      subAction}
                  </StatsTableHeaderActionCell>
                ))}
                <StatsTableHeaderActionCell
                  key={`subheader-${action?.actionId}`}
                >
                  Total
                </StatsTableHeaderActionCell>
              </React.Fragment>
            ))}
          </TableRow>
        </StatsTableHead>
        <StatsTableBody
          activeColumn={activeColumn}
          setActiveColumn={setActiveColumn}
          colGroupRef={colGroupRef}
        >
          {advancedStatsData.map((statistics) => (
            <TableRowWithHover
              key={`table-row-${statistics.playerId || statistics.teamId}`}
            >
              <StatsTableStickyDataCell>
                {statistics?.playerId
                  ? getPlayerName(
                      statistics.playerId,
                      fixtureConfig,
                      fixtureSummary
                    )
                  : statistics?.teamId
                  ? getTeamName(
                      statistics.teamId,
                      fixtureConfig,
                      fixtureSummary
                    )
                  : ''}
              </StatsTableStickyDataCell>
              {newTableHeader?.map((action) => (
                <React.Fragment key={`table-cell-${action?.actionId}`}>
                  {action?.subActions.map(
                    (subAction) =>
                      subAction && (
                        <StatsTableDataCell
                          key={`${action.actionId}-${subAction}-${statistics.playerId}`}
                        >
                          {statistics.statistics
                            ?.find(
                              (statisticsAction) =>
                                action.actionId === statisticsAction?.actionId
                            )
                            ?.subActionAggregates.find(
                              (aggregates) =>
                                aggregates.subActionId === subAction
                            )?.subActionAggregate || 0}
                        </StatsTableDataCell>
                      )
                  )}
                  <StatsTableDataCell
                    key={`${action?.actionId}-${statistics.playerId}`}
                  >
                    {statistics.statistics?.find(
                      (activeAction) =>
                        activeAction?.actionId === action?.actionId
                    )?.actionAggregate || 0}
                  </StatsTableDataCell>
                </React.Fragment>
              ))}
            </TableRowWithHover>
          ))}
        </StatsTableBody>
      </StatsTable>
    </StatsTableContainer>
  );
};
