import { Divider, DividerProps } from '@mui/material';
import { FC } from 'react';

export interface TextSeparatorProps extends DividerProps {
  color?: string;
}
export const TextSeparator: FC<TextSeparatorProps> = (props) => {
  const { color, sx, ...dividerProps } = props;

  return (
    <Divider
      sx={{
        borderRadius: 1,
        borderColor: color || 'currentcolor',
        ...sx,
      }}
      orientation='vertical'
      flexItem
      {...dividerProps}
    />
  );
};
