import { useCallback, useMemo } from 'react';
import { useLocalStorage, useSessionStorage } from 'usehooks-ts';
import { LS_KEY, SOUND_DEFAULT_PREFERENCES } from './constants';
import { SoundsPreferences, PreferencesActions } from './types';
import { deepEquals } from './utils';

export const useSoundsPreferences = () => {
  const [userDefaultSoundsPreferences, setUserDefaultSoundsPreferences] =
    useLocalStorage<SoundsPreferences>(
      LS_KEY.SOUNDS_PREFERENCES,
      SOUND_DEFAULT_PREFERENCES
    );

  const [soundsPreferences, setSoundsPreferences] =
    useSessionStorage<SoundsPreferences>(
      LS_KEY.SOUNDS_PREFERENCES,
      userDefaultSoundsPreferences
    );

  const restoreAppDefaultSoundsPreferences = useCallback(() => {
    setSoundsPreferences(SOUND_DEFAULT_PREFERENCES);
  }, [setSoundsPreferences]);

  const restoreUserDefaultSoundsPreferences = useCallback(() => {
    setSoundsPreferences(userDefaultSoundsPreferences);
  }, [setSoundsPreferences, userDefaultSoundsPreferences]);

  const saveUserDefaultSoundsPreferences: PreferencesActions['saveUserDefaultSoundsPreferences'] =
    useCallback(() => {
      return setUserDefaultSoundsPreferences(soundsPreferences);
    }, [soundsPreferences, setUserDefaultSoundsPreferences]);

  const updateSoundsPreferences: PreferencesActions['updateSoundsPreferences'] =
    useCallback(
      (newSettings) => {
        return setSoundsPreferences((prevSettings) => {
          return { ...prevSettings, ...newSettings };
        });
      },
      [setSoundsPreferences]
    );

  const updateSoundPreferences: PreferencesActions['updateSoundPreferences'] =
    useCallback(
      ({ soundId, soundUpdate }) => {
        return setSoundsPreferences((prevSettings) => {
          const newSounds = { ...prevSettings.sounds };
          newSounds[soundId] = { ...newSounds[soundId], ...soundUpdate };
          const newSettings = {
            ...prevSettings,
            sounds: newSounds,
          };
          return newSettings;
        });
      },
      [setSoundsPreferences]
    );

  const isSoundsPreferencesUserDefault = useMemo(
    () => deepEquals(soundsPreferences, userDefaultSoundsPreferences),
    [soundsPreferences, userDefaultSoundsPreferences]
  );
  const isSoundsPreferencesAppDefault = useMemo(
    () => deepEquals(soundsPreferences, SOUND_DEFAULT_PREFERENCES),
    [soundsPreferences]
  );

  return {
    soundsPreferences,
    isSoundsPreferencesAppDefault,
    isSoundsPreferencesUserDefault,
    updateSoundsPreferences,
    updateSoundPreferences,
    saveUserDefaultSoundsPreferences,
    restoreUserDefaultSoundsPreferences,
    restoreAppDefaultSoundsPreferences,
  };
};
