import { styled } from '@mui/material';
import { FC } from 'react';

export interface KeyboardButtonProps {
  theKey: string;
}
const KeyboardButtonSpan = styled('span')(({ theme }) => ({
  boxSizing: 'content-box',
  display: 'inline-block',
  width: 'min(1em, 0.75rem)',
  aspectRatio: '1 / 1',

  fontSize: 'min(1em, 0.75rem)',
  padding: theme.spacing(0.25),
  margin: theme.spacing(0.25),
  border: `2px solid ${theme.palette.primary.light}`,
  borderRadius: theme.shape.borderRadius,
  lineHeight: 1,
  textAlign: 'center',
  fontWeight: 700,
  textTransform: 'uppercase',
  color: 'white',
  background: theme.palette.primary.dark,
}));
export const KeyboardButton: FC<KeyboardButtonProps> = ({ theKey }) => {
  return <KeyboardButtonSpan>{theKey}</KeyboardButtonSpan>;
};
