import { HubConnectionState } from '@microsoft/signalr';
import {
  Actions,
  ActionType,
  FixtureAction,
  FixtureActionFlag,
  FixtureConnectionStatus,
  FixtureSummary,
  MatchSummaryTeam,
  Player,
  SocketRequestConfirmation,
  StatusReason,
} from '@/service/types';
import { FixtureChecklistElement } from '@/service/types/checklist';
import {
  CollectionStatusId,
  CoverageLevelId,
  FixtureStatusId,
} from '@/service/constants';
import { ScoringState } from '@/contexts/scoring/types';

// Messages from worker to host
/**
 * This enum contains action/message names dispatched by WebWorker.
 * Those messages are handled by main thread (worker host).
 * Most of them directly pass the corresponding data coming from WebSocket.
 */
export enum SCORING_WORKER_ACTION {
  WORKER_READY = 'workerReady',
  WS_CONNECTION = 'wsConnection',
  REQUEST_CONFIRMATION = 'requestConfirmationReceived',
  ACTIONS_RECEIVED = 'actionsReceived',
  ACTION_ADDED = 'actionAdded',
  ACTION_UPDATED = 'actionUpdated',
  SUMMARY_RECEIVED = 'summaryReceived',
  CONNECTION_STATUS_RECEIVED = 'connectionStatusReceived',
  CHECKLIST_RECEIVED = 'checklistReceived',
  CHECKLIST_ELEMENT_ADDED = 'checklistAdded',
  CHECKLIST_ELEMENT_REMOVED = 'checklistRemoved',
}

export type WorkerReadyMsg = {
  action: SCORING_WORKER_ACTION.WORKER_READY;
  payload: { isWorkerReady: boolean };
};
export type WebSocketConnectionMsg = {
  action: SCORING_WORKER_ACTION.WS_CONNECTION;
  payload: { wsConnection: HubConnectionState };
};
export type FixtureSummaryMsg = {
  action: SCORING_WORKER_ACTION.SUMMARY_RECEIVED;
  payload: { fixtureSummary: FixtureSummary };
};
export type FixtureConnectionStatusMsg = {
  action: SCORING_WORKER_ACTION.CONNECTION_STATUS_RECEIVED;
  payload: { fixtureConnectionStatus: FixtureConnectionStatus };
};
export type RequestConfirmationMsg = {
  action: SCORING_WORKER_ACTION.REQUEST_CONFIRMATION;
  payload: SocketRequestConfirmation;
};
export type FixtureActionsMsg = {
  action: SCORING_WORKER_ACTION.ACTIONS_RECEIVED;
  payload: { fixtureActions: Actions; checklistActions: FixtureAction[] };
};
export type NewFixtureActionMsg = {
  action: SCORING_WORKER_ACTION.ACTION_ADDED;
  payload: FixtureAction;
};
export type ChecklistReceivedActionMsg = {
  action: SCORING_WORKER_ACTION.CHECKLIST_RECEIVED;
  payload: {
    fixtureId: string;
    fixtureChecklistElements: FixtureChecklistElement[];
  };
};
export type ChecklistElementAddedActionMsg = {
  action: SCORING_WORKER_ACTION.CHECKLIST_ELEMENT_ADDED;
  payload: FixtureChecklistElement;
};
export type ChecklistElementRemovedActionMsg = {
  action: SCORING_WORKER_ACTION.CHECKLIST_ELEMENT_REMOVED;
  payload: FixtureChecklistElement['id'];
};

export type FixtureActionUpdatedActionMsg = {
  action: SCORING_WORKER_ACTION.ACTION_UPDATED;
  payload: FixtureAction;
};

export type ScoringWorkerMsg =
  | WorkerReadyMsg
  | WebSocketConnectionMsg
  | FixtureSummaryMsg
  | FixtureConnectionStatusMsg
  | FixtureActionsMsg
  | NewFixtureActionMsg
  | RequestConfirmationMsg
  | ChecklistReceivedActionMsg
  | ChecklistElementAddedActionMsg
  | ChecklistElementRemovedActionMsg
  | FixtureActionUpdatedActionMsg;

export type ScoringWorkerMsgEvent = MessageEvent<ScoringWorkerMsg>;
// END: Messages from worker to host
// Messages from host to worker
/**
 * This enum contains action/message names dispatched by host.
 * Those messages are received by WebWorker and most of them
 * directly invoke a WebSocket action/message.
 */
export enum SCORING_WORKER_HOST_ACTION {
  LOG_ENABLE = 'log',
  KILL = 'kill',
  TOKEN = 'token',
  /**
   * An unified action which initially invokes many `fixtureId` related socket methods.
   */
  FIXTURE_ID = 'fixtureId',
  /**
   * Subscription methods
   */
  ACTIONS_SUBSCRIBE = 'subscribeActions',
  ACTIONS_UNSUBSCRIBE = 'unsubscribeActions',
  SUMMARY_SUBSCRIBE = 'subscribeSummary',
  SUMMARY_UNSUBSCRIBE = 'unsubscribeSummary',
  CONNECTION_STATUS_SUBSCRIBE = 'subscribeConnectionStatus',
  CONNECTION_STATUS_UNSUBSCRIBE = 'unsubscribeConnectionStatus',
  CHECKLIST_SUBSCRIBE = 'subscribeChecklist',
  CHECKLIST_UNSUBSCRIBE = 'unsubscribeChecklist',

  /**
   * Particural methods
   */
  CHECKLIST_ELEMENT_ADD = 'addChecklistElement',
  CHECKLIST_ELEMENT_REMOVE = 'removeChecklistElement',
  ACTION_COMMENT_ADD = 'addActionComment',
  ACTION_COMMENT_UPDATE = 'updateActionComment',
  ACTION_COMMENT_REMOVE = 'removeActionComment',
  POST_MATCH_CHECK_COMPLETE_SET = 'setPostMatchCheck',
  POST_MATCH_CHECK_COMPLETE_UNSET = 'unsetPostMatchCheck',
  FIXTURE_STATUSES_SET = 'setFixtureStatuses',
  UPDATE_ACTION_FLAG = 'updateActionFlag',
  UPDATE_FIXTURE_ACTION = 'updateFixtureAction',
  ADD_FIXTURE_ACTION = 'addFixtureAction',
}

export interface PayloadBase {
  fixtureId: string;
}
export interface RequestConfirmationPayload extends PayloadBase {
  requestId: string;
}
export type LogEnableMessage = {
  action: SCORING_WORKER_HOST_ACTION.LOG_ENABLE;
  payload: boolean;
};
export type KillMsg = {
  action: SCORING_WORKER_HOST_ACTION.KILL;
  payload: null;
};
export type TokenMsg = {
  action: SCORING_WORKER_HOST_ACTION.TOKEN;
  payload: string;
};
export type FixtureIdMsg = {
  action: SCORING_WORKER_HOST_ACTION.FIXTURE_ID;
  payload: PayloadBase & { oldFixtureId?: string };
};
export type FixtureSummarySubscriptionMsg = {
  action:
    | SCORING_WORKER_HOST_ACTION.SUMMARY_SUBSCRIBE
    | SCORING_WORKER_HOST_ACTION.SUMMARY_UNSUBSCRIBE;
  payload: PayloadBase;
};
export type FixtureActionsSubscriptionMsg = {
  action:
    | SCORING_WORKER_HOST_ACTION.ACTIONS_SUBSCRIBE
    | SCORING_WORKER_HOST_ACTION.ACTIONS_UNSUBSCRIBE;
  payload: PayloadBase;
};
export type FixtureConnectionStatusSubscriptionMsg = {
  action:
    | SCORING_WORKER_HOST_ACTION.CONNECTION_STATUS_SUBSCRIBE
    | SCORING_WORKER_HOST_ACTION.CONNECTION_STATUS_UNSUBSCRIBE;
  payload: PayloadBase;
};
export type FixtureChecklistSubscriptionMsg = {
  action:
    | SCORING_WORKER_HOST_ACTION.CHECKLIST_SUBSCRIBE
    | SCORING_WORKER_HOST_ACTION.CHECKLIST_UNSUBSCRIBE;
  payload: PayloadBase;
};

export type FixtureChecklistAddElementMsg = {
  action: SCORING_WORKER_HOST_ACTION.CHECKLIST_ELEMENT_ADD;
  payload: RequestConfirmationPayload & {
    type: FixtureChecklistElement['type'];
  };
};
export type FixtureChecklistRemoveElementMsg = {
  action: SCORING_WORKER_HOST_ACTION.CHECKLIST_ELEMENT_REMOVE;
  payload: RequestConfirmationPayload & {
    id: FixtureChecklistElement['id'];
  };
};

export type ActionCommentAddMsg = {
  action: SCORING_WORKER_HOST_ACTION.ACTION_COMMENT_ADD;
  payload: RequestConfirmationPayload & {
    fixtureActionId: FixtureAction['id'];
    comment: string;
  };
};

export type ActionCommentUpdateMsg = {
  action: SCORING_WORKER_HOST_ACTION.ACTION_COMMENT_UPDATE;
  payload: RequestConfirmationPayload & {
    id: string;
    comment: string;
  };
};

export type ActionCommentRemoveMsg = {
  action: SCORING_WORKER_HOST_ACTION.ACTION_COMMENT_REMOVE;
  payload: RequestConfirmationPayload & {
    id: string;
  };
};

export type UpdateFixtureActionMsg = {
  action: SCORING_WORKER_HOST_ACTION.UPDATE_FIXTURE_ACTION;
  payload: RequestConfirmationPayload & {
    fixtureActionId: FixtureAction['id'];
    fixtureId: ScoringState['fixtureId'];
    newFixtureActionTypeId?: ActionType['id'];
    newFixtureActionSubtypeId?: ActionType['id'];
    newTeamId: MatchSummaryTeam['id'] | null;
    newPlayerId?: Player['id'];
  };
};

export type SetPostMatchCheckCompleteMsg = {
  action:
    | SCORING_WORKER_HOST_ACTION.POST_MATCH_CHECK_COMPLETE_SET
    | SCORING_WORKER_HOST_ACTION.POST_MATCH_CHECK_COMPLETE_UNSET;
  payload: RequestConfirmationPayload;
};

export type SetFixtureStatusMsg = {
  action: SCORING_WORKER_HOST_ACTION.FIXTURE_STATUSES_SET;
  payload: RequestConfirmationPayload & {
    fixtureStatus: FixtureStatusId;
    fixtureStatusReason?: StatusReason['reason'];
    fixtureStatusReasonCode?: StatusReason['code'];
    collectionStatus: CollectionStatusId;
    collectionStatusReason?: StatusReason['reason'];
    collectionStatusReasonCode?: StatusReason['code'];
    coverageLevel: CoverageLevelId;
    coverageLevelReason?: StatusReason['reason'];
    coverageLevelReasonCode?: StatusReason['code'];
  };
};

export type UpdateActionFlagMsg = {
  action: SCORING_WORKER_HOST_ACTION.UPDATE_ACTION_FLAG;
  payload: RequestConfirmationPayload & FixtureActionFlag;
};

export type AddFixtureActionMsg = {
  action: SCORING_WORKER_HOST_ACTION.ADD_FIXTURE_ACTION;
  payload: RequestConfirmationPayload & {
    fixtureActionTypeId: FixtureAction['fixtureActionTypeId'];
    withLastFixtureActionParams: boolean;
  };
};

export type ScoringWorkerHostMsg =
  | LogEnableMessage
  | KillMsg
  | TokenMsg
  | FixtureIdMsg
  | FixtureSummarySubscriptionMsg
  | FixtureConnectionStatusSubscriptionMsg
  | FixtureActionsSubscriptionMsg
  | FixtureChecklistSubscriptionMsg
  | FixtureChecklistAddElementMsg
  | FixtureChecklistRemoveElementMsg
  | ActionCommentAddMsg
  | ActionCommentUpdateMsg
  | ActionCommentRemoveMsg
  | SetPostMatchCheckCompleteMsg
  | SetFixtureStatusMsg
  | UpdateActionFlagMsg
  | UpdateFixtureActionMsg
  | AddFixtureActionMsg;

export type ScoringWorkerHostEvent = MessageEvent<ScoringWorkerHostMsg>;
// END: Messages from host to worker

// Utility types to grab particular action type properties
export type ActionOf<T> = T extends ScoringWorkerHostMsg ? T['action'] : never;
export type PayloadOf<T> = T extends ScoringWorkerHostMsg
  ? T['payload']
  : never;

// interface for main thread instance
export interface ScoringAPIWorker
  extends Omit<
    Worker,
    'postMessage' | 'addEventListener' | 'removeEventListener'
  > {
  addEventListener(
    type: 'message',
    listener: (this: ScoringAPIWorker, ev: ScoringWorkerMsgEvent) => any,
    options?: boolean | AddEventListenerOptions
  ): void;
  removeEventListener(
    type: 'message',
    listener: (this: ScoringAPIWorker, ev: ScoringWorkerMsgEvent) => any,
    options?: boolean | EventListenerOptions
  ): void;

  postMessage(message: ScoringWorkerHostMsg, transfer: Transferable[]): void;
  postMessage(
    message: ScoringWorkerHostMsg,
    options?: StructuredSerializeOptions
  ): void;
}
