import { Button, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { FixtureAction, FixtureActionComment } from '@/service/types';
import { useCommentAction } from '@/components/ActionsDialog/useCommentAction';
import { COMMON_STRING } from '@/constants/dictionary';
import { CommentRemoveConfirmation } from '@/components/Comment/CommentRemoveConfirmation';

interface CommentButtonsProps {
  action: FixtureAction;
  onClose: VoidFunction;
  comment: FixtureActionComment;
  hasLocalChanges: boolean;
  hasRemoteChanges: boolean;
  onSyncClick: () => void;
}
export const getSyncTooltipTitle = (actionComment: FixtureActionComment) => {
  return (
    <>
      <Typography variant='subtitle2'>This comment has changed.</Typography>
      <Typography variant='subtitle2'>New comment:</Typography>
      <Typography variant='subtitle1'>{actionComment.comment}</Typography>
    </>
  );
};

export const CommentButtons = ({
  action,
  onClose,
  comment,
  hasLocalChanges,
  hasRemoteChanges,
  onSyncClick,
}: CommentButtonsProps) => {
  const { commentAction } = useCommentAction();
  const theme = useTheme();
  const [isOpenDeleteConfirmation, setIsOpenDeleteConfirmation] =
    useState(false);

  const handleCloseDeleteConfirmation = () =>
    setIsOpenDeleteConfirmation(false);
  const handleRemoveComment = () => {
    commentAction({ commentActionType: 'remove', comment });
    onClose();
  };

  return (
    <Stack flexDirection='row' gap={1} justifyContent='end'>
      <Tooltip
        open={isOpenDeleteConfirmation}
        disableHoverListener
        title={
          <CommentRemoveConfirmation
            onRemove={handleRemoveComment}
            onClose={handleCloseDeleteConfirmation}
          />
        }
        placement='left'
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: theme.palette.background.paper,
              boxShadow: theme.shadows[1],
            },
          },
        }}
      >
        <Button
          variant={'text'}
          disabled={isOpenDeleteConfirmation}
          onClick={() => {
            comment.id &&
            action.fixtureActionComments &&
            action.fixtureActionComments[0]?.comment
              ? setIsOpenDeleteConfirmation(true)
              : onClose();
          }}
        >
          {comment.id &&
          action.fixtureActionComments &&
          action.fixtureActionComments[0]?.comment
            ? COMMON_STRING.REMOVE
            : COMMON_STRING.CLOSE}
        </Button>
      </Tooltip>
      <Button
        variant={hasRemoteChanges ? 'outlined' : 'contained'}
        disabled={!hasLocalChanges || !comment.comment}
        onClick={() => {
          !action.fixtureActionComments ||
          !action.fixtureActionComments[0]?.comment
            ? commentAction({ commentActionType: 'add', comment })
            : commentAction({ commentActionType: 'update', comment });
          onClose();
        }}
      >
        {hasRemoteChanges
          ? COMMON_STRING.OVERWRITE
          : !action.fixtureActionComments ||
            !action.fixtureActionComments[0]?.comment
          ? COMMON_STRING.ADD
          : COMMON_STRING.UPDATE}
      </Button>
      {hasRemoteChanges && (
        <Tooltip
          sx={{ maxWidth: 500 }}
          placement='top'
          title={getSyncTooltipTitle(action.fixtureActionComments[0])}
        >
          <Button variant='contained' onClick={onSyncClick}>
            {COMMON_STRING.SYNC}
          </Button>
        </Tooltip>
      )}
    </Stack>
  );
};
