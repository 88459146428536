import { Dialog, DialogContent, DialogProps } from '@mui/material';
import React, { FC, PropsWithChildren } from 'react';
import { DialogTitleWithClose } from '../common/DialogTitleWithClose';

interface ActionsDialogProps {
  open: boolean;
  onClose: VoidFunction;
  title: string;
  fullWidth?: DialogProps['fullWidth'];
  maxWidth?: DialogProps['maxWidth'];
  sx?: DialogProps['sx'];
}

export const ActionsDialog: FC<PropsWithChildren<ActionsDialogProps>> = ({
  open,
  onClose,
  title,
  children,
  fullWidth = false,
  maxWidth = false,
  sx,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <DialogTitleWithClose close={onClose}>{title}</DialogTitleWithClose>

      <DialogContent
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 2,
          pt: 0,
          ...sx,
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};
