import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { FC } from 'react';
import { GENERIC_FILTER_VALUE, GenericFilterValue } from './constants';

export interface GenericFilterProps {
  value: GenericFilterValue;
  onValueChange: (value: GenericFilterValue) => void;
  labels: {
    unset: string;
    falsy: string;
    truthy: string;
    unknown?: string;
  };
}
export const GenericFilterRadios: FC<GenericFilterProps> = ({
  value,
  onValueChange,
  labels,
}) => {
  return (
    <RadioGroup
      value={value}
      onChange={({ target }) =>
        onValueChange(target.value as GenericFilterValue)
      }
    >
      <FormControlLabel
        value={GENERIC_FILTER_VALUE.UNSET}
        label={labels.unset}
        control={<Radio size='small' />}
      />
      <FormControlLabel
        value={GENERIC_FILTER_VALUE.FALSY}
        label={labels.falsy}
        control={<Radio size='small' />}
      />
      {labels.unknown && (
        <FormControlLabel
          value={GENERIC_FILTER_VALUE.UNKNOWN}
          label={labels.unknown}
          control={<Radio size='small' />}
        />
      )}
      <FormControlLabel
        value={GENERIC_FILTER_VALUE.TRUTHY}
        label={labels.truthy}
        control={<Radio size='small' />}
      />
    </RadioGroup>
  );
};
