import React, { useContext, useMemo, useState } from 'react';
import { TabPanel } from '@/components/common/TabPanel';
import { ScoringContext } from '@/contexts/scoring/context';
import { useUserRoles } from '@/service/hooks/useUserRoles';
import { generateTabs } from '@/components/FixtureTabs/helpers/generateTabs';
import { useAuthToken } from '@/contexts/auth/useAuthToken';
import { UIStateContext } from '@/contexts/UIState/context';
import { getSport } from '@/service/utils/getSport';
import { useFixtureConfig } from '@/service/hooks/useFixtureConfig';
import { FixtureTabs } from './FixtureTabs';

export const FixtureMain = () => {
  const token = useAuthToken();
  const { data: userRoles } = useUserRoles({ token });
  const {
    state: { fixtureId },
  } = useContext(ScoringContext);
  const { data: fixtureConfig } = useFixtureConfig({ token, fixtureId });
  const { setOpenedPanel } = useContext(UIStateContext);

  const sport = getSport(fixtureConfig);

  const fixtureTabs = useMemo(
    () => generateTabs({ sportId: sport && sport.id, userRoles }),
    [sport, userRoles]
  );

  const [currentTabName, setCurrentTabName] = useState<string>(
    fixtureTabs[0].name
  );

  const handleChangeTab = (
    event: React.SyntheticEvent,
    selectedTab: string
  ) => {
    setOpenedPanel(null);
    setCurrentTabName(selectedTab);
  };

  const isCurrentTab = (name: string) => name === currentTabName;

  return (
    <>
      <FixtureTabs
        tabValue={currentTabName}
        tabHandler={handleChangeTab}
        tabs={fixtureTabs}
      />
      {fixtureTabs.map(({ name, component }, index) => (
        <TabPanel
          key={name}
          name={name}
          value={currentTabName}
          sx={{
            overflow: 'auto',
            flex: isCurrentTab(name) ? 1 : 0,
          }}
        >
          {component}
        </TabPanel>
      ))}
    </>
  );
};
