import React from 'react';
import { FixtureActions } from '@/components/FixtureTabs/FixtureActions';
import { FixtureLineups } from '@/components/FixtureTabs/FixtureLineups';
import { FixtureAdvancedStats } from '@/components/FixtureTabs/FixtureAdvancedStats';
import { FixtureBasketballStats } from '@/components/FixtureTabs/FixtureBasketballStats';
import { FixtureScoresheet } from '@/components/FixtureTabs/FixtureScoresheet';
import { FixtureComparison } from '@/components/FixtureTabs/FixtureComparison';
import { SPORT_ID, USER_ROLE_ID } from '@/service/constants';
import { UserRoles } from '@/service/types';
import { FixtureAmericanFootballStats } from '@/components/FixtureTabs/FixtureAmericanFootballStats';

export interface FixtureTabsType {
  name: string;
  component: JSX.Element;
  isVisible: boolean;
}

interface GenerateTabsOptions {
  sportId?: SPORT_ID;
  userRoles?: UserRoles;
}

export const MAIN_TAB_NAME = {
  MATCH_DATA: 'Match Data',
  LINEUPS: 'Lineups',
  ADVANCED_STATS: 'Advanced stats',
  BASKETBALL_STATS: 'Basketball stats',
  AMERICAN_FOOTBALL_STATS: 'American Football stats',
  SCORESHEET: 'Scoresheet',
  QA: 'QA',
} as const;
export type MainTabName = (typeof MAIN_TAB_NAME)[keyof typeof MAIN_TAB_NAME];

export const generateTabs = ({ sportId, userRoles }: GenerateTabsOptions) => {
  const isSupervisorOrSuperAdmin = !userRoles
    ? false
    : userRoles.some(
        ({ id }) =>
          id === USER_ROLE_ID.SUPERVISOR || id === USER_ROLE_ID.SUPER_ADMIN
      );

  const tabs: FixtureTabsType[] = [
    {
      name: MAIN_TAB_NAME.MATCH_DATA,
      component: <FixtureActions />,
      isVisible: true,
    },
    {
      name: MAIN_TAB_NAME.LINEUPS,
      component: <FixtureLineups />,
      isVisible: true,
    },
    {
      name: MAIN_TAB_NAME.ADVANCED_STATS,
      component: <FixtureAdvancedStats />,
      isVisible: true,
    },
    {
      name: MAIN_TAB_NAME.BASKETBALL_STATS,
      component: <FixtureBasketballStats />,
      isVisible: sportId === SPORT_ID.BASKETBALL,
    },
    {
      name: MAIN_TAB_NAME.AMERICAN_FOOTBALL_STATS,
      component: <FixtureAmericanFootballStats />,
      isVisible: sportId === SPORT_ID.AMERICAN_FOOTBALL,
    },
    {
      name: MAIN_TAB_NAME.SCORESHEET,
      component: <FixtureScoresheet />,
      isVisible: sportId === SPORT_ID.BASKETBALL,
    },
    {
      name: MAIN_TAB_NAME.QA,
      component: <FixtureComparison />,
      isVisible: isSupervisorOrSuperAdmin && sportId === SPORT_ID.SOCCER,
    },
  ].filter((tab) => tab.isVisible);

  return tabs;
};
