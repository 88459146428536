export enum API_METHOD {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export enum USER_ROLE_ID {
  SUPER_ADMIN = 1,
  SPORT_ADMIN = 2,
  SUPERVISOR = 3,
  MAIN_SCORER = 4,
  ORGANISATION_ADMIN = 5,
  ORGANISATION_EDITOR = 6,
  ORGANISATION_OBSERVER = 7,
  BACKUP_SCORER = 8,
  CLOCK_ADMIN = 9,
  SCHEDULER = 10,
  FIXTURE_ADMIN = 11,
  SQUAD_ADMIN = 12,
  OBSERVER = 13,
  TV_SCORER = 14,
}

export enum SPORT_ID {
  SOCCER = 1,
  BASKETBALL = 2,
  SNOOKER = 3,
  AMERICAN_FOOTBALL = 6,
}

export enum USER_ROLE_NAME {
  SUPER_ADMIN = 'Super Admin',
  SPORT_ADMIN = 'Sport Admin',
  SUPERVISOR = 'Supervisor',
  MAIN_SCORER = 'Main Scorer',
  ORGANISATION_ADMIN = 'Organisation Admin',
  ORGANISATION_EDITOR = 'Organisation Editor',
  ORGANISATION_OBSERVER = 'Organisation Observer',
  BACKUP_SCORER = 'Backup Scorer',
  CLOCK_ADMIN = 'Clock Admin',
  SCHEDULER = 'Scheduler',
  FIXTURE_ADMIN = 'Fixture Admin',
  SQUAD_ADMIN = 'Squad Admin',
  OBSERVER = 'Observer',
  TV_SCORER = 'TV Scorer',
  TELEGRAM_ADMIN = 'Telegram Admin',
}

export enum MATCH_ACTION_SEND_TYPE {
  PENDING = 1,
  UPDATED = 2,
  CANCELLED = 3,
  DELETED = 4,
  CONFIRMED = 5,
}

export enum FIXTURE_ACTION_TYPE {
  MATCH_CONDITIONS = 1,
  TEAM_COLOURS = 2,
  LINEUPS = 3,
  START_FIXTURE = 4,
  END_FIXTURE = 5,
  START_PERIOD = 6,
  END_PERIOD = 7,
  START_CLOCK = 8,
  STOP_CLOCK = 9,
  ADJUST_CLOCK = 10,
  START_DELAY = 11,
  END_DELAY = 12,
  SUBSTITUTION_IN = 13,
  SUBSTITUTION_OUT = 14,
  DISTRESS = 18,
  DEVICE_LOCATION = 19,
  ATTENDANCE = 20,
  POST_MATCH_CHECK_COMPLETE = 21,
  STATUS_UPDATE = 22,

  VIDEO_CHECK_UNDERWAY = 51,
  PLAYER_LEFT = 59,
  PLAYER_RETURNED = 60,
  VIDEO_CHECK_RESULT = 61,
  POSSIBLE_VAR = 64,
  NO_VIDEO_CHECK = 68,

  GOAL = 104,
  RED_CARD = 105,
  PENALTY_AWARDED = 106,
  YELLOW_CARD = 107,
  CORNER_TAKEN = 108,
  CORNER_AWARDED = 127,
  FLAG_ON_FIELD_TYPE = 620,
  PENALTY = 643,

  OFFENSIVE_REBOUND = 204,
  DEFENSIVE_REBOUND = 205,
  FT_MADE = 206,
  '2PT_MADE' = 207,
  '3PT_MADE' = 208,
  FT_MISSED = 209,
  '2PT_MISSED' = 210,
  '3PT_MISSED' = 211,
  PLAYER_FOUL = 212,
  FT_AWARDED = 213,
  ASSIST = 217,
  OFFENSIVE_TEAM_REBOUND = 224,
  DEFENSIVE_TEAM_REBOUND = 225,
  COACH_FOUL = 228,
  BENCH_FOUL = 229,
  '2PT_SHOT' = 232,
  '3PT_SHOT' = 233,
}

export enum FIXTURE_ACTION_SUBTYPE {
  PENALTY_GOAL = 10401,
  OWN_GOAL = 10402,
  SECOND_YELLOW_CARD = 10502,
  STRAIGHT_RED = 10501,
}

export const FIXTURE_STATUS_ID = {
  SCHEDULED: 0,
  LIVE: 1,
  COMPLETED: 2,
  POSTPONED: 3,
  ABANDONED: 4,
  CANCELLED: 5,
  SUSPENDED: 6,
  UNVERIFIED: 7,
} as const;
export type FixtureStatusId =
  (typeof FIXTURE_STATUS_ID)[keyof typeof FIXTURE_STATUS_ID];

export const FIXTURE_STATUS = {
  [FIXTURE_STATUS_ID.SCHEDULED]: 'Scheduled',
  [FIXTURE_STATUS_ID.LIVE]: 'Live',
  [FIXTURE_STATUS_ID.COMPLETED]: 'Completed',
  [FIXTURE_STATUS_ID.POSTPONED]: 'Postponed',
  [FIXTURE_STATUS_ID.ABANDONED]: 'Abandoned',
  [FIXTURE_STATUS_ID.CANCELLED]: 'Cancelled',
  [FIXTURE_STATUS_ID.SUSPENDED]: 'Suspended',
  [FIXTURE_STATUS_ID.UNVERIFIED]: 'Unverified',
} as const;
export type FixtureStatus =
  (typeof FIXTURE_STATUS)[keyof typeof FIXTURE_STATUS];

export const COLLECTION_STATUS_ID = {
  SCHEDULED: 0,
  LIVE: 1,
  POST_MATCH: 2,
  SUSPENDED: 3,
  DROPPED: 4,
  COMPLETED: 5,
  POST_MATCH_CHECKED: 6,
} as const;
export type CollectionStatusId =
  (typeof COLLECTION_STATUS_ID)[keyof typeof COLLECTION_STATUS_ID];

export const COLLECTION_STATUS = {
  [COLLECTION_STATUS_ID.SCHEDULED]: 'Scheduled',
  [COLLECTION_STATUS_ID.LIVE]: 'Live',
  [COLLECTION_STATUS_ID.POST_MATCH]: 'Post Match',
  [COLLECTION_STATUS_ID.SUSPENDED]: 'Suspended',
  [COLLECTION_STATUS_ID.DROPPED]: 'Dropped',
  [COLLECTION_STATUS_ID.COMPLETED]: 'Completed',
  [COLLECTION_STATUS_ID.POST_MATCH_CHECKED]: 'Post Match Checked',
} as const;

export type CollectionStatus =
  (typeof COLLECTION_STATUS)[keyof typeof COLLECTION_STATUS];

export const COVERAGE_LEVEL_ID = {
  NO_COVERAGE: 0,
  IN_VENUE: 1,
  LOW_LATENCY_STREAM: 2,
  STANDARD_STREAM: 3,
  VIDEO_POST_MATCH: 4,
};

export type CoverageLevelId =
  (typeof COVERAGE_LEVEL_ID)[keyof typeof COVERAGE_LEVEL_ID];

export const COVERAGE_LEVEL = {
  [COVERAGE_LEVEL_ID.NO_COVERAGE]: '0 (No coverage)',
  [COVERAGE_LEVEL_ID.IN_VENUE]: '1 (In venue)',
  [COVERAGE_LEVEL_ID.LOW_LATENCY_STREAM]: '2 (Low latency stream)',
  [COVERAGE_LEVEL_ID.STANDARD_STREAM]: '3 (Standard stream)',
  [COVERAGE_LEVEL_ID.VIDEO_POST_MATCH]: '4 (Video post match)',
};

export type CoverageLevel =
  (typeof COVERAGE_LEVEL)[keyof typeof COVERAGE_LEVEL];

export const SCORING_APP_STATUS = {
  COMPLETED: 'Completed',
} as const;
export type ScoringAppStatus =
  | (typeof SCORING_APP_STATUS)[keyof typeof SCORING_APP_STATUS]
  | string;

export enum SCORE_TYPE {
  TOTALPERIODWINS = 'TOTALPERIODWINS',
  CUMULATIVEPERIODSCORES = 'CUMULATIVEPERIODSCORES',
}

export { HTTP_STATUS_CODE } from './httpStatusCodes';

export const FIXTURE_ACTION_FLAG_STATE = {
  UNCONFIRMEND: 0,
  CONFIRMED: 1,
} as const;
export type FixtureActionFlagState =
  (typeof FIXTURE_ACTION_FLAG_STATE)[keyof typeof FIXTURE_ACTION_FLAG_STATE];
