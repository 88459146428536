import { FixtureAction } from '@/service/types';
import { AnyPrimitive } from '@/types/common';
import { FilterProperty, GENERIC_FILTER_VALUE } from './constants';
import { ActionFilter } from './useActionsFilters';

export type PropertyFilters = {
  [index in keyof FixtureAction]?: PropertyFilter;
};
export type PropertyFilter = {
  values: any[];
  exclude: boolean;
};

export const isInFilters = (
  filters: ActionFilter[],
  property: FilterProperty,
  value: AnyPrimitive,
  checkFn?: (filter: ActionFilter) => boolean
) => {
  return filters.some((filter) => {
    const checkFnResult = checkFn ? checkFn(filter) : true;
    return (
      filter.property === property && filter.value === value && checkFnResult
    );
  });
};

export const gatherPropertyValues = (filters: ActionFilter[]) => {
  return filters.reduce(
    (acc: PropertyFilters, { property, value, exclude }) => {
      const propOptions = acc[property];
      if (!propOptions) {
        acc[property] = { values: [value], exclude: !!exclude };
        return acc;
      }
      if (propOptions.values.includes(value)) {
        return acc;
      }
      propOptions.values.push(value);
      return acc;
    },
    {}
  );
};

export const doesItemMatchFilter = <T>(
  item: T,
  property: keyof T,
  filterValues: any[]
) => {
  const itemPropVal = item[property];
  if (
    filterValues.includes(GENERIC_FILTER_VALUE.TRUTHY) ||
    filterValues.includes(GENERIC_FILTER_VALUE.UNKNOWN)
  ) {
    if (typeof itemPropVal === 'object' && itemPropVal !== null) {
      return Object.keys(itemPropVal).length > 0;
    }
    return !!itemPropVal;
  }
  if (filterValues.includes(GENERIC_FILTER_VALUE.FALSY)) {
    // eslint-disable-next-line eqeqeq
    return itemPropVal == false || !itemPropVal;
  }

  if (filterValues.includes(undefined) || filterValues.includes(null)) {
    return itemPropVal === undefined || itemPropVal === null;
  }
  return filterValues.includes(itemPropVal);
};

export const applyFilter = <T>(
  collection: T[],
  filter: [property: keyof T, propertyFilter: PropertyFilter]
) => {
  const [property, propertyFilter] = filter;
  return collection.filter((item) => {
    const doesMatch = doesItemMatchFilter(
      item,
      property,
      propertyFilter.values
    );
    return propertyFilter.exclude ? !doesMatch : doesMatch;
  });
};

export const filterActions = (
  filters: ActionFilter[],
  actions: FixtureAction[]
) => {
  if (!actions) return [];
  const filtersPerProperty = Object.entries(gatherPropertyValues(filters)) as [
    keyof FixtureAction,
    PropertyFilter
  ][];

  const filteredActions = filtersPerProperty.reduce((acc, propertyFilter) => {
    return applyFilter(acc, propertyFilter);
  }, actions);
  return filteredActions;
};

export const removeGenericFilter = (
  filters: ActionFilter[],
  filterProperty: keyof FixtureAction
) => {
  const newFilters = [...filters];
  const { TRUTHY, FALSY } = GENERIC_FILTER_VALUE;
  const genericFilterIndex = newFilters.findIndex(
    ({ property, value }) =>
      (property === filterProperty && value === TRUTHY) || value === FALSY
  );
  genericFilterIndex !== -1 && newFilters.splice(genericFilterIndex, 1);
  return newFilters;
};
