import { Stack, Typography } from '@mui/material';
import { FixtureComparisonRating } from '@/service/types';
import { ComparisonRatingForm } from '@/components/ComparisonRating/ComparisonRatingForm';

interface ComparisonRatingProps {
  comparisonRating: FixtureComparisonRating[];
}

export const ComparisonRating = ({
  comparisonRating,
}: ComparisonRatingProps) => {
  return (
    <Stack gap={3} padding={3} alignItems='center'>
      <Typography variant='h6' textTransform='uppercase'>
        Post Fixture Evaluation
      </Typography>
      <Stack flexDirection='row' gap={4}>
        {comparisonRating.map((rating) => (
          <ComparisonRatingForm key={rating.id} rating={rating} />
        ))}
      </Stack>
    </Stack>
  );
};
