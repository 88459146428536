import useSWR from 'swr/immutable';
import { UserRoles } from '../types';
import {
  fetchFromMTService,
  SERVICE_ENDPOINT,
} from '../fetcher/monitoringToolService';
import { ApiError } from '../ApiError';

interface UseUserRolesParams {
  token: string | undefined;
}
export const useUserRoles = ({ token }: UseUserRolesParams) => {
  const { data, error, mutate } = useSWR<UserRoles, ApiError>(
    token
      ? { token, endpoint: SERVICE_ENDPOINT.UserAuthorization.myRoles.GET }
      : null,
    fetchFromMTService
  );

  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
};
