import { StatsButtonType } from '@/contexts/stats/types';
import { AdvancedStatistics } from '@/service/types';
import { STATS_BUTTONS } from '@/contexts/stats/constants';
import { BasketballStatsHeader } from '@/components/BasketballStatsTable/types';

interface FilterBasketballTableDataProps {
  activeQuarter: StatsButtonType['value'];
  activeType: StatsButtonType['value'];
  activeTeam: StatsButtonType['id'];
  activeStatsType: StatsButtonType['value'];
  advancedSportStatistics: AdvancedStatistics | null;
}
export const filterBasketballTableData = ({
  activeQuarter,
  activeType,
  activeTeam,
  activeStatsType,
  advancedSportStatistics,
}: FilterBasketballTableDataProps) => {
  if (!advancedSportStatistics) return;
  if (
    activeStatsType === STATS_BUTTONS.STATS.GAME &&
    activeType === STATS_BUTTONS.TYPE.GAME
  ) {
    return [
      {
        statistics: advancedSportStatistics?.gameStatistics,
      },
    ];
  }
  if (
    activeStatsType === STATS_BUTTONS.STATS.GAME &&
    activeType === STATS_BUTTONS.TYPE.TEAM
  ) {
    return advancedSportStatistics?.teamsStatistics.map((teamStatistics) => {
      return {
        statistics: teamStatistics.gameStatistics,
        teamId: teamStatistics.teamId,
      };
    });
  }

  if (
    activeStatsType === STATS_BUTTONS.STATS.GAME &&
    activeType === STATS_BUTTONS.TYPE.PLAYER
  ) {
    return advancedSportStatistics?.teamsStatistics
      .find((teamStatistics) => teamStatistics.teamId === activeTeam)
      ?.playersStatistics.map((player) => {
        return {
          statistics: player.gameStatistics,
          playerId: player.playerId,
        };
      });
  }

  if (
    activeQuarter === STATS_BUTTONS.QUARTER.ALL.LABEL &&
    activeType === STATS_BUTTONS.TYPE.PLAYER &&
    (activeStatsType === STATS_BUTTONS.STATS.BOX_SCORE ||
      activeStatsType === STATS_BUTTONS.STATS.ZONE_STATS)
  ) {
    return advancedSportStatistics?.teamsStatistics
      .find((teamStatistics) => teamStatistics.teamId === activeTeam)
      ?.playersStatistics.map((player) => {
        return {
          statistics: player.gameStatistics,
          playerId: player.playerId,
        };
      });
  }

  if (
    activeQuarter !== STATS_BUTTONS.QUARTER.ALL.LABEL &&
    activeType === STATS_BUTTONS.TYPE.PLAYER &&
    (activeStatsType === STATS_BUTTONS.STATS.BOX_SCORE ||
      activeStatsType === STATS_BUTTONS.STATS.ZONE_STATS)
  ) {
    return advancedSportStatistics?.teamsStatistics
      .find((teamStatistics) => teamStatistics.teamId === activeTeam)
      ?.playersStatistics.map((player) => {
        return {
          statistics: player?.periodsStatistics.find(
            (period) => period.period === activeQuarter
          ),
          playerId: player.playerId,
        };
      });
  }

  if (
    activeQuarter === STATS_BUTTONS.QUARTER.ALL.LABEL &&
    activeType === STATS_BUTTONS.TYPE.TEAM &&
    (activeStatsType === STATS_BUTTONS.STATS.BOX_SCORE ||
      activeStatsType === STATS_BUTTONS.STATS.ZONE_STATS)
  ) {
    return advancedSportStatistics?.teamsStatistics.map((teamStatistics) => {
      return {
        statistics: teamStatistics.gameStatistics,
        teamId: teamStatistics.teamId,
      };
    });
  }

  if (
    activeQuarter !== STATS_BUTTONS.QUARTER.ALL.LABEL &&
    activeType === STATS_BUTTONS.TYPE.TEAM &&
    (activeStatsType === STATS_BUTTONS.STATS.BOX_SCORE ||
      activeStatsType === STATS_BUTTONS.STATS.ZONE_STATS)
  ) {
    return advancedSportStatistics?.teamsStatistics.map((teamStatistics) => {
      return {
        statistics: teamStatistics.periodStatistics?.find(
          (period) => period.period === activeQuarter
        ),
        teamId: teamStatistics.teamId,
      };
    });
  }

  if (
    activeStatsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO ||
    activeStatsType ===
      STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO_WHEN_ON_COURT ||
    activeStatsType === STATS_BUTTONS.STATS.TEAM_STATS_WHEN_PLAYER_ON_COURT
  ) {
    return advancedSportStatistics?.teamsStatistics
      .find((teamStatistics) => teamStatistics.teamId === activeTeam)
      ?.playersStatistics.map((player) => {
        return {
          statistics: player.gameStatistics,
          playerId: player.playerId,
        };
      });
  }
};

export const generateBasketballStatsHeader = (
  statsType: StatsButtonType['value'],
  type: StatsButtonType['value']
) => {
  const header: BasketballStatsHeader[] = [
    {
      key: 'minutesPlayedString',
      shortValue: 'MIN',
      value: 'Minutes Played',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'freeThrowsMade',
      shortValue: 'FTM',
      value: 'Free Throws Made',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'teamFreeThrowsMadeWhenOnCourt',
      shortValue: 'FTM',
      value: 'Team Free Throws MadeWhenOnCourt',
      visible:
        statsType === STATS_BUTTONS.STATS.TEAM_STATS_WHEN_PLAYER_ON_COURT,
    },
    {
      key: 'freeThrowsMadeRatio',
      shortValue: 'FTM%',
      value: 'Free Throws Made Ratio',
      visible: statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO,
    },
    {
      key: 'freeThrowsMadeRatioWhenOnCourt',
      shortValue: 'FTM%',
      value: 'Free Throws Made Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO_WHEN_ON_COURT,
    },
    {
      key: 'freeThrowAttempts',
      shortValue: 'FTA',
      value: 'Free Throws Attempts',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'teamFreeThrowAttemptsWhenOnCourt',
      shortValue: 'FTA',
      value: 'Team Free Throws Attempts When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.TEAM_STATS_WHEN_PLAYER_ON_COURT,
    },
    {
      key: 'freeThrowsAttemptsRatio',
      shortValue: 'FTA%',
      value: 'Free Throws Attempts Ratio',
      visible: statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO,
    },
    {
      key: 'freeThrowsAttemptsRatioWhenOnCourt',
      shortValue: 'FTA%',
      value: 'Free Throws Attempts Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO_WHEN_ON_COURT,
    },
    {
      key: 'freeThrowsPercentage',
      shortValue: 'FT%',
      value: 'Free Throws Percentage',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'twoPointersMade',
      shortValue: '2PM',
      value: 'Two Pointers Made',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'teamTwoPointersMadeWhenOnCourt',
      shortValue: '2PM',
      value: 'Team Two Pointers Made When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.TEAM_STATS_WHEN_PLAYER_ON_COURT,
    },
    {
      key: 'twoPointersMadeRatio',
      shortValue: '2PM%',
      value: 'Two Pointers Made Ratio',
      visible: statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO,
    },
    {
      key: 'twoPointersMadeRatioWhenOnCourt',
      shortValue: '2PM%',
      value: 'Two Pointers Made Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO_WHEN_ON_COURT,
    },
    {
      key: 'twoPointerAttempts',
      shortValue: '2PA',
      value: 'Two Pointer Attempts',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'teamTwoPointerAttemptsWhenOnCourt',
      shortValue: '2PA',
      value: 'Team Two Pointer Attempts When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.TEAM_STATS_WHEN_PLAYER_ON_COURT,
    },
    {
      key: 'twoPointersAttemptsRatio',
      shortValue: '2PA%',
      value: 'Two Pointer Attempts Ratio',
      visible: statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO,
    },
    {
      key: 'twoPointersAttemptsRatioWhenOnCourt',
      shortValue: '2PA%',
      value: 'Two Pointer Attempts Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO_WHEN_ON_COURT,
    },
    {
      key: 'twoPointersPercentage',
      shortValue: '2P%',
      value: 'Two Pointers Percentage',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'threePointersMade',
      shortValue: '3PM',
      value: 'Three Pointers Made',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'teamThreePointersMadeWhenOnCourt',
      shortValue: '3PM',
      value: 'Team Three Pointers Made When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.TEAM_STATS_WHEN_PLAYER_ON_COURT,
    },
    {
      key: 'threePointersMadeRatio',
      shortValue: '3PM%',
      value: 'Three Pointers Made Ratio',
      visible: statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO,
    },
    {
      key: 'threePointerAttempts',
      shortValue: '3PA',
      value: 'Three Pointer Attempts',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'teamThreePointerAttemptsWhenOnCourt',
      shortValue: '3PA',
      value: 'Team Three Pointer Attempts When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.TEAM_STATS_WHEN_PLAYER_ON_COURT,
    },
    {
      key: 'threePointersAttemptsRatio',
      shortValue: '3PA%',
      value: 'Three Pointer Attempts Ratio',
      visible: statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO,
    },
    {
      key: 'threePointersMadeRatioWhenOnCourt',
      shortValue: '3PM%',
      value: 'Three Pointers Made Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO_WHEN_ON_COURT,
    },
    {
      key: 'threePointersAttemptsRatioWhenOnCourt',
      shortValue: '3PA%',
      value: 'Three Pointer Attempts Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO_WHEN_ON_COURT,
    },
    {
      key: 'threePointersPercentage',
      shortValue: '3P%',
      value: 'Three Pointers Percentage',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'fieldGoalsMade',
      shortValue: 'FGM',
      value: 'Field Goals Made',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'teamFieldGoalsMadeWhenOnCourt',
      shortValue: 'FGM',
      value: 'Team Field Goals Made When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.TEAM_STATS_WHEN_PLAYER_ON_COURT,
    },
    {
      key: 'fieldGoalsMadeRatio',
      shortValue: 'FGM%',
      value: 'Field Goals Made Ratio',
      visible: statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO,
    },
    {
      key: 'fieldGoalsMadeRatioWhenOnCourt',
      shortValue: 'FGM%',
      value: 'Field Goals Made Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO_WHEN_ON_COURT,
    },
    {
      key: 'fieldGoalAttempts',
      shortValue: 'FGA',
      value: 'Field Goal Attempts',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'teamFieldGoalAttemptsWhenOnCourt',
      shortValue: 'FGA',
      value: 'Team Field Goal Attempts When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.TEAM_STATS_WHEN_PLAYER_ON_COURT,
    },
    {
      key: 'fieldGoalsAttemptsRatio',
      shortValue: 'FGA%',
      value: 'Field Goal Attempts Ratio',
      visible: statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO,
    },
    {
      key: 'fieldGoalsAttemptsRatioWhenOnCourt',
      shortValue: 'FGA%',
      value: 'Field Goal Attempts Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO_WHEN_ON_COURT,
    },
    {
      key: 'fieldGoalsPercentage',
      shortValue: 'FG%',
      value: 'Field Goals Percentage',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'effectiveFieldGoalsPercentage',
      shortValue: 'EFG%',
      value: 'Effective Field Goals Percentage',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'defensiveRebounds',
      shortValue: 'DREB',
      value: 'Defensive Rebounds',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'teamDefensiveReboundsWhenOnCourt',
      shortValue: 'DREB',
      value: 'Team Defensive Rebounds When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.TEAM_STATS_WHEN_PLAYER_ON_COURT,
    },
    {
      key: 'defensiveReboundsRatio',
      shortValue: 'DREB%',
      value: 'Defensive Rebounds Ratio',
      visible: statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO,
    },
    {
      key: 'defensiveReboundsRatioWhenOnCourt',
      shortValue: 'DREB%',
      value: 'Defensive Rebounds Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO_WHEN_ON_COURT,
    },
    {
      key: 'offensiveRebounds',
      shortValue: 'OREB',
      value: 'Offensive Rebounds',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'teamOffensiveReboundsWhenOnCourt',
      shortValue: 'OREB',
      value: 'Team Offensive Rebounds When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.TEAM_STATS_WHEN_PLAYER_ON_COURT,
    },
    {
      key: 'offensiveReboundsRatio',
      shortValue: 'OREB%',
      value: 'Offensive Rebounds Ratio',
      visible: statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO,
    },
    {
      key: 'offensiveReboundsRatioWhenOnCourt',
      shortValue: 'OREB%',
      value: 'Offensive Rebounds Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO_WHEN_ON_COURT,
    },
    {
      key: 'totalRebounds',
      shortValue: 'REB',
      value: 'Total Rebounds',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'teamTotalReboundsWhenOnCourt',
      shortValue: 'REB',
      value: 'Team Total Rebounds When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.TEAM_STATS_WHEN_PLAYER_ON_COURT,
    },
    {
      key: 'totalReboundsRatio',
      shortValue: 'REB%',
      value: 'Total Rebounds Ratio',
      visible: statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO,
    },
    {
      key: 'totalReboundsRatioWhenOnCourt',
      shortValue: 'REB%',
      value: 'Total Rebounds Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO_WHEN_ON_COURT,
    },
    {
      key: 'defensiveTeamRebounds',
      shortValue: 'DTREB',
      value: 'Defensive Team Rebounds',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'offensiveTeamRebounds',
      shortValue: 'OTREB',
      value: 'Offensive Team Rebounds',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'teamRebounds',
      shortValue: 'TREB',
      value: 'Team Rebounds',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'totalReboundsWithTeamRebounds',
      shortValue: 'REB+TREB',
      value: 'Total Rebounds With Team Rebounds',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'blocks',
      shortValue: 'BLK',
      value: 'Blocks',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'teamBlocksWhenOnCourt',
      shortValue: 'BLK',
      value: 'Team Blocks When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.TEAM_STATS_WHEN_PLAYER_ON_COURT,
    },
    {
      key: 'blocksRatio',
      shortValue: 'BLK%',
      value: 'Blocks Ratio',
      visible: statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO,
    },
    {
      key: 'blocksRatioWhenOnCourt',
      shortValue: 'BLK%',
      value: 'Blocks Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO_WHEN_ON_COURT,
    },
    {
      key: 'blocksAgainst',
      shortValue: 'BLA',
      value: 'Blocks Against',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'teamBlocksAgainstWhenOnCourt',
      shortValue: 'BLA',
      value: 'Team Blocks Against When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.TEAM_STATS_WHEN_PLAYER_ON_COURT,
    },
    {
      key: 'blocksAgainstRatio',
      shortValue: 'BLA%',
      value: 'Blocks Against Ratio',
      visible: statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO,
    },
    {
      key: 'blocksAgainstRatioWhenOnCourt',
      shortValue: 'BLA%',
      value: 'Blocks Against Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO_WHEN_ON_COURT,
    },
    {
      key: 'steals',
      shortValue: 'STL',
      value: 'Steals',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'teamStealsWhenOnCourt',
      shortValue: 'STL',
      value: 'Team Steals When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.TEAM_STATS_WHEN_PLAYER_ON_COURT,
    },
    {
      key: 'stealsRatio',
      shortValue: 'STL%',
      value: 'Steals Ratio',
      visible: statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO,
    },
    {
      key: 'stealsRatioWhenOnCourt',
      shortValue: 'STL%',
      value: 'Steals Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO_WHEN_ON_COURT,
    },
    {
      key: 'assists',
      shortValue: 'AST',
      value: 'Assists',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'teamAssistsWhenOnCourt',
      shortValue: 'AST',
      value: 'Team Assists When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.TEAM_STATS_WHEN_PLAYER_ON_COURT,
    },
    {
      key: 'assistsRatio',
      shortValue: 'AST%',
      value: 'Assists Ratio',
      visible: statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO,
    },
    {
      key: 'assistsRatioWhenOnCourt',
      shortValue: 'AST%',
      value: 'Assists Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO_WHEN_ON_COURT,
    },
    {
      key: 'assistsToTurnovers',
      shortValue: 'AST/TO',
      value: 'Assists To Turnovers',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'assistsToTurnoversRatio',
      shortValue: 'AST/TO%',
      value: 'Assists To Turnovers Ratio',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'turnovers',
      shortValue: 'TO',
      value: 'Turnovers',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'teamTurnoversWhenOnCourt',
      shortValue: 'TO',
      value: 'Team Turnovers When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.TEAM_STATS_WHEN_PLAYER_ON_COURT,
    },
    {
      key: 'turnoversRatio',
      shortValue: 'TO%',
      value: 'Turnovers Ratio',
      visible: statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO,
    },
    {
      key: 'turnoversRatioWhenOnCourt',
      shortValue: 'TO%',
      value: 'Turnovers Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO_WHEN_ON_COURT,
    },
    {
      key: 'personalFouls',
      shortValue: 'PF',
      value: 'Personal Fouls',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'offensiveFouls',
      shortValue: 'OF',
      value: 'Offensive Fouls',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'technicalFouls',
      shortValue: 'TF',
      value: 'Technical Fouls',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'unsportsmanlikeFouls',
      shortValue: 'UF',
      value: 'Unsportsmanlike Fouls',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'disqualifyingFouls',
      shortValue: 'DF',
      value: 'Disqualifying Fouls',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'teamLimitFouls',
      shortValue: 'TLF',
      value: 'Team Limit Fouls',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'playerLimitFouls',
      shortValue: 'PLF',
      value: 'Player Limit Fouls',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'foulsAgainst',
      shortValue: 'PFA',
      value: 'Fouls Against',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'coachTechnicalFouls',
      shortValue: 'CTFA',
      value: 'Coach Technical Fouls',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'coachDisqualifyingFouls',
      shortValue: 'CDFA',
      value: 'Coach Disqualifying Fouls',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'benchTechnicalFouls',
      shortValue: 'BTFA',
      value: 'Bench Technical Fouls',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'totalPoints',
      shortValue: 'PTS',
      value: 'Total Points',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'benchPoints',
      shortValue: 'BPTS',
      value: 'Bench Points',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'pointsOffTurnovers',
      shortValue: 'PTSOT',
      value: 'Points Off Turnovers',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'teamPointsOffTurnoversWhenOnCourt',
      shortValue: 'POT',
      value: 'Team Points Off Turnovers When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.TEAM_STATS_WHEN_PLAYER_ON_COURT,
    },
    {
      key: 'pointsOffTurnoversRatio',
      shortValue: 'POT%',
      value: 'Points Off Turnovers Ratio',
      visible: statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO,
    },
    {
      key: 'pointsOffTurnoversRatioWhenOnCourt',
      shortValue: 'POT%',
      value: 'Points Off Turnovers Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO_WHEN_ON_COURT,
    },
    {
      key: 'secondChancePoints',
      shortValue: 'SCPTS',
      value: 'Second Chance Points',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'plusMinus',
      shortValue: '+/-',
      value: '+/-',
      visible:
        statsType === STATS_BUTTONS.STATS.BOX_SCORE &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'teamSecondChancePointsWhenOnCourt',
      shortValue: 'SCP',
      value: 'Team Second Chance Points When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.TEAM_STATS_WHEN_PLAYER_ON_COURT,
    },
    {
      key: 'secondChancePointsRatio',
      shortValue: 'SCP%',
      value: 'Second Chance Points Ratio',
      visible: statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO,
    },
    {
      key: 'secondChancePointsRatioWhenOnCourt',
      shortValue: 'SCP%',
      value: 'Second Chance Points Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.PLAYER_TO_TEAM_RATIO_WHEN_ON_COURT,
    },
    {
      key: 'leadChanges',
      shortValue: 'LC',
      value: 'Lead Changes',
      visible:
        statsType === STATS_BUTTONS.STATS.GAME &&
        type === STATS_BUTTONS.TYPE.GAME,
    },
    {
      key: 'timesTied',
      shortValue: 'TT',
      value: 'Times Tied',
      visible:
        statsType === STATS_BUTTONS.STATS.GAME &&
        type === STATS_BUTTONS.TYPE.GAME,
    },
    {
      key: 'hasDoubleDouble',
      shortValue: 'HDD',
      value: 'Has Double Double',
      visible:
        statsType === STATS_BUTTONS.STATS.GAME &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'hasTripleDouble',
      shortValue: 'HTD',
      value: 'Has Triple Double',
      visible:
        statsType === STATS_BUTTONS.STATS.GAME &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'hasQuadrupleDouble',
      shortValue: 'HQD',
      value: 'Has Quadruple Double',
      visible:
        statsType === STATS_BUTTONS.STATS.GAME &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'ejected',
      shortValue: 'IE',
      value: 'Is Ejected',
      visible:
        statsType === STATS_BUTTONS.STATS.GAME &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'fouledOut',
      shortValue: 'IFO',
      value: 'Is Fouled Out',
      visible:
        statsType === STATS_BUTTONS.STATS.GAME &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'biggestLead',
      shortValue: 'BL',
      value: 'Biggest Lead',
      visible:
        statsType === STATS_BUTTONS.STATS.GAME &&
        type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'currentScoringRun',
      shortValue: 'CSR',
      value: 'Current Scoring Run',
      visible:
        statsType === STATS_BUTTONS.STATS.GAME &&
        type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'longestScoringRun',
      shortValue: 'LSR',
      value: 'Longest Scoring Run',
      visible:
        statsType === STATS_BUTTONS.STATS.GAME &&
        type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'longestScoringRunFromZero',
      shortValue: 'LSRFZ',
      value: 'Longest Scoring Run From Zero',
      visible:
        statsType === STATS_BUTTONS.STATS.GAME &&
        type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'mostPointsInPeriod',
      shortValue: 'MPIP',
      value: 'Most Points In Period',
      visible:
        statsType === STATS_BUTTONS.STATS.GAME &&
        type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'periodWithMostPoints',
      shortValue: 'PWMP',
      value: 'Period With Most Points',
      visible:
        statsType === STATS_BUTTONS.STATS.GAME &&
        type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'coachEjected',
      shortValue: 'ICE',
      value: 'Is Coach Ejected',
      visible:
        statsType === STATS_BUTTONS.STATS.GAME &&
        type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'timeOnLead',
      shortValue: 'TOL',
      value: 'Time On Lead',
      visible:
        statsType === STATS_BUTTONS.STATS.GAME &&
        type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'leftCornerStats',
      subKey: 'zonePoints',
      shortValue: 'LCP',
      value: 'Left Corner Points',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'leftCornerStats',
      subKey: 'zonePointsRatio',
      shortValue: 'LCP%',
      value: 'Left Corner Points Ratio',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'leftCornerStats',
      subKey: 'zonePointsRatioWhenOnCourt',
      shortValue: 'LCP%WOC',
      value: 'Left Corner Points Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'leftCornerStats',
      subKey: 'teamZonePointsWhenOnCourt',
      shortValue: 'TLCPWOC',
      value: 'Team Left Corner Points When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'leftCornerStats',
      subKey: 'zoneShotAttempts',
      shortValue: 'LCSA',
      value: 'Left Corner Shot Attempts',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'leftCornerStats',
      subKey: 'zoneShotAttemptsRatio',
      shortValue: 'LCSA%',
      value: 'Left Corner Shot Attempts Ratio',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'leftCornerStats',
      subKey: 'zoneShotAttemptsRatioWhenOnCourt',
      shortValue: 'LCSA%WOC',
      value: 'Left Corner Shot Attempts Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'leftCornerStats',
      subKey: 'teamZoneShotAttemptsWhenOnCourt',
      shortValue: 'TLCSAWOC',
      value: 'Team Left Corner Shot Attempts When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'leftCornerStats',
      subKey: 'zoneShotsMade',
      shortValue: 'LCSM',
      value: 'Left Corner Shots Made',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'leftCornerStats',
      subKey: 'zoneShotsMadeRatio',
      shortValue: 'LCSM%',
      value: 'Left Corner Shots Made Ratio',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'leftCornerStats',
      subKey: 'zoneShotsMadeRatioWhenOnCourt',
      shortValue: 'LCSM%WOC',
      value: 'Left Corner Shots Made Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'leftCornerStats',
      subKey: 'teamZoneShotsMadeWhenOnCourt',
      shortValue: 'TLCSMWOC',
      value: 'Team Left Corner Shots Made When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'leftCornerStats',
      subKey: 'zoneShotsPercentage',
      shortValue: 'LCS%',
      value: 'Left Corner Shots Ratio',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'rightCornerStats',
      subKey: 'zonePoints',
      shortValue: 'RCP',
      value: 'Right Corner Points',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'rightCornerStats',
      subKey: 'zonePointsRatio',
      shortValue: 'RCP%',
      value: 'Right Corner Points Ratio',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'rightCornerStats',
      subKey: 'zonePointsRatioWhenOnCourt',
      shortValue: 'RCP%WOC',
      value: 'Right Corner Points Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'rightCornerStats',
      subKey: 'teamZonePointsWhenOnCourt',
      shortValue: 'TRCPWOC',
      value: 'Team Right Corner Points When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'rightCornerStats',
      subKey: 'zoneShotAttempts',
      shortValue: 'RCSA',
      value: 'Right Corner Shot Attempts',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'rightCornerStats',
      subKey: 'zoneShotAttemptsRatio',
      shortValue: 'RCSA%',
      value: 'Right Corner Shot Attempts Ratio',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'rightCornerStats',
      subKey: 'zoneShotAttemptsRatioWhenOnCourt',
      shortValue: 'RCSA%WOC',
      value: 'Right Corner Shot Attempts Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'rightCornerStats',
      subKey: 'teamZoneShotAttemptsWhenOnCourt',
      shortValue: 'TRCSAWOC',
      value: 'Team Right Corner Shot Attempts When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'rightCornerStats',
      subKey: 'zoneShotsMade',
      shortValue: 'RCSM',
      value: 'Right Corner Shots Made',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'rightCornerStats',
      subKey: 'zoneShotsMadeRatio',
      shortValue: 'RCSM%',
      value: 'Right Corner Shots Made Ratio',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'rightCornerStats',
      subKey: 'zoneShotsMadeRatioWhenOnCourt',
      shortValue: 'RCSM%WOC',
      value: 'Right Corner Shots Made Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'rightCornerStats',
      subKey: 'teamZoneShotsMadeWhenOnCourt',
      shortValue: 'TRCSMWOC',
      value: 'Team Right Corner Shots Made When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'rightCornerStats',
      subKey: 'zoneShotsPercentage',
      shortValue: 'RCS%',
      value: 'Right Corner Shots Ratio',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'paintStats',
      subKey: 'zonePoints',
      shortValue: 'PP',
      value: 'Paint Points',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'paintStats',
      subKey: 'zonePointsRatio',
      shortValue: 'PP%',
      value: 'Paint Points Ratio',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'paintStats',
      subKey: 'zonePointsRatioWhenOnCourt',
      shortValue: 'PP%WOC',
      value: 'Paint Points Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'paintStats',
      subKey: 'teamZonePointsWhenOnCourt',
      shortValue: 'TPPWOC',
      value: 'Team Paint Points When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'paintStats',
      subKey: 'zoneShotAttempts',
      shortValue: 'PSA',
      value: 'Paint Shot Attempts',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'paintStats',
      subKey: 'zoneShotAttemptsRatio',
      shortValue: 'PSA%',
      value: 'Paint Shot Attempts Ratio',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'paintStats',
      subKey: 'zoneShotAttemptsRatioWhenOnCourt',
      shortValue: 'PSA%WOC',
      value: 'Paint Shot Attempts Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'paintStats',
      subKey: 'teamZoneShotAttemptsWhenOnCourt',
      shortValue: 'TPSAWOC',
      value: 'Team Paint Shot Attempts When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'paintStats',
      subKey: 'zoneShotsMade',
      shortValue: 'PSM',
      value: 'Paint Shots Made',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'paintStats',
      subKey: 'zoneShotsMadeRatio',
      shortValue: 'PSM%',
      value: 'Paint Shots Made Ratio',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'paintStats',
      subKey: 'zoneShotsMadeRatioWhenOnCourt',
      shortValue: 'PSM%WOC',
      value: 'Paint Shots Made Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'paintStats',
      subKey: 'teamZoneShotsMadeWhenOnCourt',
      shortValue: 'TPSMWOC',
      value: 'Team Paint Shots Made When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'paintStats',
      subKey: 'zoneShotsPercentage',
      shortValue: 'PS%',
      value: 'Paint Shots Ratio',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'restrictedAreaStats',
      subKey: 'zonePoints',
      shortValue: 'RAP',
      value: 'Restricted Area Points',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'restrictedAreaStats',
      subKey: 'zonePointsRatio',
      shortValue: 'RAPP%',
      value: 'Restricted Area Points Ratio',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'restrictedAreaStats',
      subKey: 'zonePointsRatioWhenOnCourt',
      shortValue: 'RAP%WOC',
      value: 'Restricted Area Points Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'restrictedAreaStats',
      subKey: 'teamZonePointsWhenOnCourt',
      shortValue: 'TRAPWOC',
      value: 'Team Restricted Area Points When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'restrictedAreaStats',
      subKey: 'zoneShotAttempts',
      shortValue: 'RASA',
      value: 'Restricted Area Shot Attempts',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'restrictedAreaStats',
      subKey: 'zoneShotAttemptsRatio',
      shortValue: 'RASA%',
      value: 'Restricted Area Shot Attempts Ratio',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'restrictedAreaStats',
      subKey: 'zoneShotAttemptsRatioWhenOnCourt',
      shortValue: 'RASA%WOC',
      value: 'Restricted Area Shot Attempts Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'restrictedAreaStats',
      subKey: 'teamZoneShotAttemptsWhenOnCourt',
      shortValue: 'TRASAWOC',
      value: 'Team Restricted Area Shot Attempts When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'restrictedAreaStats',
      subKey: 'zoneShotsMade',
      shortValue: 'RASM',
      value: 'Restricted Area Shots Made',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'restrictedAreaStats',
      subKey: 'zoneShotsMadeRatio',
      shortValue: 'RASM%',
      value: 'Restricted Area Shots Made Ratio',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'restrictedAreaStats',
      subKey: 'zoneShotsMadeRatioWhenOnCourt',
      shortValue: 'RASM%WOC',
      value: 'Restricted Area Shots Made Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'restrictedAreaStats',
      subKey: 'teamZoneShotsMadeWhenOnCourt',
      shortValue: 'TRASMWOC',
      value: 'Team Restricted Area Shots Made When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'restrictedAreaStats',
      subKey: 'zoneShotsPercentage',
      shortValue: 'RAS%',
      value: 'Restricted Area Shots Ratio',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'midRangeStats',
      subKey: 'zonePoints',
      shortValue: 'MRP',
      value: 'Mid Range Points',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'midRangeStats',
      subKey: 'zonePointsRatio',
      shortValue: 'MRP%',
      value: 'Mid Range Points Ratio',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'midRangeStats',
      subKey: 'zonePointsRatioWhenOnCourt',
      shortValue: 'MRP%WOC',
      value: 'Mid Range Points Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'midRangeStats',
      subKey: 'teamZonePointsWhenOnCourt',
      shortValue: 'TMRPWOC',
      value: 'Team Mid Range Points When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'midRangeStats',
      subKey: 'zoneShotAttempts',
      shortValue: 'MRSA',
      value: 'Mid Range Shot Attempts',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'midRangeStats',
      subKey: 'zoneShotAttemptsRatio',
      shortValue: 'MRSA%',
      value: 'Mid Range Shot Attempts Ratio',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'midRangeStats',
      subKey: 'zoneShotAttemptsRatioWhenOnCourt',
      shortValue: 'MRSA%WOC',
      value: 'Mid Range Shot Attempts Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'midRangeStats',
      subKey: 'teamZoneShotAttemptsWhenOnCourt',
      shortValue: 'TMRSAWOC',
      value: 'Team Mid Range Shot Attempts When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'midRangeStats',
      subKey: 'zoneShotsMade',
      shortValue: 'MRSM',
      value: 'Mid Range Shots Made',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'midRangeStats',
      subKey: 'zoneShotsMadeRatio',
      shortValue: 'MRSM%',
      value: 'Mid Range Shots Made Ratio',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'midRangeStats',
      subKey: 'zoneShotsMadeRatioWhenOnCourt',
      shortValue: 'MRSM%WOC',
      value: 'Mid Range Shots Made Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'midRangeStats',
      subKey: 'teamZoneShotsMadeWhenOnCourt',
      shortValue: 'TMRSMWOC',
      value: 'Team Mid Range Shots Made When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'midRangeStats',
      subKey: 'zoneShotsPercentage',
      shortValue: 'MRS%',
      value: 'Mid Range Shots Ratio',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'aboveTheBreak3Stats',
      subKey: 'zonePoints',
      shortValue: 'ATBTP',
      value: 'Above The Break Three Points',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'aboveTheBreak3Stats',
      subKey: 'zonePointsRatio',
      shortValue: 'ATBTP%',
      value: 'Above The Break Three Points Ratio',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'aboveTheBreak3Stats',
      subKey: 'zonePointsRatioWhenOnCourt',
      shortValue: 'ATBTP%WOC',
      value: 'Above The Break Three Points Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'aboveTheBreak3Stats',
      subKey: 'teamZonePointsWhenOnCourt',
      shortValue: 'TATBTPWOC',
      value: 'Team Above The Break Three Points When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'aboveTheBreak3Stats',
      subKey: 'zoneShotAttempts',
      shortValue: 'ATBTSA',
      value: 'Above The Break Three Shot Attempts',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'aboveTheBreak3Stats',
      subKey: 'zoneShotAttemptsRatio',
      shortValue: 'ATBTSA%',
      value: 'Above The Break Three Shot Attempts Ratio',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'aboveTheBreak3Stats',
      subKey: 'zoneShotAttemptsRatioWhenOnCourt',
      shortValue: 'ATBTSA%WOC',
      value: 'Above The Break Three Shot Attempts Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'aboveTheBreak3Stats',
      subKey: 'teamZoneShotAttemptsWhenOnCourt',
      shortValue: 'TATBTSAWOC',
      value: 'Team Above The Break Three Shot Attempts When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'aboveTheBreak3Stats',
      subKey: 'zoneShotsMade',
      shortValue: 'ATBTSM',
      value: 'Above The Break Three Shots Made',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'aboveTheBreak3Stats',
      subKey: 'zoneShotsMadeRatio',
      shortValue: 'ATBTSM%',
      value: 'Above The Break Three Shots Made Ratio',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'aboveTheBreak3Stats',
      subKey: 'zoneShotsMadeRatioWhenOnCourt',
      shortValue: 'ATBTSM%WOC',
      value: 'Above The Break Three Shots Made Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'aboveTheBreak3Stats',
      subKey: 'teamZoneShotsMadeWhenOnCourt',
      shortValue: 'TATBTSMWOC',
      value: 'Team Above The Break Three Shots Made When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'aboveTheBreak3Stats',
      subKey: 'zoneShotsPercentage',
      shortValue: 'ATBTS%',
      value: 'Above The Break Three Shots Ratio',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'backcourtStats',
      subKey: 'zonePoints',
      shortValue: 'BP',
      value: 'Backcourt Points',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'backcourtStats',
      subKey: 'zonePointsRatio',
      shortValue: 'BP%',
      value: 'Backcourt Points Ratio',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'backcourtStats',
      subKey: 'zonePointsRatioWhenOnCourt',
      shortValue: 'BP%WOC',
      value: 'Backcourt Points Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'backcourtStats',
      subKey: 'teamZonePointsWhenOnCourt',
      shortValue: 'TBPWOC',
      value: 'Team Backcourt Points When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'backcourtStats',
      subKey: 'zoneShotAttempts',
      shortValue: 'BSA',
      value: 'Backcourt Shot Attempts',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'backcourtStats',
      subKey: 'zoneShotAttemptsRatio',
      shortValue: 'BSA%',
      value: 'Backcourt Shot Attempts Ratio',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'backcourtStats',
      subKey: 'zoneShotAttemptsRatioWhenOnCourt',
      shortValue: 'BSA%WOC',
      value: 'Backcourt Shot Attempts Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'backcourtStats',
      subKey: 'teamZoneShotAttemptsWhenOnCourt',
      shortValue: 'TBSAWOC',
      value: 'Team Backcourt Shot Attempts When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'backcourtStats',
      subKey: 'zoneShotsMade',
      shortValue: 'BSM',
      value: 'Backcourt Shots Made',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
    {
      key: 'backcourtStats',
      subKey: 'zoneShotsMadeRatio',
      shortValue: 'BSM%',
      value: 'Backcourt Shots Made Ratio',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'backcourtStats',
      subKey: 'zoneShotsMadeRatioWhenOnCourt',
      shortValue: 'BSM%WOC',
      value: 'Backcourt Shots Made Ratio When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'backcourtStats',
      subKey: 'teamZoneShotsMadeWhenOnCourt',
      shortValue: 'TBSMWOC',
      value: 'Team Backcourt Shots Made When On Court',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        type === STATS_BUTTONS.TYPE.PLAYER,
    },
    {
      key: 'backcourtStats',
      subKey: 'zoneShotsPercentage',
      shortValue: 'BS%',
      value: 'Backcourt Shots Ratio',
      visible:
        statsType === STATS_BUTTONS.STATS.ZONE_STATS &&
        (type === STATS_BUTTONS.TYPE.PLAYER ||
          type === STATS_BUTTONS.TYPE.TEAM),
    },
  ];
  return header.filter((header) => header.visible);
};
