import Warning from '@mui/icons-material/Warning';
import { Stack, Typography } from '@mui/material';

export const NotImplemented = () => {
  return (
    <Stack
      flexGrow={1}
      fontSize='3rem'
      alignItems='center'
      justifyContent='center'
      textAlign='center'
    >
      <Warning fontSize='inherit' />
      <Typography fontSize='inherit'>Not yet implemented</Typography>
    </Stack>
  );
};
