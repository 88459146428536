import {
  AdvancedStatsData,
  AdvancedStatsTableHeader,
} from '@/components/AdvancedStatsTable/types';
import { StatsButtonType } from '@/contexts/stats/types';
import { TeamActionAggregates } from '@/service/types';
import { STATS_BUTTONS } from '@/contexts/stats/constants';

interface FilterAdvancedTableDataProps {
  activeQuarter: StatsButtonType['value'];
  activeType: StatsButtonType['value'];
  activeTeam: StatsButtonType['id'];
  actions?: TeamActionAggregates[];
}
export const filterAdvancedTableData = ({
  activeQuarter,
  activeType,
  activeTeam,
  actions,
}: FilterAdvancedTableDataProps) => {
  if (!actions || !actions.length) {
    return;
  }

  const isAllQuarter = activeQuarter === STATS_BUTTONS.QUARTER.ALL.LABEL;

  if (activeType === STATS_BUTTONS.TYPE.PLAYER) {
    return actions
      .find((teamStatistics) => teamStatistics.teamId === activeTeam)
      ?.playerActionAggregates.map((player) => ({
        statistics: isAllQuarter
          ? player?.gameActionAggregates
          : player?.periodActionAggregates.filter(
              (period) => period.period === activeQuarter
            ),
        playerId: player.playerId,
      }));
  }
  if (activeType === STATS_BUTTONS.TYPE.TEAM) {
    return actions.map((teamStatistics) => ({
      statistics: isAllQuarter
        ? teamStatistics?.gameActionAggregates
        : teamStatistics.periodActionAggregates?.filter(
            (period) => period.period === activeQuarter
          ),
      teamId: teamStatistics.teamId,
    }));
  }
};

export const generateTableHeader = (statsData: AdvancedStatsData[]) => {
  const uniqueHeaders = new Map<number, Set<number | undefined>>();

  for (const data of statsData) {
    if (data.statistics) {
      for (const statistics of data.statistics) {
        const { actionId, subActionAggregates } = statistics;

        if (!uniqueHeaders.has(actionId)) {
          uniqueHeaders.set(actionId, new Set<number | undefined>());
        }

        for (const subAction of subActionAggregates) {
          if (subAction.subActionId !== -1) {
            uniqueHeaders.get(actionId)!.add(subAction.subActionId);
          }
        }
      }
    }
  }

  const sortedActionIds = Array.from(uniqueHeaders.keys()).sort();

  return sortedActionIds.map((actionId) => {
    const subActions = Array.from(uniqueHeaders.get(actionId)!.values()).sort();
    return { actionId, subActions };
  }) as AdvancedStatsTableHeader;
};
