import { ChangeEvent, Dispatch, SetStateAction, useMemo } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import Avatar from '@mui/material/Avatar';
import { FixtureScorerRating } from '@/service/types';
import { ComparisonBox } from '@/components/ComparisonRating/common';

interface ComparisonRatingCommentProps {
  newRating: FixtureScorerRating | undefined;
  setNewRating: Dispatch<SetStateAction<FixtureScorerRating | undefined>>;
}
export const ComparisonRatingComment = ({
  newRating,
  setNewRating,
}: ComparisonRatingCommentProps) => {
  const { authState } = useOktaAuth();
  const username = authState?.idToken?.claims.name ?? '';

  const userInitials = useMemo(() => {
    if (!username) return;
    const [[first], [second]] = username.split(' ');
    return first + second;
  }, [username]);
  return (
    <ComparisonBox gap={2} textTransform='uppercase'>
      Add Comment
      <TextField
        id='comment'
        multiline
        value={newRating?.comment || ''}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setNewRating({ ...newRating, comment: event.target.value });
        }}
        minRows={6}
        maxRows={16}
        InputProps={{
          style: { padding: '1rem 0.875rem 1rem 2.25rem' },
          startAdornment: (
            <InputAdornment position='start'>
              <Avatar
                sx={{
                  width: 24,
                  height: 24,
                  fontSize: '0.75rem',
                  position: 'absolute',
                  top: (theme) => theme.spacing(2),
                  left: (theme) => theme.spacing(1.5),
                }}
              >
                {userInitials}
              </Avatar>
            </InputAdornment>
          ),
        }}
      />
    </ComparisonBox>
  );
};
