import { styled } from '@mui/material/styles';
import { TableCell } from '@mui/material';

export const StatsTableDataCell = styled(TableCell)(({ theme }) => ({
  textAlign: 'center',
  borderRight: `1px solid ${theme.palette.tableBorderColor}`,
  '&:last-of-type': {
    borderRight: 'none',
  },
}));

export const StatsTableHeaderActionCell = styled(StatsTableDataCell)(
  ({ theme }) => ({
    backgroundColor: theme.palette.tableHeaderColor,
  })
);

export const StatsTableStickyHeaderActionCell = styled(
  StatsTableHeaderActionCell
)(() => ({
  position: 'sticky',
  top: 0,
  left: 0,
  zIndex: 2,
}));

export const StatsTableStickyDataCell = styled(StatsTableDataCell)(
  ({ theme }) => ({
    position: 'sticky',
    left: 0,
    zIndex: 1,
    backgroundColor: theme.palette.background.paper,
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);',
    textAlign: 'left',
  })
);
