import { FC } from 'react';
import JSONPretty from 'react-json-pretty';
import { Divider, IconButton, Stack, Tooltip, useTheme } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ActionsDialog } from '@/components/ActionsDialog/ActionsDialog';
import { copyToClipboard } from '@/utils';
import { ActionDialogProps } from '@/components/ActionsDialog/types';
import { COMMON_STRING } from '@/constants/dictionary';

export const COPY_JSON_BUTTON_LABEL = 'Copy Json';

export const JsonDialog: FC<ActionDialogProps> = ({ action, onClose }) => {
  const {
    palette: { json },
  } = useTheme();

  return (
    <ActionsDialog
      open={true}
      onClose={onClose}
      title={COMMON_STRING.ACTION_JSON}
    >
      <Stack
        sx={{
          background: json.background,
          width: '100%',
          borderRadius: '0.5rem',
        }}
      >
        <Stack alignItems='end'>
          <Tooltip title='Copy to clipboard' arrow>
            <IconButton
              aria-label={COPY_JSON_BUTTON_LABEL}
              sx={{ width: 'fit-content' }}
              onClick={() => copyToClipboard(JSON.stringify(action))}
            >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </Stack>
        <Divider />
        <JSONPretty
          data={action}
          theme={{
            main: `line-height:1.3; color:${json.main};`,
            error: `line-height:1.3; color:${json.red}; background:white;`,
            key: `color:${json.purple};`,
            string: `color:${json.red};`,
            value: `color:${json.blue};`,
            boolean: `color:${json.main};`,
          }}
          style={{ paddingInline: '1rem', overflow: 'auto' }}
        />
      </Stack>
    </ActionsDialog>
  );
};
