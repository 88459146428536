import Stack, { StackProps } from '@mui/material/Stack';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FC, PropsWithChildren } from 'react';

import { COMPARISON_TABLE_CLASS_NAMES } from '@/components/ComparisonTable/constants';

export type ComparisonTableCellProps = PropsWithChildren<
  StackProps & {
    width?: string;
    columnName?: string;
    label?: string;
    subLabel?: string;
    overallValue?: string | number;
    supervisorsValue?: string | number | null;
    isMismatch?: boolean;
  }
>;

export const ComparisonTableCell: FC<ComparisonTableCellProps> = ({
  children,
  label,
  subLabel,
  columnName,
  overallValue,
  supervisorsValue,
  width,
  isMismatch,
  ...restProps
}) => {
  const theme = useTheme();
  return (
    <Stack
      data-column-name={columnName}
      data-label={columnName && (label || overallValue)}
      justifyContent='center'
      lineHeight={1.43}
      fontSize='0.875rem'
      padding={(theme) => theme.spacing(1)}
      className={COMPARISON_TABLE_CLASS_NAMES.CELL}
      width={width}
      textAlign='center'
      sx={{
        background:
          (label !== undefined || overallValue !== undefined) &&
          !supervisorsValue &&
          !isMismatch
            ? 'none'
            : overallValue !== undefined && supervisorsValue && !isMismatch
            ? `${theme.palette.warning.light}52`
            : isMismatch
            ? `${theme.palette.error.main}52`
            : theme.palette.action.disabled,
      }}
      {...restProps}
    >
      {(label !== undefined || overallValue !== undefined) && !supervisorsValue
        ? label || overallValue?.toString()
        : overallValue && supervisorsValue
        ? `${overallValue} (${supervisorsValue})`
        : 'N/A'}
      {subLabel && (
        <Typography
          component='p'
          variant='caption'
          sx={{
            lineHeight: 1.1,
          }}
        >
          {subLabel}
        </Typography>
      )}
      {children}
    </Stack>
  );
};
