import { STATS_BUTTONS } from '@/contexts/stats/constants';
import { StatsButtonType } from '@/contexts/stats/types';
import { AdvancedStatistics } from '@/service/types';
import { AmericanFootballStatsHeader } from '@/components/AmericanFootballStatsTable/types';

interface FilterAmericanFootballTableDataProps {
  activeQuarter: StatsButtonType['value'];
  activeType: StatsButtonType['value'];
  activeTeam: StatsButtonType['id'];
  advancedSportStatistics: AdvancedStatistics | null;
}

export const filterAmericanFootballTableData = ({
  activeQuarter,
  activeType,
  activeTeam,
  advancedSportStatistics,
}: FilterAmericanFootballTableDataProps) => {
  if (!advancedSportStatistics) return;

  const isAllQuarter = activeQuarter === STATS_BUTTONS.QUARTER.ALL.LABEL;

  if (activeType === STATS_BUTTONS.TYPE.PLAYER) {
    return advancedSportStatistics?.teamsStatistics
      .find((teamStatistics) => teamStatistics.teamId === activeTeam)
      ?.playersStatistics.map((player) => {
        return {
          statistics: isAllQuarter
            ? player.gameStatistics
            : player?.periodsStatistics.find(
                (period) => period.period === activeQuarter
              ),
          playerId: player.playerId,
        };
      });
  }

  if (activeType === STATS_BUTTONS.TYPE.TEAM) {
    return advancedSportStatistics?.teamsStatistics.map((teamStatistics) => {
      return {
        statistics: isAllQuarter
          ? teamStatistics.gameStatistics
          : teamStatistics?.periodsStatistics?.find(
              (period) => period.period === activeQuarter
            ),
        teamId: teamStatistics.teamId,
      };
    });
  }
};

export const generateAmericanFootballStatsHeader = (
  statsType: StatsButtonType['value'],
  type: StatsButtonType['value']
) => {
  const header: AmericanFootballStatsHeader[] = [
    {
      key: 'firstDowns',
      shortValue: '1DWNS',
      value: '1st Downs',
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'thirdDownConversions',
      value: '3rd Down Conversions',
      shortValue: '3DWNC',
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'thirdDownAttempts',
      value: '3rd Down Attempts',
      shortValue: '3DWNA',
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'fourthDownConversions',
      value: '4th Down Conversions',
      shortValue: '4DWNC',
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'fourthDownAttempts',
      value: '4th Down Attempts',
      shortValue: '4DWNA',
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'yardsTotal',
      value: 'Total Yards',
      shortValue: 'TYDS',
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'passingYards',
      value: 'Passing Yards',
      shortValue: 'PYDS',
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'passingAttempts',
      value: 'Passing Attempts',
      shortValue: 'PATM',
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'passingAverage',
      value: 'Yards per Pass',
      shortValue: 'YPP',
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'rushingYards',
      value: 'Rushing Yards',
      shortValue: 'RYDS',
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'rushingAttempts',
      value: 'Rushing Attempts',
      shortValue: 'RATM',
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'rushingAverage',
      value: 'Yards per Rush',
      shortValue: 'YPR',
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'penaltiesNumber',
      value: 'Penalties',
      shortValue: 'PNL',
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'turnovers',
      value: 'Turnovers',
      shortValue: 'TRN',
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'fumblesLost',
      value: 'Fumbles Lost',
      shortValue: 'FMBL',
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'interceptions',
      value: 'Interceptions',
      shortValue: 'INTR',
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'timeOfPossession',
      value: 'Time in Possession',
      shortValue: 'TIP',
      visible: type === STATS_BUTTONS.TYPE.TEAM,
    },
    {
      key: 'passingCompletions',
      value: 'Completions',
      shortValue: 'CMP',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.PASSING,
    },
    {
      key: 'passingAttempts',
      value: 'Attempts',
      shortValue: 'ATT',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.PASSING,
    },
    {
      key: 'passingYards',
      value: 'Yards',
      shortValue: 'YDS',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.PASSING,
    },
    {
      key: 'passingTouchdowns',
      value: 'Touchdowns',
      shortValue: 'TD',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.PASSING,
    },
    {
      key: 'passingInterceptions',
      value: 'Interceptions',
      shortValue: 'INT',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.PASSING,
    },
    {
      key: 'passingLongest',
      value: 'Longest Pass',
      shortValue: 'LNG',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.PASSING,
    },
    {
      key: 'offenseSacksAllowed',
      value: 'Sacks',
      shortValue: 'SCK',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.PASSING,
    },
    {
      key: 'rushingAttempts',
      value: 'Attempts',
      shortValue: 'ATT',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.RUSHING,
    },
    {
      key: 'rushingYards',
      value: 'Yards Gained',
      shortValue: 'GN',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.RUSHING,
    },
    {
      key: 'rushingTouchdowns',
      value: 'Touchdowns',
      shortValue: 'TD',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.RUSHING,
    },
    {
      key: 'rushingLongest',
      value: 'Longest Run',
      shortValue: 'LNG',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.RUSHING,
    },
    {
      key: 'rushingAverage',
      value: 'Average Distance per Run',
      shortValue: 'AVG',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.RUSHING,
    },
    {
      key: 'receivingReceptions',
      value: 'Receptions',
      shortValue: 'REC',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.RECEIVING,
    },
    {
      key: 'receivingYards',
      value: 'Yards',
      shortValue: 'YDS',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.RECEIVING,
    },
    {
      key: 'receivingTouchdowns',
      value: 'Touchdowns',
      shortValue: 'TD',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.RECEIVING,
    },
    {
      key: 'receivingLongest',
      value: 'Longest Reception',
      shortValue: 'LNG',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.RECEIVING,
    },
    {
      key: 'defenseTacklesSolo',
      value: 'Solo Tackle',
      shortValue: 'STK',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.DEFENSIVE,
    },
    {
      key: 'defenseTacklesAssisted',
      value: 'Assisted Tackle',
      shortValue: 'ATK',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.DEFENSIVE,
    },
    {
      key: 'defenseTacklesForLoss',
      value: 'Tackle For Loss',
      shortValue: 'TFL',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.DEFENSIVE,
    },
    {
      key: 'defenseForcedFumbles',
      value: 'Forced Fumble',
      shortValue: 'FF',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.DEFENSIVE,
    },
    {
      key: 'defenseFumbleRecoveries',
      value: 'Fumble Recovery',
      shortValue: 'FR',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.DEFENSIVE,
    },
    {
      key: 'defenseInterceptions',
      value: 'Interception',
      shortValue: 'INT',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.DEFENSIVE,
    },
    {
      key: 'punting',
      value: 'Punts',
      shortValue: 'PTS',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.SPECIAL_TEAMS_PUNTING,
    },
    {
      key: 'puntingYards',
      value: 'Yards',
      shortValue: 'YDS',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.SPECIAL_TEAMS_PUNTING,
    },
    {
      key: 'puntingAverage',
      value: 'Average Punt Length',
      shortValue: 'AVG',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.SPECIAL_TEAMS_PUNTING,
    },
    {
      key: 'puntingLongest',
      value: 'Longest Punt',
      shortValue: 'LNG',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.SPECIAL_TEAMS_PUNTING,
    },
    {
      key: 'puntingInside20',
      value: 'Punts downed inside opponents 20',
      shortValue: 'I20',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.SPECIAL_TEAMS_PUNTING,
    },
    {
      key: 'puntingTouchbacks',
      value: 'Touchbacks',
      shortValue: 'TB',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.SPECIAL_TEAMS_PUNTING,
    },
    {
      key: 'puntingReturns',
      value: 'Punts Returned',
      shortValue: 'RET',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.SPECIAL_TEAMS_ALL_RETURNS,
    },
    {
      key: 'puntingReturnYardage',
      value: 'Punts Yards',
      shortValue: 'PYDS',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.SPECIAL_TEAMS_ALL_RETURNS,
    },
    {
      key: 'kickPuntReturnsYards',
      value: 'Kickoffs Yards',
      shortValue: 'KYDS',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.SPECIAL_TEAMS_ALL_RETURNS,
    },
    {
      key: 'kickPuntReturnsLongest',
      value: 'Kickoffs Longest Return',
      shortValue: 'LNG',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.SPECIAL_TEAMS_ALL_RETURNS,
    },
    {
      key: 'kickoffsKickoffs',
      value: 'Number of Kicks',
      shortValue: 'NO',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.SPECIAL_TEAMS_KICKOFFS,
    },
    {
      key: 'kickoffsYards',
      value: 'Total Yards',
      shortValue: 'YDS',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.SPECIAL_TEAMS_KICKOFFS,
    },
    {
      key: 'kickoffsTouchbacks',
      value: 'Touchbacks',
      shortValue: 'TB',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.SPECIAL_TEAMS_KICKOFFS,
    },
    {
      key: 'kickoffsOutOfBounds',
      value: 'Out of Bounds',
      shortValue: 'OB',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.SPECIAL_TEAMS_KICKOFFS,
    },
    {
      key: 'kickoffsAverage',
      value: 'Average Length',
      shortValue: 'AVG',
      visible:
        type === STATS_BUTTONS.TYPE.PLAYER &&
        statsType === STATS_BUTTONS.STATS.SPECIAL_TEAMS_KICKOFFS,
    },
  ];
  return header.filter((header) => header.visible);
};
