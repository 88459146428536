import { Dispatch, SetStateAction } from 'react';

type UIStateAction<T> = Dispatch<SetStateAction<T>>;

export const PANEL = {
  CHECKLIST: 'checklist',
  PREFERENCES: 'preferences',
  FILTERS: 'filters',
  STATUSES: 'statuses',
} as const;
export type Panel = (typeof PANEL)[keyof typeof PANEL] | null;

export interface PanelHotkey {
  key: string;
  panel: Panel;
}

export type UIState = {
  openedPanel: Panel;
  setOpenedPanel: UIStateAction<Panel>;
  isFixtureInfoVisible: boolean;
  setFixtureInfoVisible: UIStateAction<boolean>;
  isDataCollectorsVisible: boolean;
  setIsDataCollectorsVisible: UIStateAction<boolean>;
  setHotkeysDisabled: UIStateAction<boolean>;
};
