import { Divider, Stack } from '@mui/material';
import { useContext, useMemo } from 'react';
import { LineupsPlayers } from '@/components/Lineups/LineupsPlayers';
import { ScoringContext } from '@/contexts/scoring/context';
import { LabelWithDivider } from '@/components/Lineups/LineupsStyledComponents';

export const Lineups = () => {
  const {
    state: { fixtureSummary },
  } = useContext(ScoringContext);

  const sortedPlayers = useMemo(
    () => ({
      homeTeam: fixtureSummary?.homeTeam.players.sort((a, b) =>
        +a.shirtNumber > +b.shirtNumber ? 1 : -1
      ),
      awayTeam: fixtureSummary?.awayTeam.players.sort((a, b) =>
        +a.shirtNumber > +b.shirtNumber ? 1 : -1
      ),
    }),
    [fixtureSummary]
  );

  const lineup = useMemo(
    () => ({
      startingLineupHomeTeam: sortedPlayers?.homeTeam?.filter(
        (player) => player.isStartingSquad
      ),
      substitutesHomeTeam: sortedPlayers?.homeTeam?.filter(
        (player) => !player.isStartingSquad
      ),
      startingLineupAwayTeam: sortedPlayers?.awayTeam?.filter(
        (player) => player.isStartingSquad
      ),
      substitutesAwayTeam: sortedPlayers?.awayTeam?.filter(
        (player) => !player.isStartingSquad
      ),
    }),
    [sortedPlayers]
  );

  return (
    <>
      <LabelWithDivider label='Starting lineup' />
      <Stack direction='row' gap={3} justifyContent='center'>
        <LineupsPlayers
          players={lineup?.startingLineupHomeTeam}
          teamName={fixtureSummary?.homeTeam.teamName}
        />

        <Divider orientation='vertical' flexItem />
        <LineupsPlayers
          awayTeam
          players={lineup?.startingLineupAwayTeam}
          teamName={fixtureSummary?.awayTeam.teamName}
        />
      </Stack>

      <LabelWithDivider label='Substitutes' />

      <Stack direction='row' gap={3} justifyContent='center'>
        <LineupsPlayers players={lineup?.substitutesHomeTeam} />
        <Divider orientation='vertical' flexItem />
        <LineupsPlayers awayTeam players={lineup?.substitutesAwayTeam} />
      </Stack>
    </>
  );
};
