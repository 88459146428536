import { useContext, useEffect, useMemo, useState } from 'react';
import { Paper, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { ScoringContext } from '@/contexts/scoring/context';
import { FixtureComparisonRating, FixtureScorerRating } from '@/service/types';
import { ComparisonRatingButtons } from '@/components/ComparisonRating/ComparisonRatingButtons';
import { ComparisonRatingComment } from '@/components/ComparisonRating/ComparisonRatingComment';
import { ComparisonRatingScore } from '@/components/ComparisonRating/ComparisonRatingScore';
import { ComparisonRatingInfo } from '@/components/ComparisonRating/ComparisonRatingInfo';
import { AddRatingButton } from '@/components/ComparisonRating/common';
import {
  calculateOverallScore,
  generateDefaultScorerRating,
} from '@/components/ComparisonRating/utils';

interface ComparisonRatingFormProps {
  rating: FixtureComparisonRating;
}

export const addNewRatingButtonTestId = 'add-new-rating-button-test-id';

export const ComparisonRatingForm = ({ rating }: ComparisonRatingFormProps) => {
  const {
    state: { fixtureId },
  } = useContext(ScoringContext);
  const [scorerRating, setScorerRating] = useState<
    FixtureScorerRating | undefined
  >();
  const personalisedScorerRating = useMemo(() => {
    return rating?.user?.scorerRatings.find(
      (rating) => rating.fixtureId === fixtureId
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rating]);

  useEffect(() => {
    if (personalisedScorerRating) {
      setScorerRating(calculateOverallScore(personalisedScorerRating));
    } else setScorerRating(undefined);
  }, [personalisedScorerRating]);

  return (
    <Paper
      elevation={1}
      sx={{
        width: 555,
      }}
    >
      <ComparisonRatingInfo comparisonRating={rating} />
      <Stack position='relative'>
        <ComparisonRatingScore
          newRating={scorerRating}
          setNewRating={setScorerRating}
        />
        <ComparisonRatingComment
          newRating={scorerRating}
          setNewRating={setScorerRating}
        />
        <ComparisonRatingButtons
          rating={personalisedScorerRating}
          newRating={scorerRating}
          setNewRating={setScorerRating}
        />
        {!scorerRating && (
          <AddRatingButton
            aria-hidden={!!scorerRating}
            variant='text'
            onClick={() =>
              setScorerRating(
                calculateOverallScore(
                  generateDefaultScorerRating(fixtureId, rating.user)
                )
              )
            }
          >
            <AddIcon fontSize='large' />
          </AddRatingButton>
        )}
      </Stack>
    </Paper>
  );
};
