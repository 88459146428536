import { ChecklistWarning } from '@/contexts/checklist/types';
import {
  CheckListElementType,
  CHECKLIST_ELEMENT_TYPE,
} from '@/service/types/checklist';

/**
 * Checklist time constraints in minutes.
 */
export const CHECKLIST_TIME_THRESHOLD: Partial<
  Record<CheckListElementType, number>
> = {
  [CHECKLIST_ELEMENT_TYPE.ATTENDANCE_GEO]: 60,
  [CHECKLIST_ELEMENT_TYPE.ATTENDANCE_SELFIE]: 45,
  [CHECKLIST_ELEMENT_TYPE.MATCH_CONDITIONS]: 35,
  [CHECKLIST_ELEMENT_TYPE.TEAM_COLOURS]: 30,
  [CHECKLIST_ELEMENT_TYPE.LINEUPS]: 20,
};

export const CHECKLIST_WARNING = {
  TIMEOUT: {
    [CHECKLIST_ELEMENT_TYPE.ATTENDANCE_GEO]: {
      name: 'device-location-timeout',
      message: 'Device location not received in time',
    } as ChecklistWarning,
    [CHECKLIST_ELEMENT_TYPE.ATTENDANCE_SELFIE]: {
      name: 'selfie-timeout',
      message: 'Selfie not checked in time',
    } as ChecklistWarning,
    [CHECKLIST_ELEMENT_TYPE.MATCH_CONDITIONS]: {
      name: 'match-conditions-timeout',
      message: 'Match conditions not received in time',
    } as ChecklistWarning,
    [CHECKLIST_ELEMENT_TYPE.TEAM_COLOURS]: {
      name: 'team-colours-timeout',
      message: 'Shirt colours not received in time',
    } as ChecklistWarning,
    [CHECKLIST_ELEMENT_TYPE.LINEUPS]: {
      name: 'lineups-timeout',
      message: 'Lineups not received in time',
    } as ChecklistWarning,
  } as Partial<Record<CheckListElementType, ChecklistWarning>>,
  LINEUPS_SQUAD: {
    name: 'lineups-squad',
    message: 'Players missing in SDM: ',
  } as ChecklistWarning,
} as const;
