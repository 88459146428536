import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import { FC, useContext } from 'react';
import { useSnackbar } from 'notistack';
import { DICTIONARY } from '@/constants/dictionary';
import { ScoringContext } from '@/contexts/scoring/context';
import { ChecklistContext } from '@/contexts/checklist/context';
import {
  SCORING_WORKER_HOST_ACTION,
  SetPostMatchCheckCompleteMsg,
} from '@/workers/scoring/types';
import { CHECKLIST_ELEMENT_TYPE } from '@/service/types/checklist';
import { LoadingOverlay } from '../common/LoadingOverlay';
import { DialogTitleWithClose } from '../common/DialogTitleWithClose';
import { ChecklistCheckbox } from './ChecklistCheckbox';
import { CheckboxWarning } from './CheckboxWarning';

export interface ChecklistProps {
  onClose: () => void;
}

export const Checklist: FC<ChecklistProps> = ({ onClose }) => {
  const {
    state: { fixtureChecklist, fixtureId },
    useDispatchWithResponse,
  } = useContext(ScoringContext);
  const checklistCtx = useContext(ChecklistContext);

  const isLoading = !fixtureChecklist;

  const { enqueueSnackbar } = useSnackbar();
  const setPostMatchCheck =
    useDispatchWithResponse<SetPostMatchCheckCompleteMsg>(
      SCORING_WORKER_HOST_ACTION.POST_MATCH_CHECK_COMPLETE_SET
    );
  const unsetPostMatchCheck =
    useDispatchWithResponse<SetPostMatchCheckCompleteMsg>(
      SCORING_WORKER_HOST_ACTION.POST_MATCH_CHECK_COMPLETE_UNSET
    );
  const onBeforePostMatchCheckError = () => {
    enqueueSnackbar('Post match check failed', { variant: 'error' });
  };
  const beforePostMatchCheck = (isChecked: boolean) => {
    const dispatchAction = isChecked ? unsetPostMatchCheck : setPostMatchCheck;
    return dispatchAction.dispatch({ fixtureId });
  };

  return (
    <Stack>
      <DialogTitleWithClose close={onClose}>
        {DICTIONARY.COMMON.CHECKLIST}
      </DialogTitleWithClose>
      <Divider />
      <FormGroup sx={{ px: 2 }}>
        {checklistCtx.checkboxes.map((checkbox) => {
          const hasWarnings = checkbox.warnings.length > 0;

          const isPostMatchCheckMissing =
            checkbox.type ===
              CHECKLIST_ELEMENT_TYPE.POST_MATCH_CHECK_COMPLETE &&
            checkbox.requiresConfirmation;
          const beforeChange = isPostMatchCheckMissing
            ? beforePostMatchCheck
            : undefined;
          const onBeforeChangeError = isPostMatchCheckMissing
            ? onBeforePostMatchCheckError
            : undefined;

          return (
            <Box key={checkbox.type}>
              <Stack direction='row' alignItems='center' py={1}>
                <FormControlLabel
                  disabled={checkbox.isDisabled}
                  control={
                    <ChecklistCheckbox
                      checklistCheckboxState={checkbox}
                      hasWarnings={hasWarnings}
                      beforeChange={beforeChange}
                      onBeforeChangeError={onBeforeChangeError}
                    />
                  }
                  label={checkbox.name}
                />
                {hasWarnings && (
                  <CheckboxWarning warnings={checkbox.warnings} />
                )}
              </Stack>
              <Divider />
            </Box>
          );
        })}
      </FormGroup>
      <LoadingOverlay isLoading={isLoading} />
    </Stack>
  );
};
