import { API_METHOD } from '../constants';
import { ApiError } from '../ApiError';

export const SERVICE_BASE_URL = import.meta.env.VITE_MT_API_URL;

export const SERVICE_ENDPOINT = {
  Fixture: (fixtureId?: string) => {
    const category = '/Fixture';
    return {
      fixtureConfig: {
        GET: `${category}/${fixtureId}/config`,
      },
      postMatchCheck: { GET: `${category}/${fixtureId}/post-match-qa` },
      reasonOptions: { GET: `${category}/get-reason-options` },
      fixtureCollectorsData: {
        GET: `${category}/${fixtureId}/get-data-collectors-details`,
      },
    };
  },
  ScoringRate: (fixtureId?: string) => {
    const category = '/ScoringRate';
    return {
      scoringRate: {
        GET: `${category}/${fixtureId}/fixture-resources`,
        POST: category,
        PUT: category,
        DELETE: category,
      },
    };
  },
  FixtureAction: (fixtureId: string) => {
    const category = '/FixtureAction';
    return {
      generateFakeAction: {
        GET: `${category}/${fixtureId}/generate-fake-action`,
      },
    };
  },
  FixtureActionComment: '/FixtureActionComment',
  FixtureChecklist: '/FixtureCheckList',
  UserAuthorization: {
    myRoles: {
      GET: '/UserAuthorization/my-roles',
    },
  },
} as const;

export type ServiceEndpoint =
  (typeof SERVICE_ENDPOINT)[keyof typeof SERVICE_ENDPOINT];

export const makeUrlWithParams = (
  url: string,
  params?: Record<string, string>
) => {
  if (!params) return new URL(url);
  const searchParams = new URLSearchParams(params).toString();
  if (!searchParams) return new URL(url);
  return new URL(`${url}?${searchParams}`);
};

export interface FetchFromMTServiceOptions {
  token: string;
  endpoint: string;
  queryParams?: Record<string, string>;
}

export const fetchFromMTService = async ({
  token,
  endpoint,
  queryParams,
}: FetchFromMTServiceOptions) => {
  const url = makeUrlWithParams(SERVICE_BASE_URL + endpoint, queryParams);
  const res = await fetch(url, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: API_METHOD.GET,
  });

  if (!res.ok && res.status !== 210) {
    const error = await ApiError.parseFromResponse(res);
    throw error;
  }
  const contentType = res.headers.get('content-type');
  if (!contentType || !contentType.includes('application/json'))
    return res.text();
  return res.json();
};
