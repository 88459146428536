import {
  Box,
  BoxProps,
  Button,
  debounce,
  Divider,
  Stack,
  TextField,
  ToggleButtonGroup,
} from '@mui/material';
import {
  ChangeEventHandler,
  FC,
  MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useEffectOnce } from 'usehooks-ts';
import InfoIcon from '@mui/icons-material/Info';
import { useAuthToken } from '@/contexts/auth/useAuthToken';
import { useFixtureConfig } from '@/service/hooks/useFixtureConfig';
import { ScoringContext } from '@/contexts/scoring/context';
import { PreferencesContext } from '@/contexts/preferences/context';
import { getFixtureActionTypes } from '@/service/utils/getFixtureActionTypes';
import {
  ActionConditionId,
  ACTION_CONDITION_ID,
  ACTION_CONDITION_NAME,
  ACTION_TYPE_PRIORITY,
} from '@/contexts/preferences/constants';

import { SoundId } from '@/contexts/notifications/types';
import { PREFERENCES_STRING } from '@/constants/dictionary';
import { ColoredButton } from '@/components/common/ColoredButton';
import { commonOptions as themeOptions } from '@/contexts/theme/monitoringTool.theme';
import { StaticFixtureActionType } from '@/service/types';
import { SectionTitleTypography } from '../PreferencesTypography';
import {
  ActionSoundSelectPopoverProps,
  SoundSelectPopover,
  onActionNotificationPopoverApply,
} from './ActionSoundSelectPopover';
import { ActionSoundFilterButton } from './SoundFilterButton';
import { splitArray } from './utils';

export const LABEL_FILTER_BY_NAME = 'Filter by name';

const ButtonsGrid: FC<BoxProps> = ({ children, onClick }) => (
  <Box
    onClick={onClick}
    display='grid'
    justifyItems='start'
    gridTemplateColumns='repeat(auto-fill, minmax(230px, 1fr))'
    gap={1}
  >
    {children}
  </Box>
);

export const Notifications: FC = () => {
  const {
    state: { fixtureId },
  } = useContext(ScoringContext);
  const token = useAuthToken();
  const { data: fixtureConfig } = useFixtureConfig({ token, fixtureId });
  const {
    actionSounds,
    soundsPreferences,
    isActionSoundsAppDefault,
    isActionSoundsUserDefault,
    actionConditionSounds,
    actions: {
      updateActionSounds,
      updateActionConditionSounds,
      restoreAppDefaultActionSounds,
      restoreUserDefaultActionSounds,
      updateUserDefaultActionSounds,
      setCurrentFixtureActionTypeIds,
    },
  } = useContext(PreferencesContext);

  const [popoverProps, setPopoverProps] = useState<
    Omit<ActionSoundSelectPopoverProps, 'onApply' | 'onClose' | 'sounds'>
  >({ isOpen: false });

  const actionConditions = Object.values(
    ACTION_CONDITION_ID
  ) as ActionConditionId[];

  const actionTypes = useMemo(() => {
    if (!fixtureConfig) return [];
    return getFixtureActionTypes(fixtureConfig);
  }, [fixtureConfig]);

  useEffect(() => {
    setCurrentFixtureActionTypeIds(actionTypes.map(({ id }) => id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionTypes]);

  const selectingSoundForActionType = useRef<number | undefined>(undefined);
  const selectingSoundForActionCondition = useRef<
    ActionConditionId | undefined
  >(undefined);

  const [displayActionTypes, setDisplayActionTypes] = useState(actionTypes);

  const groupedActionTypes = useMemo(
    () =>
      splitArray(displayActionTypes, ['important', 'other'], (actionType) =>
        ACTION_TYPE_PRIORITY.includes(actionType.id) ? 'important' : 'other'
      ),
    [displayActionTypes]
  );

  const [displayActionConditions, setDisplayActionConditions] =
    useState(actionConditions);

  const [soundIdFilter, setSoundIdFilter] = useState<SoundId | null>(null);
  const [actionTypeNameFilter, setActionTypeNameFilter] = useState<string>('');

  useEffect(() => {
    setDisplayActionTypes(() => {
      if (!actionTypeNameFilter && soundIdFilter === null) return actionTypes;
      return actionTypes.filter(({ id, name }) => {
        const nameMatch = !actionTypeNameFilter
          ? true
          : name
              .toLocaleLowerCase()
              .includes(actionTypeNameFilter.toLocaleLowerCase());

        const soundFilterMatch =
          soundIdFilter === null
            ? true
            : actionSounds.some(
                (sound) => sound[0] === id && sound[1] === soundIdFilter
              );
        return nameMatch && soundFilterMatch;
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [soundIdFilter, actionTypeNameFilter]);

  useEffect(() => {
    setDisplayActionConditions(() => {
      if (soundIdFilter === null) return actionConditions;
      return actionConditions.filter((actionConditionId) => {
        return actionConditionSounds.some(
          (sound) =>
            sound[0] === actionConditionId && sound[1] === soundIdFilter
        );
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [soundIdFilter]);

  const onActionNameFilterChange = useMemo(
    () =>
      debounce<ChangeEventHandler<HTMLInputElement>>((e) => {
        setActionTypeNameFilter(e.target.value);
      }, 500),
    []
  );

  const onPopoverClose = useCallback(() => {
    selectingSoundForActionCondition.current = undefined;
    selectingSoundForActionType.current = undefined;
    setPopoverProps((prev) => ({
      ...prev,
      isOpen: false,
    }));
  }, []);

  const onPopoverApply = useCallback<onActionNotificationPopoverApply>(
    ({ soundId }) => {
      if (selectingSoundForActionCondition.current !== undefined) {
        updateActionConditionSounds([
          selectingSoundForActionCondition.current,
          soundId,
        ]);
      } else if (selectingSoundForActionType.current !== undefined) {
        updateActionSounds([selectingSoundForActionType.current, soundId]);
      }
      onPopoverClose();
    },
    [onPopoverClose, updateActionConditionSounds, updateActionSounds]
  );

  const getSelectedActionConditionSoundId = useCallback(
    (actionConditionId: ActionConditionId) => {
      const actionConditionSound = actionConditionSounds.find(
        (actionSound) => actionSound[0] === actionConditionId
      );
      return actionConditionSound && actionConditionSound[1];
    },
    [actionConditionSounds]
  );
  const getSelectedActionSoundId = useCallback(
    (actionTypeId: number) => {
      const actionSound = actionSounds.find(
        (actionSound) => actionSound[0] === actionTypeId
      );
      return actionSound && actionSound[1];
    },
    [actionSounds]
  );

  const onActionSoundButtonClick: MouseEventHandler<HTMLElement> = useCallback(
    (e) => {
      const target = e.target as HTMLElement;
      const popoverTitle = target.dataset['popoverTitle'];
      const selectedSoundIdData = target.dataset['selectedSoundId'];
      const selectedSoundId = selectedSoundIdData
        ? Number(selectedSoundIdData)
        : undefined;
      setPopoverProps({
        title: popoverTitle,
        isOpen: true,
        anchorEl: target,
        currentSoundId: selectedSoundId,
      });
    },
    []
  );
  const onActionTypeSoundButtonClick: MouseEventHandler<HTMLElement> =
    useCallback(
      (e) => {
        const target = e.target as HTMLElement;
        const actionTypeIdDataset = target.dataset['actionTypeId'];
        if (!actionTypeIdDataset) return;
        const actionTypeId = Number(actionTypeIdDataset);
        selectingSoundForActionType.current = actionTypeId;
        selectingSoundForActionCondition.current = undefined;
        return onActionSoundButtonClick(e);
      },
      [onActionSoundButtonClick]
    );
  const onActionConditionSoundButtonClick: MouseEventHandler<HTMLElement> =
    useCallback(
      (e) => {
        const target = e.target as HTMLElement;
        const actionConditionDataset = target.dataset['actionConditionId'];
        if (!actionConditionDataset) return;
        selectingSoundForActionType.current = undefined;
        selectingSoundForActionCondition.current = Number(
          actionConditionDataset
        ) as ActionConditionId;
        return onActionSoundButtonClick(e);
      },
      [onActionSoundButtonClick]
    );

  useEffectOnce(() => {
    return () => {
      onActionNameFilterChange.clear();
      setSoundIdFilter(null);
    };
  });

  const renderActionConditionSoundButton = useCallback(
    (actionConditionId: ActionConditionId) => {
      const actionConditionName = ACTION_CONDITION_NAME[actionConditionId];
      const selectedSoundId =
        getSelectedActionConditionSoundId(actionConditionId);
      const soundColor =
        selectedSoundId !== undefined
          ? soundsPreferences.sounds[selectedSoundId].color
          : themeOptions.palette.defaultSoundButton;

      return (
        <ColoredButton
          customColor={soundColor}
          key={actionConditionId}
          size='small'
          variant='contained'
          data-action-condition-id={actionConditionId}
          data-popover-title={actionConditionName}
          data-selected-sound-id={selectedSoundId}
        >
          {actionConditionName}
        </ColoredButton>
      );
    },
    [getSelectedActionConditionSoundId, soundsPreferences.sounds]
  );
  const renderActionTypeSoundButton = useCallback(
    ({ id, name }: StaticFixtureActionType) => {
      const selectedSoundId = getSelectedActionSoundId(id);
      const soundColor =
        selectedSoundId !== undefined
          ? soundsPreferences.sounds[selectedSoundId].color
          : themeOptions.palette.defaultSoundButton;
      return (
        <ColoredButton
          customColor={soundColor}
          key={id}
          size='small'
          variant={'contained'}
          data-action-type-id={id}
          data-popover-title={name}
          data-selected-sound-id={selectedSoundId}
        >
          {name}
        </ColoredButton>
      );
    },
    [getSelectedActionSoundId, soundsPreferences.sounds]
  );

  const hasAnySoundAssigned =
    !!displayActionConditions.length ||
    !!groupedActionTypes.important.length ||
    !!groupedActionTypes.other.length;

  const hasAnyActionTypeSoundAssigned =
    !!groupedActionTypes.important.length || !!groupedActionTypes.other.length;

  const hasBothGroupedActionTypeSounds =
    !!groupedActionTypes.important.length && !!groupedActionTypes.other.length;

  return (
    <Stack gap={2}>
      <TextField
        label={LABEL_FILTER_BY_NAME}
        variant='outlined'
        size='small'
        onChange={onActionNameFilterChange}
      />
      <SectionTitleTypography>Filter by sound</SectionTitleTypography>
      <ToggleButtonGroup
        color='primary'
        value={soundIdFilter}
        fullWidth
        size='small'
        exclusive
        onChange={(e, val) => setSoundIdFilter(val)}
      >
        {Object.values(soundsPreferences.sounds).map(({ id, name, color }) => {
          return (
            <ActionSoundFilterButton key={id} value={id} soundColor={color}>
              {name}
            </ActionSoundFilterButton>
          );
        })}
      </ToggleButtonGroup>

      {!hasAnySoundAssigned && (
        <>
          <SectionTitleTypography
            justifyContent={'center'}
            alignItems={'center'}
            display={'flex'}
            gap={0.5}
          >
            <InfoIcon /> No actions assigned to this sound
          </SectionTitleTypography>
          <Divider flexItem />
        </>
      )}

      {!!displayActionConditions.length && (
        <>
          <SectionTitleTypography>
            Action Condition Settings
          </SectionTitleTypography>
          <ButtonsGrid onClick={onActionConditionSoundButtonClick}>
            {displayActionConditions.map(renderActionConditionSoundButton)}
          </ButtonsGrid>
          <Divider flexItem />
        </>
      )}

      {hasAnyActionTypeSoundAssigned && (
        <>
          <SectionTitleTypography>Action Type Settings</SectionTitleTypography>
          {!!groupedActionTypes.important.length && (
            <ButtonsGrid onClick={onActionTypeSoundButtonClick}>
              {groupedActionTypes.important.map(renderActionTypeSoundButton)}
            </ButtonsGrid>
          )}

          {hasBothGroupedActionTypeSounds && <Divider flexItem />}

          {!!groupedActionTypes.other.length && (
            <ButtonsGrid onClick={onActionTypeSoundButtonClick}>
              {groupedActionTypes.other.map(renderActionTypeSoundButton)}
            </ButtonsGrid>
          )}

          <Divider flexItem />
        </>
      )}

      <Stack direction='row' justifyContent='flex-end' flexWrap='wrap' gap={1}>
        <Button
          size='small'
          variant='text'
          onClick={restoreAppDefaultActionSounds}
          disabled={isActionSoundsAppDefault}
        >
          {PREFERENCES_STRING.APPLY_APP_DEFAULT}
        </Button>
        <Button
          size='small'
          variant='text'
          onClick={restoreUserDefaultActionSounds}
          disabled={isActionSoundsUserDefault}
        >
          {PREFERENCES_STRING.APPLY_USER_DEFAULT}
        </Button>
        <Button
          size='small'
          variant='contained'
          onClick={updateUserDefaultActionSounds}
          disabled={isActionSoundsUserDefault}
        >
          {PREFERENCES_STRING.SAVE_USER_DEFAULT}
        </Button>
      </Stack>
      <SoundSelectPopover
        onApply={onPopoverApply}
        onClose={onPopoverClose}
        sounds={soundsPreferences.sounds}
        {...popoverProps}
      />
    </Stack>
  );
};
