import { Stack } from '@mui/material';
import { FC, useState } from 'react';
import { TabPanel } from '../common/TabPanel';
import { PreferencesTabsProps, PreferencesTabs } from './PreferencesTabs';
import { PREFERENCES_TABS } from './constants';
import { PreferencesTabName } from './types';

export const Preferences: FC = () => {
  const [currentTab, setCurrentTab] = useState<PreferencesTabName>(
    PREFERENCES_TABS[0].tabs[0].name
  );

  const onTabChange: PreferencesTabsProps['onChange'] = (e, selectedTab) =>
    setCurrentTab(selectedTab);

  return (
    <>
      <PreferencesTabs onChange={onTabChange} currentTab={currentTab} />
      <Stack
        overflow='auto'
        flexGrow={1}
        padding={({ spacing }) => spacing(1, 2, 2)}
      >
        {PREFERENCES_TABS.map(({ tabs }) =>
          tabs.map(({ component, name, ariaName: id }) => (
            <TabPanel key={name} name={name} ariaName={id} value={currentTab}>
              {component}
            </TabPanel>
          ))
        )}
      </Stack>
    </>
  );
};
