import { Divider } from '@mui/material';
import { BasketballStatsButtons } from '@/components/StatsButtons/BasketballStatsButtons';
import { BasketballStatsTable } from '@/components/BasketballStatsTable/BasketballStatsTable';

export const FixtureBasketballStats = () => {
  return (
    <>
      <BasketballStatsButtons />
      <Divider />
      <BasketballStatsTable />
    </>
  );
};
