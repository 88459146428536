import {
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { useFixtureConfig } from '@/service/hooks/useFixtureConfig';
import { useAuthToken } from '../auth/useAuthToken';
import { ScoringContext } from '../scoring/context';
import { ChecklistContext } from './context';
import { checklistReducer, getChecklistDefaultState } from './reducer';
import { ChecklistContextValue, CHECKLIST_REDUCER_ACTION } from './types';

if (import.meta.hot) {
  import.meta.hot.accept(() => {
    import.meta.hot?.invalidate();
  });
}

/**
 * Prepares all the data for the checklist.
 * Provides all necessary dispatch functions.
 */
export const ChecklistProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    state: { checklistActions, fixtureChecklist, fixtureSummary, fixtureId },
  } = useContext(ScoringContext);
  const token = useAuthToken();
  const { data: fixtureConfig } = useFixtureConfig({ token, fixtureId });
  const [checkboxes, checkboxesDispatch] = useReducer(
    checklistReducer,
    getChecklistDefaultState()
  );

  useEffect(() => {
    if (!fixtureChecklist) return;
    checkboxesDispatch({
      action: CHECKLIST_REDUCER_ACTION.CHECKBOXES,
      payload: fixtureChecklist.map(({ type }) => type),
    });
  }, [fixtureChecklist]);

  useEffect(() => {
    if (fixtureChecklist && checklistActions && fixtureSummary && fixtureConfig)
      checkboxesDispatch({
        action: CHECKLIST_REDUCER_ACTION.VALIDATE,
        payload: {
          actions: checklistActions,
          summary: fixtureSummary,
          config: fixtureConfig,
        },
      });
  }, [fixtureChecklist, checklistActions, fixtureSummary, fixtureConfig]);

  const value: ChecklistContextValue = {
    checkboxes,
  };
  return (
    <ChecklistContext.Provider value={value}>
      {children}
    </ChecklistContext.Provider>
  );
};
