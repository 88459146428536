import useSWR from 'swr';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { comparePostMatchStatistics } from '@/service/utils/comparePostMatchStatistics';
import { COMMON_STRING } from '@/constants/dictionary';
import {
  FixturePostMatchCheck,
  FixturePostMatchCheckStatistics,
  FixtureSummary,
} from '../types';
import {
  fetchFromMTService,
  SERVICE_ENDPOINT,
} from '../fetcher/monitoringToolService';
import { ApiError } from '../ApiError';
import { HTTP_STATUS_CODE } from '../constants';
interface UseFixturePostMatchCheckParams {
  token: string | undefined;
  fixtureId: string;
  fixtureSummary: FixtureSummary | null;
}

export const forceStatsType = (
  statistics: FixturePostMatchCheckStatistics | undefined,
  statType: string
) => {
  if (statistics === undefined) {
    return { statsType: statType };
  }
  if (!statistics.statsType) {
    return { ...statistics, statsType: statType };
  }
  return statistics;
};

// export const getComparisonData = (
//   data: FixturePostMatchCheck | null | undefined,
//   fixtureSummary: FixtureSummary | null
// ) => {
//   const internalHome = forceStatsType(
//     data?.internalTeamsStatistics.find(
//       (statistics) => statistics.teamName === fixtureSummary?.homeTeam.teamName
//     ),
//     COMMON_STRING.STATS.INTERNAL_HOME
//   );
//   const internalAway = forceStatsType(
//     data?.internalTeamsStatistics.find(
//       (statistics) => statistics.teamName === fixtureSummary?.awayTeam.teamName
//     ),
//     COMMON_STRING.STATS.INTERNAL_AWAY
//   );
//   const externalHome = forceStatsType(
//     data?.externalApiTeamStatistics?.find((statistics) => statistics.isHome),
//     COMMON_STRING.STATS.EXTERNAL_HOME
//   );
//   const externalAway = forceStatsType(
//     data?.externalApiTeamStatistics?.find((statistics) => !statistics.isHome),
//     COMMON_STRING.STATS.EXTERNAL_AWAY
//   );
//
//   return [
//     ...comparePostMatchStatistics(internalHome, externalHome),
//     ...comparePostMatchStatistics(internalAway, externalAway),
//   ];
// };

export const useFixturePostMatchCheck = ({
  token,
  fixtureId,
  fixtureSummary,
}: UseFixturePostMatchCheckParams) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isUpdating, setIsUpdating] = useState(false);
  const { data, error, mutate } = useSWR<FixturePostMatchCheck, ApiError>(
    token && fixtureId
      ? {
          token,
          endpoint: SERVICE_ENDPOINT.Fixture(fixtureId).postMatchCheck.GET,
        }
      : null,
    fetchFromMTService,
    {
      onErrorRetry: (e, _, config, revalidate, { retryCount }) => {
        if (
          e.status !== HTTP_STATUS_CODE.NOT_FOUND &&
          e.status !== HTTP_STATUS_CODE.UNAUTHORIZED
        ) {
          return enqueueSnackbar(e.title, { variant: 'error' });
        }
        if (e.status === HTTP_STATUS_CODE.NOT_FOUND) return;
        if (e.status === HTTP_STATUS_CODE.UNAUTHORIZED) return;
        setTimeout(() => revalidate({ retryCount }), config.errorRetryInterval);
      },
    }
  );

  const refreshComparisonData = () => {
    setIsUpdating(true);
    mutate()
      .then(() => {
        setIsUpdating(false);
        enqueueSnackbar('Comparison data successfully updated', {
          variant: 'success',
        });
      })
      .catch((e) => {
        setIsUpdating(false);
        enqueueSnackbar(e.title, { variant: 'error' });
      });
  };

  const internalHome = forceStatsType(
    data?.internalTeamsStatistics.find(
      (statistics) => statistics.teamName === fixtureSummary?.homeTeam.teamName
    ),
    COMMON_STRING.STATS.INTERNAL_HOME
  );
  const internalAway = forceStatsType(
    data?.internalTeamsStatistics.find(
      (statistics) => statistics.teamName === fixtureSummary?.awayTeam.teamName
    ),
    COMMON_STRING.STATS.INTERNAL_AWAY
  );
  const externalHome = forceStatsType(
    data?.externalApiTeamStatistics?.find((statistics) => statistics.isHome),
    COMMON_STRING.STATS.EXTERNAL_HOME
  );
  const externalAway = forceStatsType(
    data?.externalApiTeamStatistics?.find((statistics) => !statistics.isHome),
    COMMON_STRING.STATS.EXTERNAL_AWAY
  );

  const comparisonData = [
    ...comparePostMatchStatistics(internalHome, externalHome),
    ...comparePostMatchStatistics(internalAway, externalAway),
  ];

  return {
    comparisonData,
    externalProviderUrl: data?.externalStatisticsProviderUrl,
    error,
    isLoading: !data && !error,
    refreshComparisonData,
    isUpdating,
  };
};
