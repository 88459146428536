import { FC, PropsWithChildren, useContext, useState } from 'react';
import { useEventListener } from 'usehooks-ts';
import { PreferencesContext } from '../preferences/context';
import { THEME_MODE } from '../theme/constants';
import { UIStateContext } from './context';
import { PanelHotkey, Panel, UIState, PANEL } from './types';

export const KEY_EVENT_TAGS_BLACKLIST = ['INPUT', 'TEXTAREA'];
export const KEY_EVENT_INPUT_TYPE_WHITELIST = ['checkbox', 'radio'];

export const PANEL_HOTKEYS: PanelHotkey[] = [
  { key: 'c', panel: PANEL.CHECKLIST },
  { key: 'f', panel: PANEL.FILTERS },
  { key: 'p', panel: PANEL.PREFERENCES },
];

export const UiStateProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    themeMode,
    actions: { setThemeMode },
  } = useContext(PreferencesContext);
  const [openedPanel, setOpenedPanel] = useState<Panel>(null);
  const [isFixtureInfoVisible, setFixtureInfoVisible] = useState(false);
  const [isDataCollectorsVisible, setIsDataCollectorsVisible] = useState(false);
  const [hotkeysDisabled, setHotkeysDisabled] = useState<boolean>(false);

  const setOpenedPanelWithToggle: UIState['setOpenedPanel'] = (panel) =>
    setOpenedPanel(openedPanel === panel ? null : panel);

  const onKeyPress = (e: KeyboardEvent) => {
    if (
      e.target &&
      KEY_EVENT_TAGS_BLACKLIST.includes((e.target as HTMLElement).tagName) &&
      (e.target as HTMLInputElement).type &&
      !KEY_EVENT_INPUT_TYPE_WHITELIST.includes(
        (e.target as HTMLInputElement).type
      )
    ) {
      return;
    }
    const panelHotkey = PANEL_HOTKEYS.find(({ key }) => key === e.key);

    if (hotkeysDisabled) return;
    if (e.key === 'i') {
      setFixtureInfoVisible(!isFixtureInfoVisible);
    }
    if (e.key === 'd') {
      setIsDataCollectorsVisible(!isDataCollectorsVisible);
    }
    if (e.key === 't') {
      setThemeMode(
        themeMode === THEME_MODE.DARK ? THEME_MODE.LIGHT : THEME_MODE.DARK
      );
    }
    if (panelHotkey) {
      return setOpenedPanelWithToggle(panelHotkey.panel);
    }
  };

  useEventListener('keypress', onKeyPress);

  const providerValue: UIState = {
    openedPanel,
    setOpenedPanel: setOpenedPanelWithToggle,
    isFixtureInfoVisible,
    setFixtureInfoVisible,
    setHotkeysDisabled,
    isDataCollectorsVisible,
    setIsDataCollectorsVisible,
  };
  return (
    <UIStateContext.Provider value={providerValue}>
      {children}
    </UIStateContext.Provider>
  );
};
