import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import Close from '@mui/icons-material/Close';
import { FixtureConfig, Player } from '@/service/types';
import { ScoringContext } from '@/contexts/scoring/context';
import {
  MATCH_ACTION_SEND_TYPE_NAME,
  UNKNOWN_PLAYER_ID,
  UNKNOWN_PLAYER_NAME,
} from '@/constants';
import { COMMON_STRING } from '@/constants/dictionary';
import { getFixtureActionTypes } from '@/service/utils/getFixtureActionTypes';
import { DialogTitleWithClose } from '../../common/DialogTitleWithClose';
import { ActionFilter } from './useActionsFilters';
import {
  FILTER_DISPLAY_NAME,
  FILTER_PROPERTY,
  GenericFilterValue,
  GENERIC_FILTER_VALUE,
} from './constants';
import { AutocompleteFilter } from './AutocompleteFilter';
import { GenericFilterRadios } from './GenericFilterRadios';
import { isInFilters } from './utils';

export interface ActionFiltersFormProps {
  fixtureConfig?: FixtureConfig;
  filters: ActionFilter[];
  onApply: (newFilters: ActionFilter[]) => void;
  onCancel: () => void;
}

const makePlayerFilter = (
  playerRadio: GenericFilterValue,
  selectedPlayers: ActionFilter[]
): ActionFilter[] => {
  if (playerRadio === GENERIC_FILTER_VALUE.UNSET) return [];
  if (playerRadio === GENERIC_FILTER_VALUE.UNKNOWN)
    return [
      {
        property: FILTER_PROPERTY.PLAYER_ID,
        value: UNKNOWN_PLAYER_ID,
        displayName: FILTER_DISPLAY_NAME.PLAYER_ID,
        displayValue: 'Unknown',
      },
    ];
  if (playerRadio === GENERIC_FILTER_VALUE.FALSY) {
    return [
      {
        property: FILTER_PROPERTY.PLAYER_ID,
        value: undefined,
        displayName: FILTER_DISPLAY_NAME.PLAYER_ID,
        displayValue: 'none',
      },
    ];
  }
  if (selectedPlayers.length > 0) return selectedPlayers;
  return [
    {
      property: FILTER_PROPERTY.PLAYER_ID,
      value: GENERIC_FILTER_VALUE.TRUTHY,
      displayName: FILTER_DISPLAY_NAME.PLAYER_ID,
      displayValue: 'Assigned',
    },
  ];
};

const SEND_TYPE_OPTIONS: ActionFilter[] = Object.entries(
  MATCH_ACTION_SEND_TYPE_NAME
).map(([id, name]) => ({
  property: FILTER_PROPERTY.SEND_TYPE_ID,
  value: Number(id),
  displayName: FILTER_DISPLAY_NAME.SEND_TYPE_ID,
  displayValue: name,
}));

export const ActionFiltersForm: FC<ActionFiltersFormProps> = ({
  fixtureConfig,
  filters,
  onApply,
  onCancel,
}) => {
  const {
    state: { fixtureSummary },
  } = useContext(ScoringContext);

  const [actionId, setActionId] = useState('');
  const [selectedActionTypes, setSelectedActionTypes] = useState<
    ActionFilter[]
  >([]);
  const [teams, setTeams] = useState<
    {
      id: string | undefined;
      name: string;
      checked: boolean;
    }[]
  >([]);
  const [selectedPlayers, setSelectedPlayers] = useState<ActionFilter[]>([]);
  const [selectedSendTypeIds, setSelectedSendTypeIds] = useState<
    ActionFilter[]
  >([]);

  const [playerRadio, setPlayerRadio] = useState<GenericFilterValue>('');
  const [flagRadio, setFlagRadio] = useState<GenericFilterValue>('');
  const [commentRadio, setCommentRadio] = useState<GenericFilterValue>('');

  const onActionTypesChange = (event: any, newValue: ActionFilter[]) => {
    setSelectedActionTypes(newValue);
  };

  const onSelectedPlayersChange = (event: any, newValue: ActionFilter[]) => {
    setSelectedPlayers(newValue);
  };
  const onSelectedSendTypeIdsChange = (
    event: any,
    newValue: ActionFilter[]
  ) => {
    setSelectedSendTypeIds(newValue);
  };

  const onTeamChecked = (index: number, checked: boolean) => {
    const newTeams = [...teams];
    newTeams[index].checked = checked;
    setTeams(newTeams);
  };

  const actionTypeOptions: ActionFilter[] = useMemo(() => {
    if (!fixtureConfig) return [];
    return getFixtureActionTypes(fixtureConfig).map(
      ({ id, name }): ActionFilter => ({
        value: id,
        displayValue: name,
        property: FILTER_PROPERTY.FIXTURE_ACTION_TYPE_ID,
        displayName: FILTER_DISPLAY_NAME.FIXTURE_ACTION_TYPE_ID,
      })
    );
  }, [fixtureConfig]);

  const playersOptions: ActionFilter[] = useMemo(() => {
    if (!fixtureSummary) return [];
    const allPlayers = [
      ...fixtureSummary.homeTeam.players,
      ...fixtureSummary.awayTeam.players,
    ];
    if (fixtureConfig) {
      const configPlayers = [
        ...fixtureConfig.homeSquad.players,
        ...fixtureConfig.awaySquad.players,
      ];
      configPlayers.forEach((configPlayer) => {
        if (
          allPlayers.some(
            (summaryPlayer) => summaryPlayer.id === configPlayer.id
          )
        ) {
          return;
        }
        allPlayers.push(configPlayer);
      });
    }
    const unknownPlayer = {
      id: UNKNOWN_PLAYER_ID,
      fullName: UNKNOWN_PLAYER_NAME,
    } as Player;
    allPlayers.push(unknownPlayer);

    return allPlayers.map((player) => ({
      property: FILTER_PROPERTY.PLAYER_ID,
      value: player.id,
      displayName: FILTER_DISPLAY_NAME.PLAYER_ID,
      displayValue: player.shirtNumber
        ? `${player.shirtNumber}. ${player.fullName}`
        : player.fullName,
    }));
  }, [fixtureSummary, fixtureConfig]);

  useEffect(() => {
    if (!fixtureConfig) return;
    const { homeSquad, awaySquad } = fixtureConfig;
    const newTeams = [
      {
        id: undefined,
        name: 'None',
        checked: isInFilters(filters, FILTER_PROPERTY.TEAM_ID, undefined),
      },
      {
        id: homeSquad.team.id,
        name: homeSquad.team.name,
        checked: isInFilters(
          filters,
          FILTER_PROPERTY.TEAM_ID,
          homeSquad.team.id
        ),
      },
      {
        id: awaySquad.team.id,
        name: awaySquad.team.name,
        checked: isInFilters(
          filters,
          FILTER_PROPERTY.TEAM_ID,
          awaySquad.team.id
        ),
      },
    ];
    setTeams(newTeams);
  }, [fixtureConfig, filters]);

  useEffect(() => {
    const newSelectedActionTypes = filters.filter(
      ({ property }) => property === FILTER_PROPERTY.FIXTURE_ACTION_TYPE_ID
    );
    setSelectedActionTypes(newSelectedActionTypes);

    const newSelectedPlayers = filters.filter(
      ({ property, value }) =>
        property === FILTER_PROPERTY.PLAYER_ID &&
        value !== undefined &&
        value !== GENERIC_FILTER_VALUE.TRUTHY
    );
    setSelectedPlayers(newSelectedPlayers);

    const newSendTypeIds = filters.filter(
      ({ property }) => property === FILTER_PROPERTY.SEND_TYPE_ID
    );
    setSelectedSendTypeIds(newSendTypeIds);
  }, [filters]);

  useEffect(() => {
    const newActionId = filters.find(
      ({ property }) => property === FILTER_PROPERTY.ACTION_ID
    );
    if (!newActionId) {
      setActionId('');
    } else if (
      newActionId.value !== actionId &&
      typeof newActionId.value === 'string'
    ) {
      setActionId(newActionId.value);
    }

    const playerFilter = filters.find(
      ({ property }) => property === FILTER_PROPERTY.PLAYER_ID
    );
    const playerValue: GenericFilterValue = !playerFilter
      ? GENERIC_FILTER_VALUE.UNSET
      : playerFilter.value === UNKNOWN_PLAYER_ID
      ? GENERIC_FILTER_VALUE.UNKNOWN
      : playerFilter.value === undefined
      ? GENERIC_FILTER_VALUE.FALSY
      : GENERIC_FILTER_VALUE.TRUTHY;
    playerValue !== playerRadio && setPlayerRadio(playerValue);

    const flagFilter = filters.find(
      ({ property }) => property === FILTER_PROPERTY.FLAG
    );
    const flagValue: GenericFilterValue = !flagFilter
      ? GENERIC_FILTER_VALUE.UNSET
      : flagFilter.value === false
      ? GENERIC_FILTER_VALUE.FALSY
      : GENERIC_FILTER_VALUE.TRUTHY;
    flagValue !== flagRadio && setFlagRadio(flagValue);

    const commentFilter = filters.find(
      ({ property }) => property === FILTER_PROPERTY.COMMENT
    );
    const commentValue: GenericFilterValue = !commentFilter
      ? GENERIC_FILTER_VALUE.UNSET
      : commentFilter.value === false
      ? GENERIC_FILTER_VALUE.FALSY
      : GENERIC_FILTER_VALUE.TRUTHY;
    commentValue !== commentRadio && setCommentRadio(commentValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const onConfirm = () => {
    const actionIdFilter: ActionFilter[] = actionId
      ? [
          {
            property: FILTER_PROPERTY.ACTION_ID,
            value: actionId,
            displayName: FILTER_DISPLAY_NAME.ACTION_ID,
            displayValue: actionId,
          },
        ]
      : [];

    const teamsFilters: ActionFilter[] = teams
      .filter(({ checked }) => checked)
      .map((team) => ({
        displayValue: team.name,
        displayName: FILTER_DISPLAY_NAME.TEAM_ID,
        property: FILTER_PROPERTY.TEAM_ID,
        value: team.id || '',
      }));

    const flagFilter: ActionFilter[] =
      flagRadio === GENERIC_FILTER_VALUE.UNSET
        ? []
        : [
            {
              property: FILTER_PROPERTY.FLAG,
              value: flagRadio,
              displayName: 'Flag',
              displayValue:
                flagRadio === GENERIC_FILTER_VALUE.TRUTHY
                  ? 'Flagged'
                  : 'Not Flagged',
            },
          ];

    const commentFilter: ActionFilter[] =
      commentRadio === GENERIC_FILTER_VALUE.UNSET
        ? []
        : [
            {
              property: FILTER_PROPERTY.COMMENT,
              value: commentRadio,
              displayName: 'Comments',
              displayValue:
                commentRadio === GENERIC_FILTER_VALUE.TRUTHY
                  ? 'With Comment'
                  : 'Without Comment',
            },
          ];

    const playerFilter = makePlayerFilter(playerRadio, selectedPlayers);

    const composedFilters = [
      ...actionIdFilter,
      ...selectedActionTypes,
      ...selectedSendTypeIds,
      ...teamsFilters,
      ...playerFilter,
      ...flagFilter,
      ...commentFilter,
    ];
    onApply(composedFilters);
  };

  return (
    <Stack
      height='100%'
      alignItems='stretch'
      justifyContent='space-between'
      width={480}
      maxWidth='90vw'
      divider={<Divider flexItem />}
    >
      <DialogTitleWithClose close={onCancel}>Filters</DialogTitleWithClose>

      <Stack
        py={2}
        gap={2}
        flex={1}
        overflow='auto'
        divider={<Divider flexItem />}
      >
        <Stack px={2} gap={1.5}>
          <Typography variant='subtitle2'>Filter by</Typography>
          <TextField
            type='text'
            size='small'
            value={actionId}
            onChange={(e) => setActionId(e.target.value)}
            label='Action ID'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  {actionId && (
                    <IconButton
                      hidden={!actionId}
                      onClick={() => setActionId('')}
                    >
                      <Close />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />

          <AutocompleteFilter
            options={actionTypeOptions}
            onChange={onActionTypesChange}
            selectedOptions={selectedActionTypes}
            label='Action Type'
          />

          <AutocompleteFilter
            options={SEND_TYPE_OPTIONS}
            selectedOptions={selectedSendTypeIds}
            onChange={onSelectedSendTypeIdsChange}
            label='Send Type'
          />
        </Stack>

        <Stack px={2}>
          <Typography variant='subtitle2'>Team</Typography>
          <FormGroup>
            {teams.map((team, index) => (
              <FormControlLabel
                key={team.id || ''}
                label={team.name}
                control={
                  <Checkbox
                    size='small'
                    onChange={(_, checked) => onTeamChecked(index, checked)}
                    checked={team.checked}
                  />
                }
              />
            ))}
          </FormGroup>
        </Stack>

        <Stack px={2}>
          <Typography variant='subtitle2'>Player</Typography>
          <GenericFilterRadios
            value={playerRadio}
            onValueChange={setPlayerRadio}
            labels={{
              unset: 'All',
              falsy: 'None',
              truthy: 'Assigned',
              unknown: 'Unknown',
            }}
          />

          <AutocompleteFilter
            options={playersOptions}
            onChange={onSelectedPlayersChange}
            selectedOptions={selectedPlayers}
            label='Players'
            disabled={playerRadio !== GENERIC_FILTER_VALUE.TRUTHY}
          />
        </Stack>

        <Stack px={2}>
          <Typography variant='subtitle2'>Comment</Typography>
          <GenericFilterRadios
            value={commentRadio}
            onValueChange={setCommentRadio}
            labels={{
              unset: 'All',
              falsy: 'Without comment',
              truthy: 'With comment',
            }}
          />
        </Stack>

        <Stack px={2}>
          <Typography variant='subtitle2'>Flag</Typography>
          <GenericFilterRadios
            value={flagRadio}
            onValueChange={setFlagRadio}
            labels={{
              unset: 'All',
              falsy: 'Not flagged',
              truthy: 'Flagged',
            }}
          />
        </Stack>
      </Stack>

      <Stack p={2} gap={1} direction='row' justifyContent='space-between'>
        <Button variant='text' onClick={onCancel}>
          {COMMON_STRING.CANCEL}
        </Button>
        <Button
          variant='contained'
          type='submit'
          onClick={onConfirm}
          sx={{ flex: 1 }}
        >
          {COMMON_STRING.APPLY}
        </Button>
      </Stack>
    </Stack>
  );
};
