import { HubConnectionState } from '@microsoft/signalr';
import { konsole } from '@/utils/konsole';
import {
  SCORING_WORKER_ACTION,
  SCORING_WORKER_HOST_ACTION,
  ScoringWorkerHostMsg,
} from '@/workers/scoring/types';
import { isChecklistCrucialAction } from '@/utils/checklist';
import {
  ScoringReducerAction,
  ScoringState,
  SCORING_REDUCER_ACTION,
} from './types';

export const scoringDefaultState = (
  initial?: Partial<ScoringState>
): ScoringState => ({
  isOnline: true,
  wsConnection: HubConnectionState.Disconnected,
  worker: null,
  isWorkerReady: false,
  isScorerConnected: false,
  fixtureSummary: null,
  fixtureActions: null,
  newFixtureActions: [],
  checklistActions: [],
  fixtureId: '',
  fixtureChecklist: null,
  ...initial,
});

const SCORING_WORKER_HOST_ACTIONS = Object.values(SCORING_WORKER_HOST_ACTION);

export const scoringReducer = (
  state: ScoringState,
  dispatchedAction: ScoringReducerAction
): ScoringState => {
  const { action, payload } = dispatchedAction;
  konsole.log('reducer>dispatch', action, payload);

  // Init action which passes a reference to worker
  if (action === SCORING_REDUCER_ACTION.WORKER_INIT) {
    return { ...state, worker: payload.worker };
  }
  if (action === SCORING_REDUCER_ACTION.NETWORK) {
    return { ...state, ...payload };
  }
  if (action === SCORING_WORKER_ACTION.WORKER_READY) {
    return { ...state, isWorkerReady: payload.isWorkerReady };
  }
  if (action === SCORING_REDUCER_ACTION.NEW_ACTION_SEEN) {
    const newFixtureActions = [...state.newFixtureActions];
    const seenActionIndex = newFixtureActions.findIndex(
      (action) => action.id === payload
    );
    if (seenActionIndex < 0) return state;
    newFixtureActions.splice(seenActionIndex, 1);
    return {
      ...state,
      newFixtureActions,
    };
  }

  if (action === SCORING_WORKER_HOST_ACTION.FIXTURE_ID) {
    state.fixtureId = payload.fixtureId;
  }
  // Dispatched an action to be handled by worker
  // Should only post message to worker and not perform any state change
  // Any exceptions should be handled above or in a custom reducer only actions
  if (
    state.worker &&
    SCORING_WORKER_HOST_ACTIONS.includes(action as SCORING_WORKER_HOST_ACTION)
  ) {
    state.worker.postMessage({
      action,
      payload,
    } as ScoringWorkerHostMsg);
    return state;
  }

  // Worker posted message which dispatched below action
  switch (action) {
    case SCORING_WORKER_ACTION.WS_CONNECTION:
      return { ...state, wsConnection: payload.wsConnection };
    case SCORING_WORKER_ACTION.SUMMARY_RECEIVED:
      return { ...state, ...payload };
    case SCORING_WORKER_ACTION.ACTION_ADDED:
      const { fixtureActions } = state;
      if (fixtureActions !== null) {
        fixtureActions.actions = [payload, ...fixtureActions.actions];
      }
      return {
        ...state,
        fixtureActions,
        newFixtureActions: [...state.newFixtureActions, payload],
        checklistActions: isChecklistCrucialAction(payload)
          ? [...state.checklistActions, payload]
          : state.checklistActions,
      };
    case SCORING_WORKER_ACTION.ACTIONS_RECEIVED:
      return { ...state, ...payload };
    case SCORING_WORKER_ACTION.CHECKLIST_RECEIVED:
      return { ...state, fixtureChecklist: payload.fixtureChecklistElements };
    case SCORING_WORKER_ACTION.CHECKLIST_ELEMENT_ADDED:
      return {
        ...state,
        fixtureChecklist:
          state.fixtureChecklist === null
            ? [payload]
            : [...state.fixtureChecklist, payload],
      };
    case SCORING_WORKER_ACTION.CHECKLIST_ELEMENT_REMOVED:
      return {
        ...state,
        fixtureChecklist:
          state.fixtureChecklist === null
            ? []
            : state.fixtureChecklist.filter(({ id }) => id !== payload),
      };
    case SCORING_WORKER_ACTION.ACTION_UPDATED:
      if (!state.fixtureActions) return state;
      const updatedActionIndex = state.fixtureActions.actions.findIndex(
        ({ id }) => id === payload.id
      );
      if (updatedActionIndex < 0) return state;
      const actionsWithUpdatedAction = [...state.fixtureActions.actions];
      actionsWithUpdatedAction[updatedActionIndex] = payload;
      return {
        ...state,
        fixtureActions: {
          fixtureId: state.fixtureId,
          actions: actionsWithUpdatedAction,
        },
      };

    case SCORING_WORKER_ACTION.CONNECTION_STATUS_RECEIVED:
      return {
        ...state,
        isScorerConnected: payload.fixtureConnectionStatus.isConnected,
      };
    case SCORING_WORKER_ACTION.REQUEST_CONFIRMATION:
      return state;

    default:
      konsole.warn('Wrong action type :(', action, payload);
      return state;
  }
};
