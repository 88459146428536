import {
  ChecklistElementName,
  CheckListElementType as ChecklistElementType,
} from '@/service/types/checklist';
import { FixtureAction, FixtureConfig, FixtureSummary } from '@/service/types';

export interface ChecklistWarning {
  name: string;
  message: string;
}

export interface ChecklistCheckboxState {
  type: ChecklistElementType;
  name: ChecklistElementName;
  isChecked: boolean;
  isReadyToCheck: boolean;
  isDisabled: boolean;
  warnings: ChecklistWarning[];
  requiresConfirmation: boolean;
}

export enum CHECKLIST_REDUCER_ACTION {
  CHECKBOXES = 'checkboxes',
  VALIDATE = 'validate',
}

export type ChecklistCheckboxesAction = {
  action: CHECKLIST_REDUCER_ACTION.CHECKBOXES;
  payload: ChecklistElementType[];
};
export type ChecklistValidateAction = {
  action: CHECKLIST_REDUCER_ACTION.VALIDATE;
  payload: {
    actions: FixtureAction[];
    config: FixtureConfig;
    summary: FixtureSummary;
  };
};

export type ChecklistReducerAction =
  | ChecklistCheckboxesAction
  | ChecklistValidateAction;

export type ChecklistContextState = ChecklistCheckboxState[];

export interface ChecklistContextValue {
  checkboxes: ChecklistContextState;
}
