import {
  DialogTitle,
  DialogTitleProps,
  IconButton,
  Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FC } from 'react';

export interface DialogTitleWithCloseProps extends DialogTitleProps {
  close: () => void;
}

export const CLOSE_DIALOG_BUTTON_LABEL = 'Close Dialog';
export const DialogTitleWithClose: FC<DialogTitleWithCloseProps> = (props) => {
  const { close, children, ...dialogTitleProps } = props;
  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between'>
      <DialogTitle {...dialogTitleProps} sx={{ p: 2 }}>
        {children}
      </DialogTitle>
      <IconButton
        aria-label={CLOSE_DIALOG_BUTTON_LABEL}
        onClick={close}
        size='small'
        sx={{
          margin: 1,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </Stack>
  );
};
