import React, { useCallback } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material';
import { FixtureTabsType } from '@/components/FixtureTabs/helpers/generateTabs';

export interface FixtureTabsProps {
  tabValue: string;
  tabHandler: (event: React.SyntheticEvent, value: any) => void;
  tabs: FixtureTabsType[];
}

const a11yProps = (name: string) => {
  return {
    id: `tab-${name}`,
    'aria-controls': `tabpanel-${name}`,
  };
};

const TabLabel = styled('span')(({ theme }) => ({
  paddingInline: theme.spacing(1),
}));

// TODO: uncomment it after change new action logic
// const ActionsTabLabel: FC<PropsWithChildren> = ({ children }) => {
//   const unseenActionsNumber = useUnseenActionsNumber();
//   return (
//     <Badge color='primary' badgeContent={unseenActionsNumber}>
//       <TabLabel>{children}</TabLabel>
//     </Badge>
//   );
// };

export const FixtureTabs = ({
  tabValue,
  tabHandler,
  tabs,
}: FixtureTabsProps) => {
  const getTabLabel = useCallback((tab: FixtureTabsType) => {
    // TODO: uncomment it after change new action logic
    // if (tab.name === MAIN_TAB_NAME.MATCH_DATA) {
    //   return <ActionsTabLabel>{tab.name}</ActionsTabLabel>;
    // }
    return <TabLabel>{tab.name}</TabLabel>;
  }, []);

  return (
    <Tabs
      sx={{
        borderBottom: 1,
        borderColor: 'divider',
        '.MuiTabs-scrollButtons.Mui-disabled': {
          opacity: '0.2',
        },
      }}
      value={tabValue}
      onChange={tabHandler}
      textColor='primary'
      indicatorColor='primary'
      allowScrollButtonsMobile
      variant='scrollable'
      scrollButtons='auto'
    >
      {tabs.map((fixtureTab) => (
        <Tab
          key={fixtureTab.name}
          label={getTabLabel(fixtureTab)}
          value={fixtureTab.name}
          {...a11yProps(fixtureTab.name)}
          sx={{
            overflow: 'visible',
            p: (theme) => theme.spacing(1, 1.5),
            textTransform: 'capitalize',
          }}
        />
      ))}
    </Tabs>
  );
};
