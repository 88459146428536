export const DOWN_DESCRIPTION: { [x: number]: string } = {
  0: '??',
  1: '1ST',
  2: '2ND',
  3: '3RD',
  4: '4TH',
};

export const GAME_STATE = {
  KICKOFF: 0,
  REGULAR_SNAP: 1,
  PAT: 2,
};
