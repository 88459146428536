import { UNKNOWN_PLAYER_NAME } from '@/constants';
import { FixtureAction, FixtureConfig, FixtureSummary } from '@/service/types';
import { isPlayerUnknown } from '@/service/utils/actionConditions';

export const getPlayerName = (
  playerId: FixtureAction['playerId'],
  fixtureConfig: FixtureConfig | undefined,
  fixtureSummary: FixtureSummary | null
) => {
  if (isPlayerUnknown(playerId)) {
    return UNKNOWN_PLAYER_NAME;
  }
  if (!playerId) {
    return '';
  }

  let player = getPlayer(playerId, fixtureConfig, fixtureSummary);

  if (player) {
    return `${player.fullName}`;
  }

  return '';
};

export const getPlayerShirtNumber = (
  playerId: FixtureAction['playerId'],
  fixtureConfig: FixtureConfig | undefined,
  fixtureSummary: FixtureSummary | null
) => {
  if (!playerId) {
    return '';
  }

  let player = getPlayer(playerId, fixtureConfig, fixtureSummary);

  if (player) {
    return `${player.shirtNumber}`;
  }

  return '';
};

const getPlayerFromMatchSummary = (
  playerId: FixtureAction['playerId'],
  fixtureSummary: FixtureSummary | null
) => {
  if (fixtureSummary) {
    let player = fixtureSummary.homeTeam.players.find((p) => p.id === playerId);

    if (!player) {
      player = fixtureSummary.awayTeam.players.find((p) => p.id === playerId);
    }

    if (player) {
      return player;
    }
  }
  return undefined;
};

export const getPlayer = (
  playerId: FixtureAction['playerId'],
  fixtureConfig: FixtureConfig | undefined,
  fixtureSummary: FixtureSummary | null
) => {
  let player = getPlayerFromMatchSummary(playerId, fixtureSummary);
  if (player) return player;

  if (fixtureConfig) {
    let player = fixtureConfig.homeSquad.players.find((p) => p.id === playerId);

    if (!player) {
      player = fixtureConfig.awaySquad.players.find((p) => p.id === playerId);
    }

    if (player) {
      return player;
    }
  }
  return undefined;
};

export const getPlayerNameAndNumber = (
  playerId: FixtureAction['playerId'],
  fixtureConfig: FixtureConfig | undefined,
  fixtureSummary: FixtureSummary | null
) => {
  const shirtNumber = getPlayerShirtNumber(
    playerId,
    fixtureConfig,
    fixtureSummary
  );
  const fullName = getPlayerName(playerId, fixtureConfig, fixtureSummary);
  if (!shirtNumber && !fullName) return '';
  if (!shirtNumber) return fullName;
  return `${shirtNumber}. ${fullName}`;
};
