import useSWR from 'swr';
import { useSnackbar } from 'notistack';
import { ReasonCodeDescription, ReasonOptions } from '@/service/types';
import {
  fetchFromMTService,
  SERVICE_ENDPOINT,
} from '../fetcher/monitoringToolService';
import { ApiError } from '../ApiError';
import { HTTP_STATUS_CODE } from '../constants';

export interface UseReasonOptionsParams {
  token: string | undefined;
}

export type CodeDescriptionType = {
  [p: ReasonCodeDescription['code']]: ReasonCodeDescription['description'];
};

interface ReasonCode {
  name: string;
  value: number;
}

export const useReasonOptions = ({ token }: UseReasonOptionsParams) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, error } = useSWR<ReasonOptions, ApiError>(
    token
      ? {
          token,
          endpoint: SERVICE_ENDPOINT.Fixture().reasonOptions.GET,
        }
      : null,
    fetchFromMTService,
    {
      onErrorRetry: (e, _, config, revalidate, { retryCount }) => {
        if (
          e.status !== HTTP_STATUS_CODE.NOT_FOUND &&
          e.status !== HTTP_STATUS_CODE.UNAUTHORIZED
        ) {
          return enqueueSnackbar(e.title, { variant: 'error' });
        }
        if (e.status === HTTP_STATUS_CODE.NOT_FOUND) return;
        if (e.status === HTTP_STATUS_CODE.UNAUTHORIZED) return;
        setTimeout(() => revalidate({ retryCount }), config.errorRetryInterval);
      },
    }
  );

  const codeDescriptions: CodeDescriptionType =
    data &&
    Object.assign(
      {},
      ...data?.reasonCodeDescriptions.map(
        (description): CodeDescriptionType => ({
          [description.code]: description.description as string,
        })
      )
    );

  const fixtureStatusReasonCodes =
    data &&
    Object.assign(
      {},
      ...data?.fixtureStatusReasonCodes.map((fixtureStatus) => {
        return {
          [fixtureStatus.status]: fixtureStatus.reasonCodes.map(
            (reasonCode: number): ReasonCode => ({
              value: reasonCode,
              name: codeDescriptions[reasonCode],
            })
          ),
        };
      })
    );

  const collectionStatusReasonCodes =
    data &&
    Object.assign(
      {},
      ...data?.collectionStatusReasonCodes.map((collectionStatus) => {
        return {
          [collectionStatus.status]: collectionStatus.reasonCodes.map(
            (reasonCode): ReasonCode => ({
              value: reasonCode,
              name: codeDescriptions[reasonCode],
            })
          ),
        };
      })
    );

  const coverageLevelReasonCodes = data?.coverageLevelReasonCodes.map(
    (coverageLevel): ReasonCode => ({
      value: coverageLevel,
      name: codeDescriptions[coverageLevel],
    })
  );

  return {
    fixtureStatusReasonCodes,
    collectionStatusReasonCodes,
    coverageLevelReasonCodes,
    codes: codeDescriptions,
    error,
    isLoading:
      !fixtureStatusReasonCodes ||
      !collectionStatusReasonCodes ||
      !coverageLevelReasonCodes ||
      !data,
  };
};
