import { useState } from 'react';
import { v4 as uuid } from 'uuid'; // UUID RFC version 4 (random)
import { useSnackbar } from 'notistack';
import {
  ActionOf,
  PayloadOf,
  ScoringAPIWorker,
  ScoringWorkerHostMsg,
  ScoringWorkerMsgEvent,
  SCORING_WORKER_ACTION,
} from '@/workers/scoring/types';
import { SocketRequestConfirmation } from '@/service/types';
import {
  InvokeDispatchWithResponseOptions,
  SocketRequestPayload,
  INVOKE_DISPATCH_WITH_RESPONSE_DEFAULT_OPTIONS,
} from './createUseDispatchWithResponse';

export type Request<T> = [string, SocketRequestPayload<T>];
export type RequestCollection<T> = Array<Request<T>>;

export type DispatchManyWithResponseState<T> = {
  pendingRequests: RequestCollection<T>;
};
export type DispatchManyWithResponseOutput<T> = {
  requests: RequestCollection<T>;
  responses: SocketRequestConfirmation[];
};

export type InvokeDispatchManyWithResponse<T> = (
  requestPayloads: SocketRequestPayload<T>[],
  options?: InvokeDispatchWithResponseOptions
) => Promise<DispatchManyWithResponseOutput<T>>;

export type UseDispatchManyWithResponse<T> =
  DispatchManyWithResponseState<T> & {
    isLoading: boolean;
    dispatch: InvokeDispatchManyWithResponse<T>;
  };
/**
 * This hook is to be used in 'parent components'.
 * It is recommended to use the singnular version of this hook `useDispatchWithResponse`
 * inside the particular component. However, if you need to send many messages and await
 * for their confirmations and/or you need to perform such action from parent component
 * for any reason (eg. ActionsTable event bubbling) then you can use this version of the hook.
 */
export const createUseDispatchManyWithResponse = (worker: ScoringAPIWorker) => {
  const useDispatchManyWithResponse = <T>(
    action: ActionOf<T>
  ): UseDispatchManyWithResponse<T> => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [state, setState] = useState<DispatchManyWithResponseState<T>>({
      pendingRequests: [],
    });
    const { enqueueSnackbar } = useSnackbar();

    const dispatch: InvokeDispatchManyWithResponse<T> = (
      requestPayloads,
      options
    ) => {
      return new Promise<DispatchManyWithResponseOutput<T>>(
        (resolve, reject) => {
          const requests = requestPayloads.reduce<RequestCollection<T>>(
            (acc, payload) => {
              acc.push([uuid(), payload]);
              return acc;
            },
            []
          );
          const successes: SocketRequestConfirmation[] = [];
          const errors: SocketRequestConfirmation[] = [];
          /**
           * When a component dispatches the action with requestConfirmation
           * it should use this hook state to display the loading state
           * while awaiting for the response message.
           * However, when response is coming quick enough then
           * components render loading state and then updated state immediatly after that.
           * This looks glitchy.
           * This timeout is slightly delaying setting state properties which determine
           * whether it is loading state or not.
           */
          const loadingStateTimeout = setTimeout(() => {
            setIsLoading(true);
          }, 100);
          setState({
            pendingRequests: [...state.pendingRequests, ...requests],
          });

          const confirmationCb = (e: ScoringWorkerMsgEvent) => {
            const { action: responseAction, payload } = e.data;
            if (responseAction !== SCORING_WORKER_ACTION.REQUEST_CONFIRMATION)
              return;
            const isInRequests = requests.some(
              (request) => request[0] === payload.requestId
            );
            if (!isInRequests) return;

            setState((prevState) => {
              const newState: typeof prevState = {
                pendingRequests: prevState.pendingRequests.filter(
                  ([requestId]) => requestId !== payload.requestId
                ),
              };
              return newState;
            });

            if (payload.isSuccess) {
              successes.push(payload);
            } else {
              errors.push(payload);
            }

            const areAllRequestsDone =
              errors.length + successes.length === requests.length;
            if (!areAllRequestsDone) {
              return;
            }

            clearTimeout(loadingStateTimeout);
            setIsLoading(false);
            worker.removeEventListener('message', confirmationCb);

            const { successMessage, errorMessage, showSnackbar } = {
              ...INVOKE_DISPATCH_WITH_RESPONSE_DEFAULT_OPTIONS,
              ...options,
            };
            const responses = [...errors, ...successes];
            const output: DispatchManyWithResponseOutput<T> = {
              requests,
              responses,
            };

            if (showSnackbar) {
              if (errors.length) {
                enqueueSnackbar(
                  errorMessage ||
                    `${errors.length} actions: ${action} - failed`,
                  {
                    variant: 'error',
                  }
                );
                return reject(output);
              }
              if (successMessage) {
                const snackbarMsg =
                  successes.length > 1
                    ? `${successMessage} x${successes.length}`
                    : successMessage;
                enqueueSnackbar(snackbarMsg, {
                  variant: 'success',
                });
              }
            }

            return resolve(output);
          };

          worker.addEventListener('message', confirmationCb);

          requests.forEach(([requestId, requestPayload]) => {
            worker.postMessage({
              action: action as ActionOf<T>,
              payload: { ...requestPayload, requestId } as PayloadOf<T>,
            } as ScoringWorkerHostMsg);
          });
        }
      );
    };

    return {
      ...state,
      isLoading,
      dispatch,
    };
  };

  return useDispatchManyWithResponse;
};
