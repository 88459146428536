import { isObject } from '@/utils';
import {
  ActionConditionSound,
  ActionConditionSoundUpdateParam,
  ActionSound,
  ActionSoundUpdateParam,
} from './types';

export function isActionSoundsTheSame(a: ActionSound[], b: ActionSound[]) {
  if (a.length !== b.length) return false;
  return a.every(([idA, soundA]) =>
    b.some(([idB, soundB]) => idA === idB && soundA === soundB)
  );
}
/**
 * Compares `a` with `b` and returns the difference.
 * `newItems` are the items that exist in `a` but not in `b`
 * `missingItems` are the items that exist in `b` bot not in `a`
 */
export function getActionSoundsDiff(a: ActionSound[], b: ActionSound[]) {
  const newItems: ActionSound[] = [];
  const missingItems: ActionSound[] = [];

  a.forEach(([idA, soundA]) => {
    if (!b.some(([idB, soundB]) => idA === idB && soundA === soundB)) {
      newItems.push([idA, soundA]);
    }
  });
  b.forEach(([idB, soundB]) => {
    if (!a.some(([idA, soundA]) => idB === idA && soundB === soundA)) {
      missingItems.push([idB, soundB]);
    }
  });

  return { newItems, missingItems };
}

export function byFirstArrayItem(a: ActionSound, b: ActionSound) {
  return a[0] - b[0];
}

export function getUpdatedSoundArray<
  Input extends ActionSoundUpdateParam | ActionConditionSoundUpdateParam,
  Output extends ActionSound | ActionConditionSound
>(value: Input, collection: Output[]): Output[] {
  const newCollection = [...collection];
  const [soundFor, soundId] = value;
  if (soundId === null) {
    return newCollection.filter((soundPair) => soundPair[0] !== soundFor);
  }
  const toEdit = collection.find((soundPair) => soundPair[0] === soundFor);
  if (toEdit !== undefined) {
    toEdit[1] = soundId;
  } else {
    newCollection.push([soundFor, soundId] as Output);
  }
  return newCollection.sort(byFirstArrayItem);
}

export function deepEquals(
  obj1: Record<any, unknown>,
  obj2: Record<any, unknown>
) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];
    const areObjects = isObject(val1) && isObject(val2);
    const notEqualValue = !areObjects && val1 !== val2;
    const notEqualObjects = areObjects && !deepEquals(val1, val2);
    if (notEqualValue || notEqualObjects) return false;
  }
  return true;
}
