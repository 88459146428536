import { MouseEvent, FC, useContext } from 'react';
import DarkMode from '@mui/icons-material/DarkMode';
import DarkModeOutlined from '@mui/icons-material/DarkModeOutlined';
import LightMode from '@mui/icons-material/LightMode';
import LightModeOutlined from '@mui/icons-material/LightModeOutlined';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { Stack, Typography } from '@mui/material';
import { PreferencesContext } from '@/contexts/preferences/context';
import { ThemeMode, THEME_MODE } from '@/contexts/theme/constants';
import { KeyboardButton } from '../common/KeyboardButton';

export const ThemeToggle: FC = () => {
  const {
    themeMode,
    actions: { setThemeMode },
  } = useContext(PreferencesContext);

  const onChange = (_: MouseEvent<HTMLElement>, value: ThemeMode) => {
    if (!value) return;
    setThemeMode(value);
  };

  return (
    <Stack flexGrow={1}>
      <Typography
        variant='caption'
        component={'p'}
        gutterBottom
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <span>MODE</span>
        <KeyboardButton theKey='t' />
      </Typography>
      <ToggleButtonGroup
        color='primary'
        size='small'
        value={themeMode}
        exclusive
        onChange={onChange}
        fullWidth
      >
        <ToggleButton
          value={THEME_MODE.LIGHT}
          aria-label={`theme-${THEME_MODE.LIGHT}`}
          sx={{ gap: 0.5 }}
        >
          {themeMode === THEME_MODE.LIGHT ? (
            <LightMode />
          ) : (
            <LightModeOutlined />
          )}
          Light
        </ToggleButton>
        <ToggleButton
          value={THEME_MODE.DARK}
          aria-label={`theme-${THEME_MODE.DARK}`}
          sx={{ gap: 0.5 }}
        >
          {themeMode === THEME_MODE.DARK ? <DarkMode /> : <DarkModeOutlined />}
          Dark
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
};
