import TextField, { TextFieldProps } from '@mui/material/TextField';
import { FormControl, FormHelperText } from '@mui/material';
import { StatusReason } from '@/service/types';

interface StatusesReasonFieldProps {
  placeholder: string;
  customReason: StatusReason['reason'];
  onChange: TextFieldProps['onChange'];
}

export const StatusesReasonField = ({
  placeholder,
  customReason,
  onChange,
}: StatusesReasonFieldProps) => {
  const CHARACTER_LIMIT = 250;

  return (
    <FormControl sx={{ flex: 1, minWidth: 250 }}>
      <TextField
        value={customReason}
        onChange={onChange}
        multiline
        placeholder={placeholder}
        rows={4}
        inputProps={{
          maxLength: CHARACTER_LIMIT,
        }}
      />
      <FormHelperText sx={{ fontWeight: 400, textAlign: 'end' }}>
        {customReason.length}/{CHARACTER_LIMIT}
      </FormHelperText>
    </FormControl>
  );
};
