import {
  Box,
  Divider,
  IconButton,
  Stack,
  SxProps,
  Theme,
  Tooltip,
  Typography,
  TypographyProps,
} from '@mui/material';
import { FC } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';
import { COMMON_STRING } from '@/constants/dictionary';

export interface StatsCompareItem {
  name: string;
  internalValue: string | number | null;
  externalValue: string | number | null;
}
export interface StatsCompareTableProps {
  stats: StatsCompareItem[];
  refreshData: VoidFunction;
  isUpdating: boolean;
}

const StatsTypography: FC<TypographyProps> = (props) => {
  return (
    <Typography
      fontWeight={500}
      component='span'
      p={0.5}
      variant='body2'
      fontSize='1em'
      {...props}
    />
  );
};
const getRowStyle = (hasMismatch: boolean): SxProps<Theme> | undefined =>
  hasMismatch
    ? {
        background: ({ palette }) => palette.error[palette.mode],
        color: ({ palette }) => palette.error.contrastText,
      }
    : undefined;

const StatsCompareRow: FC<StatsCompareItem> = ({
  name,
  internalValue,
  externalValue,
}) => {
  const hasMismatch =
    internalValue !== null &&
    externalValue !== null &&
    internalValue !== externalValue;

  const internalVal = internalValue === null ? 'N/A' : internalValue;
  const externalVal = externalValue === null ? 'N/A' : externalValue;
  const mismatchStyle = getRowStyle(hasMismatch);
  const sx: SxProps<Theme> = {
    ...mismatchStyle,
    borderTop: (theme) => `1px solid ${theme.palette.divider}`,
  };

  return (
    <>
      <StatsTypography sx={sx} aria-label={`${name}-internal`}>
        {internalVal}
      </StatsTypography>
      <StatsTypography sx={sx} fontWeight={700} textAlign='center'>
        {name}
      </StatsTypography>
      <StatsTypography
        sx={sx}
        textAlign='right'
        aria-label={`${name}-external`}
      >
        {externalVal}
      </StatsTypography>
    </>
  );
};
export const StatsCompareTable: FC<StatsCompareTableProps> = ({
  stats,
  refreshData,
  isUpdating,
}) => {
  return (
    <Box // This wrapper defines size constraints
      sx={{
        flex: '1 1 auto', // This sets the container size to match other flex items
        overflow: 'hidden', // Hiding the overflow to allow children to control the overflow
        display: 'flex', // Needed for child to be flex-item
        flexDirection: 'column',
        minWidth: 170,
        minHeight: 175,
      }}
    >
      <Box
        sx={{
          flex: '1 1 0', // This resets the flex basis, so ONLY the children can control its size
          overflow: 'auto', // Making this container scrollable whenever it's bigger than parent
          display: 'flex', // Needed for setting children flex-margin.
        }}
      >
        <Stack
          margin='auto' // This fixes the justify-content: 'center' bug with overflow
          px={1}
          fontSize='0.75rem'
        >
          <Stack
            direction='row'
            justifyContent='center'
            alignItems='center'
            divider={<Divider orientation='vertical' flexItem />}
            gap={0.5}
          >
            <StatsTypography flex={1} textAlign='left'>
              Internal
            </StatsTypography>
            <Tooltip title={COMMON_STRING.REFRESH} arrow placement='top'>
              <span>
                <IconButton
                  color='primary'
                  onClick={refreshData}
                  disableRipple
                  sx={{ p: 0 }}
                  disabled={isUpdating}
                >
                  {isUpdating ? (
                    <CircularProgress
                      size='1em'
                      color='inherit'
                      sx={{ p: '0.25em' }}
                    />
                  ) : (
                    <RefreshIcon fontSize='small' />
                  )}
                </IconButton>
              </span>
            </Tooltip>
            <StatsTypography flex={1} textAlign='right'>
              External
            </StatsTypography>
          </Stack>

          <Box display='grid' gridTemplateColumns='1fr auto 1fr'>
            {stats.map(({ name, internalValue, externalValue }) => {
              return (
                <StatsCompareRow
                  key={name}
                  name={name}
                  internalValue={internalValue}
                  externalValue={externalValue}
                />
              );
            })}
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
