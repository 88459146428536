import MoreVert from '@mui/icons-material/MoreVert';
import { IconButton, Stack } from '@mui/material';
import { FC, useContext, useEffect, useRef } from 'react';

import { StatusesChangeInfo } from '@/components/ActionsTable/StatusesChangeInfo/StatusesChangeInfo';
import { getActionName } from '@/components/FixtureTabs/helpers/getActionName';
import { getActionSubTypeName } from '@/components/FixtureTabs/helpers/getActionSubTypeName';
import { getPlayerNameAndNumber } from '@/components/FixtureTabs/helpers/getPlayerNameAndNumber';
import { getTeamName } from '@/components/FixtureTabs/helpers/getTeamName';
import { MATCH_ACTION_SEND_TYPE_NAME } from '@/constants';
import { FIXTURE_ACTION_TYPE } from '@/service/constants';
import { FixtureAction, FixtureConfig, FixtureSummary } from '@/service/types';
import { formatDate } from '@/utils/formatDate/formatDate';
import { getPeriodName } from '@/components/FixtureTabs/helpers/getPeriodName';
import {
  ActionsTableContext,
  ActionsTableContextType,
} from '@/components/ActionsTable/context/ActionsTableContext';
import { FILTER_PROPERTY } from './ActionFilters/constants';
import { ActionsTableCell } from './ActionsTableCell';
import { CommentCell } from './CommentCell';
import { FlagCell } from './FlagCell';
import { BUTTON_NAME, COLUMNS, TABLE_CLASS_NAMES } from './constants';
import { getRowBackgroundStyle, getTeamColor } from './utils';
import { ActionIcons } from './ActionIcons';

export type ActionRowProps = {
  action: FixtureAction;
  fixtureConfig?: FixtureConfig;
  fixtureSummary: FixtureSummary | null;
  isNewAction: boolean;
  isUpdating: boolean;
  canComment: boolean;
  observer: IntersectionObserver;
  sportId?: number;
};

export const ActionRow: FC<ActionRowProps> = ({
  action,
  fixtureConfig,
  fixtureSummary,
  isNewAction,
  isUpdating,
  canComment,
  observer,
  sportId,
}) => {
  const { actions } = useContext<ActionsTableContextType>(ActionsTableContext);

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const currentRef = ref.current;
    if (currentRef && isNewAction) {
      observer.observe(currentRef);
    }
    if (currentRef && !isNewAction) {
      observer.unobserve(currentRef);
    }
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [isNewAction, observer]);

  return (
    <Stack
      ref={ref}
      direction='row'
      data-action-id={action.id}
      className={`${TABLE_CLASS_NAMES.ROW}`}
      sx={{
        alignItems: 'stretch',
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        '&:hover': {
          backgroundColor: (theme) => theme.palette.action.hover,
        },
      }}
      style={{
        ...getRowBackgroundStyle(getTeamColor(action.teamId, fixtureSummary)),
      }}
    >
      <ActionsTableCell width={COLUMNS.SEQ.width} columnName={COLUMNS.SEQ.name}>
        {action.fixtureSeqNum}
      </ActionsTableCell>

      <ActionsTableCell
        width={COLUMNS.SEND_TYPE.width}
        label={MATCH_ACTION_SEND_TYPE_NAME[action.sendTypeId]}
        columnName={COLUMNS.SEND_TYPE.name}
        filterProperty={FILTER_PROPERTY.SEND_TYPE_ID}
        filterValue={action.sendTypeId}
        filterValueType='number'
        color={({ palette }) => palette.status[action.sendTypeId]}
        fontWeight={600}
      />

      <ActionsTableCell
        width={COLUMNS.CLOCK_TIME.width}
        columnName={COLUMNS.CLOCK_TIME.name}
        label={action.clockTimeString}
        sublabel={formatDate(action.timestamp)}
      />

      <ActionsTableCell
        width={COLUMNS.PERIOD.width}
        label={getPeriodName({
          period: action.period,
          fixtureSeqNum: action.fixtureSeqNum,
          actions,
          sportId,
        })}
        columnName={COLUMNS.PERIOD.name}
        filterProperty={FILTER_PROPERTY.PERIOD}
        filterValue={action.period}
        filterValueType='number'
      />
      <ActionsTableCell
        alignItems='flex-end'
        width={COLUMNS.ACTION_ICONS.width}
      >
        <ActionIcons action={action} />
      </ActionsTableCell>
      <ActionsTableCell
        width={COLUMNS.ACTION.width}
        label={getActionName(action.fixtureActionTypeId, fixtureConfig)}
        sublabel={getActionSubTypeName(
          action.fixtureActionSubTypeId,
          fixtureConfig
        )}
        columnName={COLUMNS.ACTION.name}
        filterValue={action.fixtureActionTypeId}
        filterProperty={FILTER_PROPERTY.FIXTURE_ACTION_TYPE_ID}
        filterValueType='number'
      >
        {action.fixtureActionTypeId === FIXTURE_ACTION_TYPE.STATUS_UPDATE && (
          <StatusesChangeInfo
            fixtureStatusId={action.metadata?.fixtureStatus}
            fixtureStatusReasonCode={action.metadata?.fixtureStatusReasonCode}
            collectionStatusId={action.metadata?.collectionStatus}
            collectionStatusReasonCode={
              action.metadata?.collectionStatusReasonCode
            }
            coverageLevelId={action.metadata?.coverageLevel}
            coverageLevelReasonCode={action.metadata?.coverageLevelReasonCode}
          />
        )}
      </ActionsTableCell>

      <ActionsTableCell
        width={COLUMNS.TEAM.width}
        label={getTeamName(action.teamId, fixtureConfig, fixtureSummary)}
        columnName={COLUMNS.TEAM.name}
        filterProperty={FILTER_PROPERTY.TEAM_ID}
        filterValue={action.teamId}
      />
      <ActionsTableCell
        width={COLUMNS.PLAYER.width}
        label={getPlayerNameAndNumber(
          action.playerId,
          fixtureConfig,
          fixtureSummary
        ).toLowerCase()}
        columnName={COLUMNS.PLAYER.name}
        filterProperty={FILTER_PROPERTY.PLAYER_ID}
        filterValue={action.playerId}
        sx={{ textTransform: 'capitalize' }}
      />

      <ActionsTableCell width={COLUMNS.SCORE.width}>
        {action.score &&
          `${action.score.homeTeamScore}:${action.score.awayTeamScore}`}
      </ActionsTableCell>

      <ActionsTableCell
        display='grid'
        gridTemplateColumns='repeat(3, 1fr)'
        alignItems='center'
        justifyItems='center'
        justifyContent='space-between'
        sx={{ px: 0.25, width: COLUMNS.MORE.width }}
      >
        <FlagCell action={action} isUpdating={isUpdating} />
        <CommentCell action={action} canComment={canComment} />
        <IconButton name={BUTTON_NAME.MORE} size='small' aria-label='more'>
          <MoreVert fontSize='small' />
        </IconButton>
      </ActionsTableCell>
    </Stack>
  );
};
