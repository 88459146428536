import Sports from '@mui/icons-material/Sports';
import GraphicEq from '@mui/icons-material/GraphicEq';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FilterList from '@mui/icons-material/FilterList';
import LibraryMusic from '@mui/icons-material/LibraryMusic';
import { NotImplemented } from '@/components/common/NotImplemented';
import { Notifications } from '@/components/Preferences/Notifications/Notifications';
import { PreferencesTabSectionItems } from './types';
import { SoundPreferences } from './SoundPreferences/SoundPreferences';

export const PREFERENCES_TAB_SECTION_NAME = {
  SOUND: 'Sound',
  FILTERS: 'Filters',
  PROFILE: 'Profile',
};
export const PREFERENCES_TAB_NAME = {
  SOUNDS_ACTIONS: 'Sounds actions',
  SOUNDS_TEMPLATES: 'Sounds templates',
  SOUND_PREFERENCES: 'Sound preferences',
  ACCOUNT: 'Account',
  FILTER_TEMPLATES: 'Filter templates',
} as const;

export const PREFERENCES_TABS: PreferencesTabSectionItems = [
  {
    name: PREFERENCES_TAB_SECTION_NAME.SOUND,
    tabs: [
      {
        ariaName: 'preferences-notifications',
        name: PREFERENCES_TAB_NAME.SOUNDS_ACTIONS,
        component: <Notifications />,
        icon: <Sports fontSize='small' />,
      },
      {
        ariaName: 'preferences-notification-templates',
        name: PREFERENCES_TAB_NAME.SOUNDS_TEMPLATES,
        component: <NotImplemented />,
        icon: <LibraryMusic fontSize='small' />,
      },
      {
        ariaName: 'preferences-sounds',
        name: PREFERENCES_TAB_NAME.SOUND_PREFERENCES,
        component: <SoundPreferences />,
        icon: <GraphicEq fontSize='small' />,
      },
    ],
  },
  {
    name: PREFERENCES_TAB_SECTION_NAME.FILTERS,
    tabs: [
      {
        ariaName: 'preferences-filter-templates',
        name: PREFERENCES_TAB_NAME.FILTER_TEMPLATES,
        component: <NotImplemented />,
        icon: <FilterList fontSize='small' />,
      },
    ],
  },
  {
    name: PREFERENCES_TAB_SECTION_NAME.PROFILE,
    tabs: [
      {
        ariaName: 'preferences-account',
        name: PREFERENCES_TAB_NAME.ACCOUNT,
        component: <NotImplemented />,
        icon: <AccountCircleIcon fontSize='small' />,
      },
    ],
  },
];
