import { Stack } from '@mui/material';
import { LineupsProps } from '@/components/Lineups/types';
import {
  LineupFirstName,
  LineupIndicator,
  LineupLastName,
  LineupShirtNumber,
  TeamNameTypography,
} from '@/components/Lineups/LineupsStyledComponents';

export const LineupsPlayers = ({
  players,
  teamName,
  awayTeam = false,
}: LineupsProps) => {
  return (
    <Stack sx={{ flex: 1, padding: (theme) => theme.spacing(2, 0), gap: 1 }}>
      {teamName && (
        <TeamNameTypography textAlign={awayTeam ? 'left' : 'right'}>
          {teamName}
        </TeamNameTypography>
      )}
      {players?.map((player) => (
        <Stack
          key={player.id}
          textAlign={awayTeam ? 'left' : 'right'}
          direction='row'
          justifyContent={awayTeam ? 'flex-start' : 'flex-end'}
          alignItems='center'
          gap={1.5}
          lineHeight={1.1}
        >
          {awayTeam ? (
            <>
              <LineupIndicator isInCurrentLineup={player.isInCurrentLineup} />
              <LineupShirtNumber>{player.shirtNumber}</LineupShirtNumber>
              <Stack flexWrap='wrap' flex={1} direction='row'>
                <LineupFirstName>{player.firstName}</LineupFirstName>
                <LineupLastName>&nbsp;{player.lastName}</LineupLastName>
              </Stack>
            </>
          ) : (
            <>
              <Stack
                flexWrap='wrap'
                flex={1}
                direction='row'
                justifyContent='flex-end'
              >
                <LineupFirstName>{player.firstName}</LineupFirstName>
                <LineupLastName>&nbsp;{player.lastName}</LineupLastName>
              </Stack>
              <LineupShirtNumber>{player.shirtNumber}</LineupShirtNumber>
              <LineupIndicator isInCurrentLineup={player.isInCurrentLineup} />
            </>
          )}
        </Stack>
      ))}
    </Stack>
  );
};
