export function getDifferenceByProp<T>(
  arrayA: T[],
  arrayB: T[],
  property: keyof T
) {
  return arrayA.reduce<T[]>((acc, valA) => {
    if (!arrayB.some((valB) => valA[property] === valB[property])) {
      acc.push(valA);
    }
    return acc;
  }, []);
}
