import Comment from '@mui/icons-material/Comment';
import ContentCopy from '@mui/icons-material/ContentCopy';
import DataObject from '@mui/icons-material/DataObject';
import EditIcon from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import Flag from '@mui/icons-material/Flag';
import LocationOn from '@mui/icons-material/LocationOn';
import PictureInPicture from '@mui/icons-material/PictureInPicture';
import PlaylistAdd from '@mui/icons-material/PlaylistAdd';
import Search from '@mui/icons-material/Search';
import {
  Chip,
  Divider,
  Drawer,
  Popover,
  Stack,
  TableContainer,
  useTheme,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import {
  MouseEvent,
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ViewportListRef } from 'react-viewport-list';
import { useEventListener } from 'usehooks-ts';
import { CourtDialog } from '@/components/ActionsDialog/CourtDialog';
import { JsonDialog } from '@/components/ActionsDialog/JsonDialog';
import { LocationDialog } from '@/components/ActionsDialog/LocationDialog';
import {
  ActionDialogProps,
  ActionDialogType,
} from '@/components/ActionsDialog/types';
import { ActionsTableCellDataset } from '@/components/ActionsTable/ActionsTableCell';
import { CommentDialog } from '@/components/Comment/CommentDialog';
import { LoadingOverlay } from '@/components/common/LoadingOverlay';
import { DICTIONARY } from '@/constants/dictionary';
import { UIStateContext } from '@/contexts/UIState/context';
import { PANEL } from '@/contexts/UIState/types';
import { useAuthToken } from '@/contexts/auth/useAuthToken';
import { ScoringContext } from '@/contexts/scoring/context';
import { MATCH_ACTION_SEND_TYPE, USER_ROLE_ID } from '@/service/constants';
import { useFixtureConfig } from '@/service/hooks/useFixtureConfig';
import { useUserRoles } from '@/service/hooks/useUserRoles';
import { FixtureAction } from '@/service/types';
import { copyToClipboard } from '@/utils';
import { throttle } from '@/utils/throttle/throttle';
import { getSport } from '@/service/utils/getSport';
import { EditActionDialog } from '../ActionsDialog/EditActionDialog';
import { ActionsTableActionBar } from './ActionBar/ActionBar';
import { ActionFiltersForm } from './ActionFilters/ActionFiltersForm';
import {
  FILTER_DISPLAY_NAME,
  FILTER_PROPERTY,
} from './ActionFilters/constants';
import { ActionFilter } from './ActionFilters/useActionsFilters';
import { removeGenericFilter } from './ActionFilters/utils';
import { ActionsTableBody, SCROLL_TO_TOP_OFFSET } from './ActionsTableBody';
import { ActionsTableHeader } from './ActionsTableHeader';
import { ContextMenu, ContextMenuItem } from './ContextMenu';
import { BUTTON_NAME } from './constants';
import {
  ActionsTableContext,
  ActionsTableContextType,
} from './context/ActionsTableContext';
import {
  findCellInPath,
  findRowInPath,
  getCellDataset,
  getRowActionId,
  parseCellDataValue,
  prepareFlagUpdatePayloads,
} from './utils';

interface ActionDialogState {
  dialog?: ActionDialogType;
  props?: ActionDialogProps;
}

export const getCellExtraMenuItemLabel = (
  { label, filterValue, columnName }: ActionsTableCellDataset,
  text?: string
) => {
  const itemLabelValue = label || filterValue || 'none';

  return (
    <>
      {text || `${DICTIONARY.COMMON.FILTER_BY}:`}
      <Chip
        sx={{ cursor: 'inherit' }}
        size='small'
        label={`${columnName}: ${itemLabelValue}`}
      />
    </>
  );
};

export interface ActionsTableProps {
  disableFilters?: boolean;
}

export const ActionsTable = ({ disableFilters = false }: ActionsTableProps) => {
  const {
    state: { fixtureId, fixtureSummary },
  } = useContext(ScoringContext);

  const { openedPanel, setOpenedPanel, setHotkeysDisabled } =
    useContext(UIStateContext);
  const token = useAuthToken();
  const { data: fixtureConfig } = useFixtureConfig({ token, fixtureId });
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const fabSpacing = { top: theme.spacing(5), bottom: theme.spacing(8) };
  const sportId = getSport(fixtureConfig)?.id;
  /*TODO: move this logic to UserContext after permissions re-review*/
  const { data: roles } = useUserRoles({ token });
  const { actions, displayActions, filters, setFilters, dispatchFlagUpdate } =
    useContext<ActionsTableContextType>(ActionsTableContext);

  const { dispatch: updateActionsFlag, isLoading: isActionsFlagUpdating } =
    dispatchFlagUpdate;

  /**
   * Internal state to keep actions collection
   * and avoid mutating the `actions` coming as a prop
   * Use `setDisplayActions` when applying filtering.
   * Use `displayActions` instead `actions` inside this component.
   */
  const [actionDialog, setActionDialog] = useState<ActionDialogState>({
    dialog: undefined,
    props: undefined,
  });

  const COMMENT_USER_ROLES = [
    USER_ROLE_ID.SUPER_ADMIN,
    USER_ROLE_ID.SUPERVISOR,
  ];
  const canComment = roles
    ? roles.some(({ id }) => COMMENT_USER_ROLES.includes(id))
    : false;

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(!(fixtureConfig && fixtureSummary && actions !== undefined));
  }, [fixtureSummary, fixtureConfig, actions]);

  const [contextMenuPosition, setContextMenuPosition] = useState({
    top: 0,
    left: 0,
  });
  const [contextMenuItems, setContextMenuItems] = useState<ContextMenuItem[]>(
    []
  );
  const [isScrollTopVisible, setIsScrollTopVisible] = useState<boolean>(false);

  const viewportRef = useRef<HTMLDivElement | null>(null);
  const listRef = useRef<ViewportListRef | null>(null);

  const onListScroll = (event: Event) => {
    if (!event || !event.target || event.target !== viewportRef.current) return;
    const { scrollTop } = event.target as HTMLElement;
    if (scrollTop >= SCROLL_TO_TOP_OFFSET && !isScrollTopVisible) {
      return setIsScrollTopVisible(true);
    }
    if (scrollTop < SCROLL_TO_TOP_OFFSET && isScrollTopVisible) {
      return setIsScrollTopVisible(false);
    }
    return;
  };

  useEventListener('scroll', throttle(onListScroll, 500), viewportRef);

  const closeContextMenu = () => {
    setHotkeysDisabled(false);
    setContextMenuItems([]);
  };

  const openContextMenu = ({
    event,
    clickedRowActionId,
    extraMenuItems = [],
  }: {
    event: MouseEvent<HTMLElement>;
    clickedRowActionId: FixtureAction['id'];
    extraMenuItems?: ContextMenuItem[];
  }) => {
    const action = findDisplayActionById(clickedRowActionId);
    if (!action) return;
    setHotkeysDisabled(true);
    const { actionId, flag, fixtureActionTypeId, sendTypeId, fixtureSeqNum } =
      action;

    const isStaticAction = fixtureConfig?.staticFixtureActions.some(
      ({ id }) => fixtureActionTypeId === id
    );

    const isPlayerRequired = fixtureConfig?.fixtureOptions.actionButtons.some(
      ({ playerRequired, actionType }) =>
        actionType.id === fixtureActionTypeId && playerRequired
    );

    const isChangeableActionTypeId =
      fixtureConfig?.fixtureOptions.actionButtons.some(
        ({ changeableActionTypeIds, actionType }) =>
          actionType.id === fixtureActionTypeId &&
          changeableActionTypeIds?.length
      );

    const canEditAction = () => {
      const firstActionFixtureSeqNum = actions?.find(
        (action) => action.actionId === actionId
      )?.fixtureSeqNum as number;
      const isEditableSendType =
        sendTypeId === MATCH_ACTION_SEND_TYPE.CONFIRMED ||
        sendTypeId === MATCH_ACTION_SEND_TYPE.UPDATED;

      return (
        !isStaticAction &&
        (isPlayerRequired || isChangeableActionTypeId) &&
        isEditableSendType &&
        fixtureSeqNum >= firstActionFixtureSeqNum
      );
    };

    const newContextMenuItems = [
      ...extraMenuItems,
      ...(!disableFilters
        ? [
            {
              Icon: Search,
              label: `${DICTIONARY.COMMON.FILTER_BY} Action ID`,
              onClick: () => {
                setFilters([
                  {
                    displayName: FILTER_DISPLAY_NAME.ACTION_ID,
                    displayValue: actionId,
                    property: FILTER_PROPERTY.ACTION_ID,
                    value: actionId,
                  },
                ]);
                closeContextMenu();
              },
            },
          ]
        : []),
      {
        Icon: ContentCopy,
        label: `${DICTIONARY.COMMON.COPY} Action ID`,
        onClick: () => {
          copyToClipboard(actionId).then(() => {
            enqueueSnackbar(DICTIONARY.SUCCESS.COPIED_TO_CLIPBOARD, {
              variant: 'success',
            });
            closeContextMenu();
          });
        },
      },
      {
        Icon: DataObject,
        label: `${DICTIONARY.COMMON.SHOW} JSON`,
        onClick: () => {
          openActionDialog(JsonDialog, action);
          closeContextMenu();
        },
      },
    ];
    if (flag !== null) {
      newContextMenuItems.push({
        Icon: Flag,
        label: DICTIONARY.COMMON.FLAG[flag.state],
        key: 'flag',
        isLoading: isActionsFlagUpdating,
        onClick: () => {
          if (!actions) return;
          const flagUpdatePayloads = prepareFlagUpdatePayloads(action, actions);
          if (flagUpdatePayloads === null) return;
          updateActionsFlag(flagUpdatePayloads, {
            successMessage: DICTIONARY.SUCCESS.FLAG_UPDATED,
          }).finally(closeContextMenu);
        },
      });
    }
    if (canEditAction()) {
      newContextMenuItems.push({
        Icon: EditIcon,
        label: DICTIONARY.COMMON.EDIT_ACTION,
        onClick: () => {
          openActionDialog(
            EditActionDialog,
            action,
            listRef,
            isScrollTopVisible
          );
          closeContextMenu();
        },
      });
    }
    if (canComment) {
      newContextMenuItems.push({
        Icon: Comment,
        label: DICTIONARY.COMMON.COMMENT,
        onClick: () => {
          openActionDialog(CommentDialog, action);
          closeContextMenu();
        },
      });
    }

    if (action.metadata) {
      action.metadata.longitude &&
        action.metadata.latitude &&
        newContextMenuItems.push({
          Icon: LocationOn,
          label: DICTIONARY.COMMON.DEVICE_LOCATION,
          onClick: () => {
            openActionDialog(LocationDialog, action);
            closeContextMenu();
          },
        });

      action.metadata.courtPosition &&
        newContextMenuItems.push({
          Icon: PictureInPicture,
          label: DICTIONARY.COMMON.COURT_POSITION,
          onClick: () => {
            openActionDialog(CourtDialog, action);
            closeContextMenu();
          },
        });
    }

    setContextMenuPosition({ top: event.pageY + 5, left: event.pageX });
    setContextMenuItems(newContextMenuItems);
  };

  const closeActionDialog = () => {
    setHotkeysDisabled(false);
    setActionDialog({
      dialog: undefined,
      props: undefined,
    });
  };

  const openActionDialog = (
    dialog: ActionDialogType,
    action: FixtureAction,
    listRef?: MutableRefObject<ViewportListRef | null>,
    isScrollTopVisible?: boolean
  ) => {
    setHotkeysDisabled(true);
    setActionDialog({
      props: {
        onClose: closeActionDialog,
        action,
        listRef,
        isScrollTopVisible,
      },
      dialog,
    });
  };

  const findDisplayActionById = useCallback(
    (actionId: FixtureAction['id']) =>
      displayActions.find(({ id }) => id === actionId),
    [displayActions]
  );

  /**
   * Since dialog props are stored in state
   * we need to update it programatically to make
   * dialogs re-render on changes.
   */
  useEffect(() => {
    if (!actionDialog.dialog || !actionDialog.props) return;
    const oldAction = actionDialog.props.action;
    const newAction = findDisplayActionById(oldAction.id) || oldAction;
    if (oldAction === newAction) return;

    setActionDialog({
      ...actionDialog,
      props: {
        ...actionDialog.props,
        action: newAction,
      },
    });
    // We only need this to run on actions changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayActions]);

  const onTableClick = (event: MouseEvent<HTMLTableSectionElement>): void => {
    const path = event.nativeEvent.composedPath() as HTMLElement[];

    const button = path.find(({ tagName }) => tagName === 'BUTTON') as
      | HTMLButtonElement
      | undefined;
    if (!button) return;

    const clickedRowElement = findRowInPath(path);
    if (!clickedRowElement) return;

    const clickedRowActionId = getRowActionId(clickedRowElement);
    if (!clickedRowActionId) return;

    if (button.name === BUTTON_NAME.MORE) {
      openContextMenu({ event, clickedRowActionId });
      return;
    }
    if (button.name === BUTTON_NAME.COMMENT) {
      const action = findDisplayActionById(clickedRowActionId);
      if (!action) return;
      openActionDialog(CommentDialog, action);
    }
    if (button.name === BUTTON_NAME.FLAG) {
      const action = findDisplayActionById(clickedRowActionId);
      if (!actions || !action || action.flag === null) return;
      const flagUpdatePayloads = prepareFlagUpdatePayloads(action, actions);
      if (flagUpdatePayloads === null) return;
      updateActionsFlag(flagUpdatePayloads, {
        successMessage: DICTIONARY.SUCCESS.FLAG_UPDATED,
      });
    }
  };

  const onTableRightClick = (event: MouseEvent<HTMLDivElement>): void => {
    event.preventDefault();
    const path = event.nativeEvent.composedPath() as HTMLElement[];
    const clickedRowActionId = getRowActionId(findRowInPath(path));
    if (!clickedRowActionId) return;
    const cellData = getCellDataset(findCellInPath(path));
    const extraMenuItems: ContextMenuItem[] = [];
    if (!disableFilters && cellData && cellData.filterProperty) {
      const property = cellData.filterProperty;
      const filterValue = parseCellDataValue(
        cellData.filterValue,
        cellData.filterValueType
      );
      const newFilter = {
        displayName: cellData.columnName || '',
        displayValue: cellData.label || filterValue,
        property,
        value: filterValue,
        exclude: false,
      } as ActionFilter;
      const thisPropertyFilters = filters.filter(
        (filter) => filter.property === newFilter.property
      );
      if (
        filters.length > 0 &&
        !thisPropertyFilters.some(({ value }) => value === filterValue)
      ) {
        const addAlsoItem = {
          Icon: PlaylistAdd,
          label: getCellExtraMenuItemLabel(
            cellData,
            `${DICTIONARY.COMMON.ADD_FILTER}:`
          ),
          key: 'add-filter',
          onClick: () => {
            const newFilters = removeGenericFilter(
              filters,
              FILTER_PROPERTY.PLAYER_ID
            );
            setFilters([...newFilters, { ...newFilter, exclude: false }]);
            closeContextMenu();
          },
        };
        const hideAlsoItem = {
          Icon: PlaylistAdd,
          label: getCellExtraMenuItemLabel(cellData, 'Hide also: '),
          key: 'add-exclude-filter',
          onClick: () => {
            const newFilters = removeGenericFilter(
              filters,
              FILTER_PROPERTY.PLAYER_ID
            );
            setFilters([...newFilters, { ...newFilter, exclude: true }]);
            closeContextMenu();
          },
        };

        if (thisPropertyFilters.length) {
          const isExcludeMode =
            (thisPropertyFilters[0] && thisPropertyFilters[0].exclude) ||
            undefined;

          extraMenuItems.push(isExcludeMode ? hideAlsoItem : addAlsoItem);
        } else {
          extraMenuItems.push(addAlsoItem);
          extraMenuItems.push(hideAlsoItem);
        }
      }

      extraMenuItems.push({
        Icon: FilterList,
        label: getCellExtraMenuItemLabel(cellData),
        key: 'filter-exclusive',
        onClick: () => {
          setFilters([newFilter]);
          closeContextMenu();
        },
      });
      extraMenuItems.push({
        Icon: FilterList,
        label: getCellExtraMenuItemLabel(cellData, 'Hide: '),
        key: 'hide-exclusive',
        onClick: () => {
          setFilters([{ ...newFilter, exclude: true }]);
          closeContextMenu();
        },
      });
    }
    openContextMenu({ event, clickedRowActionId, extraMenuItems });
  };

  return (
    <Stack sx={{ height: '100%' }}>
      <ActionsTableActionBar
        disableFilters={disableFilters}
        fixtureSummary={fixtureSummary}
        fixtureConfig={fixtureConfig}
      />
      <Divider flexItem variant='fullWidth' />
      <TableContainer
        sx={{
          flex: 1,
          paddingBottom: fabSpacing.bottom,
          zIndex: 1,
        }}
        ref={viewportRef}
      >
        <Stack
          sx={{
            minWidth: 900,
          }}
        >
          <ActionsTableHeader />
          <ActionsTableBody
            listRef={listRef}
            onTableRightClick={onTableRightClick}
            isScrollTopVisible={isScrollTopVisible}
            onTableClick={onTableClick}
            fixtureConfig={fixtureConfig}
            canComment={canComment}
            viewportRef={viewportRef}
            fabSpacing={fabSpacing}
            sportId={sportId}
          />
        </Stack>
      </TableContainer>
      <Popover
        open={!!contextMenuItems.length}
        onClose={closeContextMenu}
        anchorReference='anchorPosition'
        anchorPosition={contextMenuPosition}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        transitionDuration={0}
      >
        <ContextMenu items={contextMenuItems}></ContextMenu>
      </Popover>
      <LoadingOverlay isLoading={isLoading} />
      {!disableFilters && (
        <Drawer
          anchor='right'
          open={openedPanel === PANEL.FILTERS}
          onClose={() => setOpenedPanel(null)}
          keepMounted
        >
          <ActionFiltersForm
            fixtureConfig={fixtureConfig}
            filters={filters}
            onApply={(newFilters) => {
              setFilters(newFilters);
              setOpenedPanel(null);
            }}
            onCancel={() => setOpenedPanel(null)}
          />
        </Drawer>
      )}
      {actionDialog.dialog && actionDialog.props && (
        <actionDialog.dialog {...actionDialog.props} />
      )}
    </Stack>
  );
};
