import { FC } from 'react';
import { SnackbarKey, useSnackbar } from 'notistack';
import { COMMON_STRING } from '@/constants/dictionary';
import { ActionDialogProps } from '@/components/ActionsDialog/types';

import { EditAction } from '../EditAction/EditAction';
import { SnackbarGoToTopAction } from '../SnackbarActions/SnackbarGoToTopAction';
import { SnackbarCloseAction } from '../SnackbarActions/SnackbarCloseAction';
import { ActionsDialog } from './ActionsDialog';

export const EditActionDialog: FC<ActionDialogProps> = ({
  action,
  onClose,
  listRef,
  isScrollTopVisible,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const editActionDialogSnackbarAction = (snackbarId: SnackbarKey) =>
    listRef && isScrollTopVisible ? (
      <>
        <SnackbarGoToTopAction snackbarId={snackbarId} listRef={listRef} />
        <SnackbarCloseAction snackbarId={snackbarId} />
      </>
    ) : (
      <SnackbarCloseAction snackbarId={snackbarId} />
    );

  const onSuccess = () => {
    enqueueSnackbar('Action successfully edited', {
      variant: 'success',
      action: editActionDialogSnackbarAction,
    });
    onClose();
  };
  const onError = (error: any) => {
    /* TODO: remove any type */
    enqueueSnackbar(error ?? 'Failed to update the action', {
      variant: 'error',
    });
    onClose();
  };

  return (
    <ActionsDialog
      maxWidth='sm'
      fullWidth
      open={true}
      onClose={onClose}
      title={COMMON_STRING.EDIT_ACTION}
    >
      <EditAction
        action={action}
        onCancel={onClose}
        onError={onError}
        onSuccess={onSuccess}
      />
    </ActionsDialog>
  );
};
