import { FC } from 'react';
import { ActionsDialog } from '@/components/ActionsDialog/ActionsDialog';
import { COMMON_STRING } from '@/constants/dictionary';
import { ActionDialogProps } from '@/components/ActionsDialog/types';
import { CourtCanvas } from '@/components/CourtCanvas/CourtCanvas';

export const CourtDialog: FC<ActionDialogProps> = ({ action, onClose }) => {
  return (
    <ActionsDialog
      open={true}
      onClose={onClose}
      title={COMMON_STRING.COURT_POSITION}
    >
      <CourtCanvas
        courtX={action?.metadata.courtPosition.x}
        courtY={action?.metadata.courtPosition.y}
      />
    </ActionsDialog>
  );
};
