import { IconButton } from '@mui/material';
import { FC } from 'react';
import Comment from '@mui/icons-material/Comment';
import { FixtureAction } from '@/service/types';
import { BUTTON_NAME } from './constants';

export interface CommentCellProps {
  action: FixtureAction;
  canComment: boolean;
}

export const CommentCell: FC<CommentCellProps> = ({ action, canComment }) => {
  if (
    !action.fixtureActionComments ||
    action.fixtureActionComments.length === 0 ||
    !action.fixtureActionComments[0].comment ||
    !canComment
  ) {
    return <span />;
  }
  return (
    <IconButton name={BUTTON_NAME.COMMENT} size='small' aria-label='comments'>
      <Comment fontSize='small' />
    </IconButton>
  );
};
