import { Divider } from '@mui/material';
import { AdvancedStatsTable } from '@/components/AdvancedStatsTable/AdvancedStatsTable';
import { AdvancedStatsButtons } from '@/components/StatsButtons/AdvancedStatsButtons';

export const FixtureAdvancedStats = () => {
  return (
    <>
      <AdvancedStatsButtons />
      <Divider />
      <AdvancedStatsTable />
    </>
  );
};
