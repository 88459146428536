import {
  ScoresheetMainPlayerTableCell,
  ScoresheetPlayerTableCell,
  ScoresheetTableBodyRow,
} from '@/components/ScoresheetTables/common/ScoresheetTableStyledComponents';
import { getPlayer } from '@/components/FixtureTabs/helpers/getPlayerNameAndNumber';
import { FixtureConfig, FixtureSummary } from '@/service/types';
import { COMMON_STRING } from '@/constants/dictionary';

interface DidNotParticipatedPlayerRowProps {
  playerId: string;
  fixtureSummary: FixtureSummary | null;
  fixtureConfig?: FixtureConfig;
}

export const DidNotParticipatedPlayerRow = ({
  playerId,
  fixtureSummary,
  fixtureConfig,
}: DidNotParticipatedPlayerRowProps) => {
  const player = getPlayer(playerId, fixtureConfig, fixtureSummary);

  return (
    <ScoresheetTableBodyRow>
      <ScoresheetMainPlayerTableCell>
        {player && player.isStartingSquad && '*'}
        {player?.shirtNumber}
      </ScoresheetMainPlayerTableCell>
      <ScoresheetMainPlayerTableCell>
        {player?.fullName}
      </ScoresheetMainPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {COMMON_STRING.DID_NOT_PARTICIPATED}
      </ScoresheetPlayerTableCell>
      {Array.from({ length: 20 }, (_, i) => i + 1).map((index) => (
        <ScoresheetPlayerTableCell key={`${playerId}-${index}`} />
      ))}
    </ScoresheetTableBodyRow>
  );
};
