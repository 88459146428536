import { useContext } from 'react';
import { Stack } from '@mui/material';
import { ScoringContext } from '@/contexts/scoring/context';
import { useAuthToken } from '@/contexts/auth/useAuthToken';
import { useFixturePostMatchCheck } from '@/service/hooks/useFixturePostMatchCheck';
import { ComparisonTable } from '@/components/ComparisonTable/ComparisonTable';
import { ComparisonTableActions } from '@/components/ComparisonTable/ComparisonTableActions';
import { useScoringRate } from '@/service/hooks/useScoringRate';
import { ComparisonRating } from '@/components/ComparisonRating/ComparisonRating';
import { LoadingOverlay } from '@/components/common/LoadingOverlay';

export const FixtureComparison = () => {
  const {
    state: { fixtureId, fixtureSummary },
  } = useContext(ScoringContext);
  const token = useAuthToken();
  const {
    comparisonData,
    externalProviderUrl,
    refreshComparisonData,
    isUpdating,
    isLoading,
  } = useFixturePostMatchCheck({
    token,
    fixtureId,
    fixtureSummary,
  });
  const { comparisonRating } = useScoringRate({
    token,
    fixtureId,
  });

  if (isLoading) {
    return <LoadingOverlay isLoading />;
  }

  return (
    <Stack px={2}>
      <ComparisonTableActions
        isUpdating={isUpdating}
        refreshData={refreshComparisonData}
        comparisonData={comparisonData}
        providerUrl={externalProviderUrl}
        teamNames={{
          homeTeam: fixtureSummary?.homeTeam.teamName,
          awayTeam: fixtureSummary?.awayTeam.teamName,
        }}
      />
      <ComparisonTable comparisonData={comparisonData} />
      {comparisonRating && comparisonRating.length > 0 && (
        <ComparisonRating comparisonRating={comparisonRating} />
      )}
    </Stack>
  );
};
