import { ViewportListRef } from 'react-viewport-list';
import { MutableRefObject } from 'react';
import { MATCH_ACTION_SEND_TYPE_NAME, TEAM_DEFAULT_COLOR } from '@/constants';
import { SocketRequestPayload } from '@/contexts/scoring/createUseDispatchWithResponse';
import {
  FIXTURE_ACTION_FLAG_STATE,
  FIXTURE_ACTION_TYPE,
} from '@/service/constants';
import { FixtureAction, FixtureConfig, FixtureSummary } from '@/service/types';
import { formatDate } from '@/utils/formatDate/formatDate';
import { UpdateActionFlagMsg } from '@/workers/scoring/types';
import { getActionName } from '@/components/FixtureTabs/helpers/getActionName';
import { getActionSubTypeName } from '@/components/FixtureTabs/helpers/getActionSubTypeName';
import {
  getPlayerName,
  getPlayerShirtNumber,
} from '@/components/FixtureTabs/helpers/getPlayerNameAndNumber';
import { getTeamName } from '@/components/FixtureTabs/helpers/getTeamName';
import { ActionsTableCellDataset } from './ActionsTableCell';
import { SHEET_NAME, TABLE_CLASS_NAMES } from './constants';

export const getTeamColor = (
  teamId: string | null | undefined,
  fixtureSummary: FixtureSummary | null | undefined
) => {
  if (!teamId) return;
  if (!fixtureSummary) return TEAM_DEFAULT_COLOR;
  const team =
    fixtureSummary.homeTeam.id === teamId
      ? fixtureSummary.homeTeam
      : fixtureSummary.awayTeam.id === teamId
      ? fixtureSummary.awayTeam
      : null;
  if (!team) return TEAM_DEFAULT_COLOR;
  return team.teamColor || TEAM_DEFAULT_COLOR;
};

export const findRowInPath = (path: HTMLElement[]) =>
  path.find(
    (element) =>
      element.classList && element.classList.contains(TABLE_CLASS_NAMES.ROW)
  ) as HTMLDivElement | undefined;

export const getRowActionId = (rowEl: HTMLDivElement | undefined) => {
  return rowEl && rowEl.dataset.actionId;
};

export const findCellInPath = (path: HTMLElement[]) =>
  path.find(
    (element) =>
      element.classList && element.classList.contains(TABLE_CLASS_NAMES.CELL)
  ) as HTMLDivElement | undefined;

export const getCellDataset = (cellEl: HTMLDivElement | undefined) => {
  if (!cellEl) return;
  return cellEl.dataset as ActionsTableCellDataset;
};

export const parseCellDataValue = (
  value: string | number | boolean,
  type: ActionsTableCellDataset['filterValueType']
) => {
  switch (type) {
    case 'boolean':
      return !!value;
    case 'number':
      return Number(value);
    case 'string':
      return value;
  }
};

export const findMatchingSubstitutionAction = (
  action: FixtureAction,
  actions: FixtureAction[],
  additionalMatchingFn?: (action: FixtureAction) => boolean
): FixtureAction | null | undefined => {
  const { scorerId, scorerSeqNum } = action;
  const otherSeqNum =
    action.fixtureActionTypeId === FIXTURE_ACTION_TYPE.SUBSTITUTION_OUT
      ? scorerSeqNum + 1
      : action.fixtureActionTypeId === FIXTURE_ACTION_TYPE.SUBSTITUTION_IN
      ? scorerSeqNum - 1
      : null;

  if (otherSeqNum === null) return null;

  return actions.find((searchAction) => {
    const baseResult =
      searchAction.scorerId === scorerId &&
      searchAction.scorerSeqNum === otherSeqNum;
    if (!additionalMatchingFn) return baseResult;
    return baseResult && additionalMatchingFn(searchAction);
  });
};

export const prepareFlagUpdatePayloads = (
  action: FixtureAction,
  actions: FixtureAction[]
): SocketRequestPayload<UpdateActionFlagMsg>[] | null => {
  const { flag } = action;
  if (flag === null) return null;
  const actionIds = [action.id];
  if (
    [
      FIXTURE_ACTION_TYPE.SUBSTITUTION_IN,
      FIXTURE_ACTION_TYPE.SUBSTITUTION_OUT,
    ].includes(action.fixtureActionTypeId)
  ) {
    const matchingAction = findMatchingSubstitutionAction(
      action,
      actions,
      (action) => action.flag !== null
    );
    matchingAction && actionIds.push(matchingAction.id);
  }

  const { UNCONFIRMEND, CONFIRMED } = FIXTURE_ACTION_FLAG_STATE;
  return actionIds.map((id) => ({
    fixtureActionId: id,
    fixtureId: action.fixtureId,
    state: flag.state === UNCONFIRMEND ? CONFIRMED : UNCONFIRMEND,
  }));
};

export const getRowBackgroundStyle = (teamColor?: string) => {
  if (!teamColor) return {};
  return {
    backgroundImage: `linear-gradient(90deg, rgba(${teamColor}, 1) 6px, rgba(${teamColor}, 0.15) 2px)`,
  };
};

export const createActionsSheet = (
  actions: FixtureAction[],
  fixtureSummary: FixtureSummary | null,
  fixtureConfig?: FixtureConfig
) => {
  const mapActionData = (action: FixtureAction) => ({
    id: action.actionId,
    fixtureSeqNum: action.fixtureSeqNum,
    sendTypeId: action.sendTypeId,
    sendType: MATCH_ACTION_SEND_TYPE_NAME[action.sendTypeId],
    clockTime: action.clockTime,
    period: action.period,
    teamId: action.teamId,
    team: getTeamName(action.teamId, fixtureConfig, fixtureSummary),
    playerId: action.playerId,
    playerNumber: getPlayerShirtNumber(
      action.playerId,
      fixtureConfig,
      fixtureSummary
    ),
    playerName: getPlayerName(action.playerId, fixtureConfig, fixtureSummary),
    actionTypeId: action.fixtureActionTypeId,
    actionType: getActionName(action.fixtureActionTypeId, fixtureConfig),
    actionSubTypeId: action.fixtureActionSubTypeId,
    actionSubType: getActionSubTypeName(
      action.fixtureActionSubTypeId,
      fixtureConfig
    ),
    relatedFixtureActionId: action.relatedFixtureActionIds.join(', '),
    scorerId: action.scorerId,
    comment: action.fixtureActionComments?.[0]?.comment,
    metadata: JSON.stringify(action.metadata),
    timestamp: formatDate(action.timestamp),
  });
  return {
    name: SHEET_NAME.MATCH_DATA,
    data: actions.map(mapActionData),
  };
};

export const createFixtureSheet = (fixtureConfig?: FixtureConfig) => {
  const fixtureData = [
    {
      fixtureId: fixtureConfig?.fixture.id,
      fixtureName: fixtureConfig?.fixture.name,
      competitionId: fixtureConfig?.fixture.stage.season.competition.id,
      competitionName: fixtureConfig?.fixture.stage.season.competition.name,
    },
  ];
  return {
    name: SHEET_NAME.FIXTURE,
    data: fixtureData,
  };
};

export const scrollToTop = (
  listRef: MutableRefObject<ViewportListRef | null>
) => {
  if (listRef.current === null) return;
  listRef.current.scrollToIndex({
    index: -1,
    alignToTop: false,
  });
};
