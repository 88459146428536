import { COLUMNS } from '../constants';

export const FILTERS_HISTORY_MAX_LENGTH = 10;

export const FILTER_PROPERTY = {
  ACTION_ID: 'actionId',
  SEND_TYPE_ID: 'sendTypeId',
  PERIOD: 'period',
  FIXTURE_ACTION_TYPE_ID: 'fixtureActionTypeId',
  TEAM_ID: 'teamId',
  PLAYER_ID: 'playerId',
  FLAG: 'flag',
  COMMENT: 'fixtureActionComments',
} as const;

export const FILTER_DISPLAY_NAME = {
  ACTION_ID: 'ActionID',
  SEND_TYPE_ID: COLUMNS.SEND_TYPE.name,
  PERIOD: COLUMNS.PERIOD.name,
  FIXTURE_ACTION_TYPE_ID: COLUMNS.ACTION.name,
  TEAM_ID: COLUMNS.TEAM.name,
  PLAYER_ID: COLUMNS.PLAYER.name,
  FLAG: 'Flag',
  HIDE_FIXTURE_ACTION_TYPE_ID: 'Hide Action',
};

export type FilterProperty =
  (typeof FILTER_PROPERTY)[keyof typeof FILTER_PROPERTY];

export const GENERIC_FILTER_VALUE = {
  UNSET: '',
  FALSY: 'falsy',
  TRUTHY: 'truthy',
  UNKNOWN: 'unknown',
} as const;
export type GenericFilterValue =
  (typeof GENERIC_FILTER_VALUE)[keyof typeof GENERIC_FILTER_VALUE];
