import {
  FixtureConfig,
  FixtureSummary,
  PlayerGameStatistics,
  PlayerPeriodStatistics,
} from '@/service/types';
import { UNKNOWN_PLAYER_ID, ZERO_MINUTES_PLAYED_STRING } from '@/constants';
import { DidNotParticipatedPlayerRow } from '@/components/ScoresheetTables/PlayersTable/Rows/DidNotParticipatedPlayerRow';
import { PlayerRow } from '@/components/ScoresheetTables/PlayersTable/Rows/PlayerRow';

interface GeneratePlayerRowProps {
  statistics: PlayerGameStatistics & PlayerPeriodStatistics;
  playerId: string;
  fixtureSummary: FixtureSummary | null;
  teamId: string;
  fixtureConfig?: FixtureConfig;
}
export const generatePlayerRow = ({
  statistics,
  playerId,
  fixtureConfig,
  fixtureSummary,
  teamId,
}: GeneratePlayerRowProps) => {
  if (playerId === UNKNOWN_PLAYER_ID) {
    return null;
  }

  if (statistics.minutesPlayedString === ZERO_MINUTES_PLAYED_STRING) {
    return (
      <DidNotParticipatedPlayerRow
        playerId={playerId as string}
        fixtureSummary={fixtureSummary}
        fixtureConfig={fixtureConfig}
        key={`${playerId}-${teamId}`}
      />
    );
  }

  return (
    <PlayerRow
      playerId={playerId as string}
      statistics={statistics}
      fixtureSummary={fixtureSummary}
      fixtureConfig={fixtureConfig}
      key={`${playerId}-${teamId}`}
    />
  );
};
