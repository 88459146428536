import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { COMMON_STRING } from '@/constants/dictionary';

interface GameStateProps {
  gameStateScore: string;
}
export const GameState: FC<GameStateProps> = ({ gameStateScore }) => {
  return (
    <Box
      sx={{
        px: 1,
        pt: 0.5,
        pb: 0.5,
        border: `1px solid currentcolor`,
        textAlign: 'center',
      }}
    >
      <Typography variant='body2'>
        {`${COMMON_STRING.GAME_STATE}: ${gameStateScore}`}
      </Typography>
    </Box>
  );
};
