import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FC, useMemo } from 'react';
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';
import { IconButton } from '@mui/material';
import OpenInNew from '@mui/icons-material/OpenInNew';
import { useFixtureDetails } from '@/service/hooks/useFixtureDetails';
import { useAuthToken } from '@/contexts/auth/useAuthToken';
import { DICTIONARY } from '@/constants/dictionary';
import { useOpsUiUrl } from '@/service/hooks/useOpsUiUrl';

export interface FixtureInfoProps {
  fixtureId: string;
  isOpen: boolean;
}

export const FixtureInfo: FC<FixtureInfoProps> = ({ fixtureId, isOpen }) => {
  const token = useAuthToken();
  const { data: fixtureInfo, error } = useFixtureDetails({
    token,
    fixtureId,
  });
  const { data: opsUiUrl } = useOpsUiUrl({
    token,
    fixtureId,
  });
  const errorTitle = error?.title;
  const errorMessage = error?.message;
  const content = useMemo(() => {
    if (errorMessage || errorTitle) {
      return (
        <Box>
          {errorTitle && (
            <Typography variant='overline'>{errorTitle}</Typography>
          )}
          {errorMessage && (
            <Typography fontWeight={700} variant='body1'>
              {errorMessage}
            </Typography>
          )}
        </Box>
      );
    }

    if (!fixtureInfo) return <CircularProgress />;

    return fixtureInfo.map(([label, value], column) => (
      <Box
        key={label}
        py={1}
        flex='1 1 0'
        style={column === 0 ? { flexGrow: 2 } : undefined}
      >
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography py={1} variant='caption' lineHeight={1}>
            {label}
          </Typography>
          {column === 0 && !!opsUiUrl && (
            <IconButton
              target='_blank'
              href={opsUiUrl}
              size='small'
              color='primary'
            >
              <OpenInNew fontSize='inherit' />
            </IconButton>
          )}
        </Stack>
        <Typography lineHeight={1.1} variant='body1'>
          {value}
        </Typography>
      </Box>
    ));
  }, [errorMessage, errorTitle, fixtureInfo, opsUiUrl]);

  return (
    <Paper sx={{ width: '100%' }} square>
      <Collapse in={isOpen}>
        <Stack
          direction='row'
          justifyContent='stretch'
          divider={<Divider orientation='vertical' flexItem />}
          gap={1}
          px={1}
        >
          {!fixtureId ? DICTIONARY.ERROR.FIXTURE_ID_MISSING : content}
        </Stack>
      </Collapse>
    </Paper>
  );
};
