import { useContext } from 'react';
import { ScoringContext } from '@/contexts/scoring/context';
import {
  FixtureChecklistAddElementMsg,
  SCORING_WORKER_HOST_ACTION,
  FixtureChecklistRemoveElementMsg,
} from '@/workers/scoring/types';
import { ChecklistCheckboxState } from '@/contexts/checklist/types';

export interface UseChecklistCheckboxParams {
  checklistCheckboxState: ChecklistCheckboxState;
}

export function useChecklistCheckbox({
  checklistCheckboxState: checkbox,
}: UseChecklistCheckboxParams) {
  const {
    state: { fixtureId, fixtureChecklist },
    useDispatchWithResponse,
  } = useContext(ScoringContext);

  const checklistElement = fixtureChecklist
    ? fixtureChecklist.find(({ type }) => type === checkbox.type)
    : null;

  const addChecklistElementAction =
    useDispatchWithResponse<FixtureChecklistAddElementMsg>(
      SCORING_WORKER_HOST_ACTION.CHECKLIST_ELEMENT_ADD
    );
  const check = () =>
    addChecklistElementAction.dispatch({
      fixtureId,
      type: checkbox.type,
    });

  const removeChecklistElementAction =
    useDispatchWithResponse<FixtureChecklistRemoveElementMsg>(
      SCORING_WORKER_HOST_ACTION.CHECKLIST_ELEMENT_REMOVE
    );
  const uncheck = () => {
    if (!checklistElement) return;
    removeChecklistElementAction.dispatch({
      fixtureId,
      id: checklistElement.id,
    });
  };
  const isLoading =
    addChecklistElementAction.isLoading ||
    removeChecklistElementAction.isLoading;

  return {
    check,
    uncheck,
    isLoading,
  };
}
