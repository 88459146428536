import { useContext } from 'react';
import { ScoringContext } from '@/contexts/scoring/context';
import { ActionsTable } from '@/components/ActionsTable/ActionsTable';
import { ActionsTableProvider } from '../ActionsTable/context/ActionsTableProvider';

export const FixtureActions = () => {
  const { state } = useContext(ScoringContext);
  const { fixtureActions } = state;

  return (
    <ActionsTableProvider actions={fixtureActions?.actions}>
      <ActionsTable />
    </ActionsTableProvider>
  );
};
