import { FIXTURE_ACTION_TYPE, SPORT_ID } from '@/service/constants';
import { PERIODS_STRING } from '@/constants/dictionary';
import { FixtureAction } from '@/service/types';

interface GetOptionalPeriodNameProps {
  period: number;
  fixtureSeqNum: number;
  actions?: FixtureAction[];
}

interface GetPeriodNameProps extends GetOptionalPeriodNameProps {
  sportId?: number;
}

export const getPeriodName = ({
  period,
  fixtureSeqNum,
  actions,
  sportId,
}: GetPeriodNameProps) => {
  if (sportId !== SPORT_ID.SOCCER) return period.toString();

  switch (period) {
    case 3:
    case 4:
    case 5:
      return getOptionalPeriodName({ period, fixtureSeqNum, actions });
    default:
      return period.toString();
  }
};

export const getOptionalPeriodName = ({
  period,
  fixtureSeqNum,
  actions,
}: GetOptionalPeriodNameProps) => {
  const optionalPeriodNum =
    actions?.find(
      (action) =>
        action.fixtureActionTypeId === FIXTURE_ACTION_TYPE.END_PERIOD &&
        action.fixtureSeqNum < fixtureSeqNum &&
        action.metadata.requestedOptionalPeriodSequenceNumber
    )?.metadata.requestedOptionalPeriodSequenceNumber || period;

  return PERIODS_STRING[
    (optionalPeriodNum >= period
      ? optionalPeriodNum
      : period) as keyof typeof PERIODS_STRING
  ];
};
