import { Divider, Stack } from '@mui/material';
import { useContext } from 'react';
import { QuarterButtons } from '@/components/StatsButtons/QuarterButtons';
import { TypeButtons } from '@/components/StatsButtons/TypeButtons';
import { TeamButtons } from '@/components/StatsButtons/TeamButtons';
import { StatsContext } from '@/contexts/stats/context';
import { STATS_BUTTONS } from '@/contexts/stats/constants';
import { StatsTypeSelect } from '@/components/StatsButtons/StatsTypeSelect';

export const AmericanFootballStatsButtons = () => {
  const {
    activeButtons: { type },
  } = useContext(StatsContext);

  return (
    <>
      <Stack direction='row' gap={1}>
        <QuarterButtons />
      </Stack>
      <Divider flexItem variant='fullWidth' />
      <Stack direction='row' gap={1}>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          flex={1}
        >
          <TypeButtons />
          {type !== STATS_BUTTONS.TYPE.TEAM && (
            <StatsTypeSelect fullWidth={false} />
          )}
        </Stack>

        {type !== STATS_BUTTONS.TYPE.TEAM && (
          <>
            <Divider flexItem orientation='vertical' />
            <TeamButtons />
          </>
        )}
      </Stack>
    </>
  );
};
