import { Divider, Stack } from '@mui/material';
import { useContext } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { QuarterButtons } from '@/components/StatsButtons/QuarterButtons';
import { TypeButtons } from '@/components/StatsButtons/TypeButtons';
import { TeamButtons } from '@/components/StatsButtons/TeamButtons';
import { StatsContext } from '@/contexts/stats/context';
import { STATS_BUTTONS } from '@/contexts/stats/constants';
import { BUTTON_TYPES, StatsButtonType } from '@/contexts/stats/types';
import { getSport } from '@/service/utils/getSport';
import { SPORT_ID } from '@/service/constants';
import { useFixtureConfig } from '@/service/hooks/useFixtureConfig';
import { useAuthToken } from '@/contexts/auth/useAuthToken';
import { ScoringContext } from '@/contexts/scoring/context';

export const AdvancedStatsButtons = () => {
  const token = useAuthToken();
  const {
    typeButtons,
    changeActiveButton,
    activeButtons: { type },
  } = useContext(StatsContext);

  const {
    state: { fixtureId },
  } = useContext(ScoringContext);

  const { data: fixtureConfig } = useFixtureConfig({ token, fixtureId });

  const isSnooker = getSport(fixtureConfig)?.id === SPORT_ID.SNOOKER;

  useEffectOnce(() => {
    if (type === STATS_BUTTONS.TYPE.GAME)
      return changeActiveButton(
        typeButtons.find(
          (typeButton) => typeButton.value === STATS_BUTTONS.TYPE.PLAYER
        ) as StatsButtonType,
        BUTTON_TYPES.TYPE
      );
  });

  return (
    <>
      <Stack direction='row' gap={1}>
        <QuarterButtons />
      </Stack>
      {!isSnooker && (
        <>
          <Divider flexItem variant='fullWidth' />
          <Stack direction='row' gap={1}>
            <TypeButtons />
            {type !== STATS_BUTTONS.TYPE.TEAM && (
              <>
                <Divider flexItem orientation='vertical' />
                <TeamButtons />
              </>
            )}
          </Stack>
        </>
      )}
    </>
  );
};
