import AppBar from '@mui/material/AppBar';
import Stack from '@mui/material/Stack';
import { Theme, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ConnectionStatus } from '@/components/ConnectionStatus';
import { WSIndicator } from '@/components/ConnectionStatus/WSIndicator';
import { getEnvBorder } from '@/contexts/theme/utils';
import { LightDivider } from '@/components/common/LightDivider';
import { EnvironmentInfo } from '@/components/Navbar/EnvironmentInfo';
import IMGLogo from '@/assets/logo/imgLogo.png';
import { AppVersion } from '../AppVersion';
import { NetworkStatus } from './NetworkStatus';
import { UserMenu } from './UserMenu';
import { FixtureNavbarItems } from './FixtureNavbarItems';

export const FIXTURE_INFO_BUTTON_LABEL = 'Open Fixture Info';
export const DATA_COLLECTORS_BUTTON_LABEL = 'Open Data Collectors';

export const Navbar = () => {
  const theme = useTheme<Theme>();
  const isXS = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <AppBar
      sx={{
        borderTop: getEnvBorder(theme),
      }}
      position='sticky'
    >
      <Toolbar>
        <Stack direction={'row'} flexGrow={1} alignItems='center' gap={1}>
          <Stack gap={0.5}>
            <img src={IMGLogo} alt='IMG Arena' width={127} height={20} />
            <Stack flexDirection='row' gap={0.5}>
              <Typography fontWeight={700} component='span' fontSize='0.6em'>
                {isXS ? 'MT' : 'Monitoring Tool'}
              </Typography>
              <AppVersion />
            </Stack>
          </Stack>
          <EnvironmentInfo />
        </Stack>
        <Stack direction={'row'} gap={0.5} alignItems='center'>
          <NetworkStatus />
          <LightDivider orientation='vertical' flexItem />
          <ConnectionStatus
            label={'Server'}
            customIndicator={<WSIndicator />}
          />
          <LightDivider orientation='vertical' flexItem />
          <FixtureNavbarItems />
          <UserMenu />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
