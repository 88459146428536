import { useContext } from 'react';
import { Typography, useTheme, Grid, Stack } from '@mui/material';
import { FixtureComparisonRating } from '@/service/types';
import { ScoringContext } from '@/contexts/scoring/context';
import { ComparisonGrid } from '@/components/ComparisonRating/common';

interface ComparisonRatingInfoProps {
  comparisonRating: FixtureComparisonRating;
}

export const ComparisonRatingInfo = ({
  comparisonRating,
}: ComparisonRatingInfoProps) => {
  const theme = useTheme();
  const {
    state: { fixtureSummary },
  } = useContext(ScoringContext);
  return (
    <ComparisonGrid container rowSpacing={1}>
      <Grid item xs={6} md={3} padding={0}>
        <Typography whiteSpace='nowrap'>Data Collector:</Typography>
      </Grid>
      <Grid item xs={6} md={9} padding={0}>
        <Stack flexDirection='row' gap={theme.spacing(0.5)}>
          <Typography fontWeight='bold'>
            {comparisonRating?.user.firstname} {comparisonRating?.user.lastname}
          </Typography>
          <Typography>{comparisonRating?.role.name}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={6} md={3} padding={0}>
        <Typography whiteSpace='nowrap'>Fixture name:</Typography>
      </Grid>
      <Grid item xs={6} md={9} padding={0}>
        <Typography fontWeight='bold'>
          {fixtureSummary?.homeTeam.teamName} vs{' '}
          {fixtureSummary?.awayTeam.teamName}
        </Typography>
      </Grid>
    </ComparisonGrid>
  );
};
