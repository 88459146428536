export interface VisualNotificationMember {
  showSnack: number;
  hideSnack: number;
  isActive: boolean;
}

export const VISUAL_NOTIFICATIONS_PROPERTIES = {
  FLAG_ON_FIELD: 'flagOnField',
  POSSIBLE_VAR: 'possibleVar',
};

export interface VisualNotifications {
  flagOnField: VisualNotificationMember;
  possibleVar: VisualNotificationMember;
}

export type NotificationsFilterType = {
  actionType: number;
  sendType?: number;
};
