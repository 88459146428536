import { FC, PropsWithChildren } from 'react';
import { Stack, useTheme } from '@mui/material';
import { THEME_MODE } from '@/contexts/theme/constants';

export type InfoLabelOrientation = 'top' | 'bottom' | 'left' | 'right' | 'flat';
export const infoLabelTestId = 'info-label-test-id';
interface InfoLabelProps {
  orientation?: InfoLabelOrientation;
}
const DEFAULT_ORIENTATION: InfoLabelOrientation = 'top';
const getBorderRadiusVariant = (
  orientation: InfoLabelOrientation,
  radiusValue: number
) => {
  switch (orientation) {
    case 'top':
      return `0 0 ${radiusValue}px ${radiusValue}px`;
    case 'bottom':
      return `${radiusValue}px ${radiusValue}px 0 0`;
    case 'left':
      return `0 ${radiusValue}px ${radiusValue}px 0`;
    case 'right':
      return `${radiusValue}px 0 0 ${radiusValue}px`;
    default:
      return 'unset';
  }
};

export const InfoLabel: FC<PropsWithChildren<InfoLabelProps>> = ({
  children,
  orientation = DEFAULT_ORIENTATION,
}) => {
  const theme = useTheme();
  return (
    <Stack
      direction='row'
      fontSize='small'
      gap={0.75}
      alignItems='center'
      style={{
        borderRadius: getBorderRadiusVariant(
          orientation,
          theme.shape.borderRadius
        ),
      }}
      sx={{
        background: ({ palette }) =>
          palette.mode === THEME_MODE.LIGHT
            ? palette.secondary.dark
            : palette.secondary.light,
        color: ({ palette }) => palette.secondary.contrastText,
        padding: (theme) => theme.spacing(0.75, 1.5),
        textShadow: '0px 0.5px 3px rgba(0,0,0, 0.75)',
      }}
    >
      {children}
    </Stack>
  );
};
