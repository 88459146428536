import { PropsWithChildren, useContext, useEffect, useReducer } from 'react';
import { SnackbarKey, useSnackbar } from 'notistack';
import { useTheme } from '@mui/material';
import { ScoringContext } from '@/contexts/scoring/context';
import { VisualNotificationsContext } from '@/contexts/visualNotifications/context';
import {
  findShowAndHideActions,
  generateInitialVisualNotificationsState,
  generateNewVisualNotificationsResults,
  generateSnackbarOptions,
} from '@/contexts/visualNotifications/utils';
import {
  VISUAL_NOTIFICATIONS_PROPERTIES,
  VisualNotifications,
} from '@/contexts/visualNotifications/types';
import { SnackbarNoVarAction } from '@/components/SnackbarActions/SnackbarNoVarAction';
import { COMMON_STRING } from '@/constants/dictionary';
import { VISUAL_NOTIFICATIONS_FILTERS } from '@/contexts/visualNotifications/constants';

export const VisualNotificationsProvider = ({
  children,
}: PropsWithChildren) => {
  const {
    state: { fixtureId, fixtureActions, newFixtureActions },
    useDispatchWithResponse,
  } = useContext(ScoringContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const theme = useTheme();

  const visualNotificationsReducer = (
    state: VisualNotifications,
    newValue: Partial<VisualNotifications>
  ): VisualNotifications => {
    return { ...state, ...newValue };
  };

  const [visualNotificationsState, dispatchVisualNotifications] = useReducer(
    visualNotificationsReducer,
    {} as VisualNotifications
  );

  useEffect(() => {
    if (
      !fixtureActions?.actions ||
      (fixtureActions.actions && !!Object.keys(visualNotificationsState).length)
    )
      return;
    return dispatchVisualNotifications(
      generateInitialVisualNotificationsState(fixtureActions.actions)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixtureActions]);

  useEffect(() => {
    if (!newFixtureActions.length) return;

    const newFlagOnFieldAction = findShowAndHideActions({
      ...VISUAL_NOTIFICATIONS_FILTERS.FLAG_ON_FIELD,
      fixtureActions: newFixtureActions,
    });

    const newPossibleVarAction = findShowAndHideActions({
      ...VISUAL_NOTIFICATIONS_FILTERS.POSSIBLE_VAR,
      fixtureActions: newFixtureActions,
    });

    return dispatchVisualNotifications({
      flagOnField: generateNewVisualNotificationsResults(
        newFlagOnFieldAction,
        visualNotificationsState.flagOnField
      ),
      possibleVar: generateNewVisualNotificationsResults(
        newPossibleVarAction,
        visualNotificationsState.possibleVar
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newFixtureActions]);

  useEffect(() => {
    if (!visualNotificationsState) return;
    actionsInfoSnackbars('flagOnField');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visualNotificationsState.flagOnField]);

  useEffect(() => {
    if (!visualNotificationsState) return;
    actionsInfoSnackbars('possibleVar');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visualNotificationsState.possibleVar]);

  const actionsInfoSnackbars = (type: keyof VisualNotifications) => {
    if (type === VISUAL_NOTIFICATIONS_PROPERTIES.FLAG_ON_FIELD) {
      return visualNotificationsState[type]?.isActive
        ? enqueueSnackbar(
            COMMON_STRING.FLAG_ON_FIELD,
            generateSnackbarOptions(type, theme, { action: () => null })
          )
        : closeSnackbar(type);
    }

    if (type === VISUAL_NOTIFICATIONS_PROPERTIES.POSSIBLE_VAR) {
      return visualNotificationsState[type]?.isActive
        ? enqueueSnackbar(
            COMMON_STRING.POSSIBLE_VAR_ACTIVATED,
            generateSnackbarOptions(type, theme, {
              action: (snackbarId: SnackbarKey) => (
                <SnackbarNoVarAction
                  fixtureId={fixtureId}
                  snackbarId={snackbarId}
                  useDispatchWithResponse={useDispatchWithResponse}
                />
              ),
            })
          )
        : closeSnackbar(type);
    }
  };

  return (
    <VisualNotificationsContext.Provider value={visualNotificationsState}>
      {children}
    </VisualNotificationsContext.Provider>
  );
};
