import {
  CheckListElementType,
  CHECKLIST_ELEMENT_TYPE,
} from '@/service/types/checklist';
import { ChecklistCheckboxState } from './types';

export const DEFAULT_CHECKLIST_CHECKBOX_STATE: Partial<
  Record<CheckListElementType, Partial<ChecklistCheckboxState>>
> = {
  [CHECKLIST_ELEMENT_TYPE.POST_MATCH_CHECK_COMPLETE]: {
    requiresConfirmation: true,
  },
};
