import { OptionsWithExtraProps } from 'notistack';
import {
  FIXTURE_ACTION_TYPE,
  MATCH_ACTION_SEND_TYPE,
} from '@/service/constants';

export const VISUAL_NOTIFICATIONS_FILTERS = {
  FLAG_ON_FIELD: {
    showFilters: [{ actionType: FIXTURE_ACTION_TYPE.FLAG_ON_FIELD_TYPE }],
    hideFilters: [
      {
        actionType: FIXTURE_ACTION_TYPE.PENALTY,
        sendType: MATCH_ACTION_SEND_TYPE.CONFIRMED,
      },
    ],
  },
  POSSIBLE_VAR: {
    showFilters: [
      {
        actionType: FIXTURE_ACTION_TYPE.POSSIBLE_VAR,
        sendType: MATCH_ACTION_SEND_TYPE.CONFIRMED,
      },
    ],
    hideFilters: [
      {
        actionType: FIXTURE_ACTION_TYPE.NO_VIDEO_CHECK,
        sendType: MATCH_ACTION_SEND_TYPE.CONFIRMED,
      },
      {
        actionType: FIXTURE_ACTION_TYPE.VIDEO_CHECK_UNDERWAY,
        sendType: MATCH_ACTION_SEND_TYPE.CONFIRMED,
      },
      {
        actionType: FIXTURE_ACTION_TYPE.VIDEO_CHECK_RESULT,
        sendType: MATCH_ACTION_SEND_TYPE.CONFIRMED,
      },
      {
        actionType: FIXTURE_ACTION_TYPE.POSSIBLE_VAR,
        sendType: MATCH_ACTION_SEND_TYPE.DELETED,
      },
    ],
  },
};

export const DEFAULT_VISUAL_NOTIFICATIONS_SNACKBAR_OPTIONS: OptionsWithExtraProps<'info'> =
  {
    variant: 'info',
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    persist: true,
    autoHideDuration: null,
    hideIconVariant: true,
    preventDuplicate: true,
  };
