import { useMemo } from 'react';
import { useFixtureConfig } from './useFixtureConfig';

const VOLLEYBALL_SPORT_NAMES = ['volleyball', 'beach-volleyball'];
const OPS_UI_URL = import.meta.env.VITE_OPS_UI_URL;

interface GenerateOpsUiUrlParams {
  sportName: string;
  seasonId: string;
  fixtureId: string;
  isVolleyball: boolean;
}
export function generateOpsUiUrl({
  sportName,
  seasonId,
  fixtureId,
  isVolleyball,
}: GenerateOpsUiUrlParams) {
  const pathPrefix = `/tournament/${
    isVolleyball ? 'fivbvolleyball' : sportName
  }`;
  return `${OPS_UI_URL}${pathPrefix}/${seasonId}/${fixtureId}`;
}

interface UseOpsUiUrlParams {
  token: string | undefined;
  fixtureId: string;
}

export function useOpsUiUrl({ token, fixtureId }: UseOpsUiUrlParams) {
  const { data, error, isLoading, mutate } = useFixtureConfig({
    token,
    fixtureId,
  });

  const linkUrl = useMemo(() => {
    if (!data) return '';
    const season = data.fixture.stage.season;
    const sportName = season.competition.sport.name
      .toLowerCase()
      .replace(/\s+/g, '-');
    const url = generateOpsUiUrl({
      sportName,
      seasonId: season.id,
      fixtureId,
      isVolleyball: VOLLEYBALL_SPORT_NAMES.includes(sportName),
    });
    return url;
  }, [data, fixtureId]);

  return {
    data: linkUrl,
    error,
    isLoading,
    mutate,
  };
}
