import { Stack, Chip } from '@mui/material';
import { FC, useCallback, useContext } from 'react';
import { AnyPrimitive } from '@/types/common';
import { FixtureConfig, FixtureSummary } from '@/service/types';
import { ExportToXlsxButton } from '@/components/common/ExportToXlsxButton';
import type { ActionFilter } from '../ActionFilters/useActionsFilters';
import { ActionsTableContext } from '../context/ActionsTableContext';
import { createActionsSheet, createFixtureSheet } from '../utils';
import { FilterButtons } from './FilterButtons';
interface ActionTableBarProps {
  disableFilters: boolean;
  fixtureSummary: FixtureSummary | null;
  fixtureConfig?: FixtureConfig;
}

const createFilterChip = (
  columnName: string,
  value: AnyPrimitive,
  onDelete?: () => void
) => {
  return (
    <Chip
      key={columnName + value}
      sx={{ cursor: 'inherit' }}
      size='small'
      label={`${columnName}: ${value ?? 'none'}`}
      onDelete={onDelete}
    />
  );
};
const getFiltersChips = (
  filters: ActionFilter[],
  onDelete?: (filterIndex: number) => void
) =>
  filters.map(
    ({ displayName, property, displayValue, value, exclude }, index) => {
      const onFilterDelete = onDelete ? () => onDelete(index) : undefined;
      const name = `${exclude ? 'Hide ' : ''}${displayName || property}`;
      const val = displayValue || value;
      return createFilterChip(name, val, onFilterDelete);
    }
  );

export const ActionsTableActionBar: FC<ActionTableBarProps> = ({
  disableFilters,
  fixtureConfig,
  fixtureSummary,
}) => {
  const { displayActions, filters, onFilterRemove } =
    useContext(ActionsTableContext);

  const getExportSheetData = useCallback(
    () => [
      createActionsSheet(displayActions, fixtureSummary, fixtureConfig),
      createFixtureSheet(fixtureConfig),
    ],
    [fixtureSummary, fixtureConfig, displayActions]
  );

  return (
    <Stack direction='row' gap={1}>
      <Stack
        p={0.5}
        direction='row'
        gap={0.5}
        alignItems='center'
        flex={1}
        flexWrap='wrap'
      >
        {!!filters.length &&
          getFiltersChips(filters, disableFilters ? undefined : onFilterRemove)}
      </Stack>
      <Stack direction='row' maxWidth='25%' alignItems='flex-start'>
        <FilterButtons disableFilters={disableFilters} />
        <ExportToXlsxButton
          fileName={`${fixtureConfig?.fixture.name} report`}
          getData={getExportSheetData}
        />
      </Stack>
    </Stack>
  );
};
