import { Dispatch } from 'react';

import { HubConnectionState } from '@microsoft/signalr';
import {
  ScoringWorkerHostMsg,
  ScoringAPIWorker,
  ScoringWorkerMsg,
} from '@/workers/scoring/types';
import { Actions, FixtureAction, FixtureSummary } from '@/service/types';
import { FixtureChecklistElement } from '@/service/types/checklist';
import { createUseDispatchWithResponse } from './createUseDispatchWithResponse';
import { createUseDispatchManyWithResponse } from './createUseDispatchManyWithResponse';

// Reducer exclusive actions
export enum SCORING_REDUCER_ACTION {
  NETWORK = 'network',
  WORKER_INIT = 'workerInit',
  NEW_ACTION = 'newAction',
  NEW_ACTION_SEEN = 'newActionSeen',
}
export type ScoringReducerWorkerInitAction = {
  action: SCORING_REDUCER_ACTION.WORKER_INIT;
  payload: Pick<ScoringState, 'worker'>;
};
export type ScoringReducerWorkerNetworkAction = {
  action: SCORING_REDUCER_ACTION.NETWORK;
  payload: Pick<ScoringState, 'isOnline'>;
};
export type ScoringReducerNewActionAction = {
  action: SCORING_REDUCER_ACTION.NEW_ACTION;
  payload: FixtureAction;
};
export type ScoringReducerNewActionSeenAction = {
  action: SCORING_REDUCER_ACTION.NEW_ACTION_SEEN;
  payload: FixtureAction['id'];
};
export type ScoringReducerCustomAction =
  | ScoringReducerWorkerInitAction
  | ScoringReducerWorkerNetworkAction
  | ScoringReducerNewActionAction
  | ScoringReducerNewActionSeenAction;

export type ScoringReducerAction =
  | ScoringReducerCustomAction
  | ScoringWorkerMsg
  | ScoringWorkerHostMsg;

export type ScoringContextValues = {
  state: ScoringState;
  dispatch: Dispatch<ScoringReducerAction | ScoringWorkerHostMsg>;
  useDispatchWithResponse: ReturnType<typeof createUseDispatchWithResponse>;
  useDispatchManyWithResponse: ReturnType<
    typeof createUseDispatchManyWithResponse
  >;
};

export type ScoringState = {
  isOnline: boolean;
  worker: ScoringAPIWorker | null;
  isWorkerReady: boolean;
  wsConnection: HubConnectionState;
  fixtureId: string;
  isScorerConnected: boolean;
  fixtureSummary: FixtureSummary | null;
  fixtureActions: Actions | null;
  newFixtureActions: FixtureAction[];
  /**
   * Pre-filtered array of actions used in checklist logic.
   * It's kept in ScoringState to utilise worker thread
   * for filtering whole actions array.
   */
  checklistActions: FixtureAction[];
  fixtureChecklist: FixtureChecklistElement[] | null;
};
