import { FC, useContext } from 'react';
import Stack from '@mui/material/Stack';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { FixtureScore } from '@/components/FixtureScore';
import { ScoringContext } from '@/contexts/scoring/context';
import { useFixtureConfig } from '@/service/hooks/useFixtureConfig';
import { useAuthToken } from '@/contexts/auth/useAuthToken';
import { getSport } from '@/service/utils/getSport';
import {
  CollectionStatusId,
  FixtureStatusId,
  FIXTURE_ACTION_TYPE,
  SPORT_ID,
} from '@/service/constants';
import { getPeriodActionAggregate } from '@/service/utils/getActionAggregates';
import { getSoccerSummaryStats } from '@/service/utils/getSoccerSummaryStats';
import { useFixturePostMatchCheck } from '@/service/hooks/useFixturePostMatchCheck';
import { convertPascalCaseTextToSentence } from '@/utils';
import { GameState } from '@/components/FixtureSummary/GameState';
import { generateGameState } from '@/components/FixtureSummary/utils';
import { LoadingPlaceholder } from '../common/LoadingPlaceholder';
import { TextSeparator } from '../common/TextSeparator';
import { StatsCompareTable } from '../StatsCompareTable';
import { PeriodsScores } from './PeriodsScores';
import { StatusesBar } from './StatusesBar';

const StatusSubText: FC<TypographyProps> = (props) => {
  return (
    <Typography
      fontSize='inherit'
      component='span'
      variant='caption'
      lineHeight={1}
      {...props}
    />
  );
};
const StatusSubValueText: FC<TypographyProps> = (props) => {
  return (
    <StatusSubText textTransform='uppercase' fontWeight={700} {...props} />
  );
};

export const FixtureSummary = () => {
  const {
    state: { fixtureSummary, fixtureId },
  } = useContext(ScoringContext);

  const token = useAuthToken();
  const { data: fixtureConfig } = useFixtureConfig({ token, fixtureId });
  const { comparisonData, refreshComparisonData, isUpdating } =
    useFixturePostMatchCheck({
      token,
      fixtureId,
      fixtureSummary,
    });
  if (!fixtureSummary) {
    return <LoadingPlaceholder py={5} />;
  }

  const {
    currentPeriod,
    currentClock,
    isClockPaused,
    currentStatus,
    fixtureStatus,
    collectionStatus,
    coverageLevel,
    homeTeam,
    awayTeam,
    currentScore,
    periods,
    advancedSportStatistics,
  } = fixtureSummary;

  const sport = getSport(fixtureConfig);
  const isBasket = sport ? sport.id === SPORT_ID.BASKETBALL : false;
  const periodAggregateActions = isBasket
    ? getPeriodActionAggregate(fixtureSummary, FIXTURE_ACTION_TYPE.PLAYER_FOUL)
    : undefined;

  const isSoccer = sport ? sport.id === SPORT_ID.SOCCER : false;

  const soccerStats = isSoccer
    ? getSoccerSummaryStats(fixtureSummary, comparisonData)
    : undefined;

  return (
    <Stack direction='row' alignSelf='stretch' justifyContent='space-evenly'>
      {isSoccer && soccerStats && (
        <StatsCompareTable
          stats={soccerStats.homeTeam}
          refreshData={refreshComparisonData}
          isUpdating={isUpdating}
        />
      )}

      <Stack
        justifySelf='center'
        alignSelf='flex-start'
        alignItems='center'
        flex='1 1 auto'
        gap={1}
        p={1}
        pt={0}
      >
        <StatusesBar
          fixtureStatusId={fixtureStatus as FixtureStatusId}
          collectionStatusId={collectionStatus as CollectionStatusId}
          coverageLevelId={coverageLevel}
        />
        <Stack
          fontSize={'small'}
          color={(theme) => theme.typography.caption.color}
          direction='row'
          gap={0.5}
          divider={<TextSeparator />}
        >
          <StatusSubText>
            {'Period '}
            <StatusSubValueText>{currentPeriod}</StatusSubValueText>
          </StatusSubText>

          <StatusSubText>
            {'Scoring app status: '}
            <StatusSubValueText>
              {convertPascalCaseTextToSentence(currentStatus)}
            </StatusSubValueText>
          </StatusSubText>
        </Stack>

        <FixtureScore
          homeTeam={{
            id: homeTeam.id,
            name: homeTeam.teamName,
            color: homeTeam.teamColor,
            score: currentScore.homeTeamScore,
          }}
          awayTeam={{
            id: awayTeam.id,
            name: awayTeam.teamName,
            color: awayTeam.teamColor,
            score: currentScore.awayTeamScore,
          }}
          stateWithTimeoutInfo={
            advancedSportStatistics?.americanFootballFixtureState
          }
          currentClock={currentClock}
          isClockPaused={isClockPaused}
        />

        <PeriodsScores
          periods={periods}
          actionAggregates={periodAggregateActions}
        />

        {advancedSportStatistics?.americanFootballFixtureState && (
          <GameState
            gameStateScore={generateGameState({
              americanFootballStatistics:
                advancedSportStatistics.americanFootballFixtureState,
              homeTeam,
              awayTeam,
            })}
          />
        )}
      </Stack>

      {isSoccer && soccerStats && (
        <StatsCompareTable
          stats={soccerStats.awayTeam}
          refreshData={refreshComparisonData}
          isUpdating={isUpdating}
        />
      )}
    </Stack>
  );
};
