import { TableRow, Tooltip } from '@mui/material';
import { useContext, useMemo, useRef, useState } from 'react';
import {
  StatsTable,
  StatsTableContainer,
  StatsTableHead,
  StyledCol,
  TableRowWithHover,
} from '@/components/StatsTableCells/common';
import {
  StatsTableDataCell,
  StatsTableHeaderActionCell,
  StatsTableStickyDataCell,
  StatsTableStickyHeaderActionCell,
} from '@/components/StatsTableCells/StatsTableCells';
import { getPlayerName } from '@/components/FixtureTabs/helpers/getPlayerNameAndNumber';
import { getTeamName } from '@/components/FixtureTabs/helpers/getTeamName';
import { StatsContext } from '@/contexts/stats/context';
import { ScoringContext } from '@/contexts/scoring/context';
import { useAuthToken } from '@/contexts/auth/useAuthToken';
import { useFixtureConfig } from '@/service/hooks/useFixtureConfig';
import { ActiveColumnType } from '@/components/StatsTableCells/types';
import { StatsTableBody } from '@/components/StatsTableBody/StatsTableBody';
import { InfoMessage } from '@/components/common/InfoMessage';
import {
  filterAmericanFootballTableData,
  generateAmericanFootballStatsHeader,
} from '@/components/AmericanFootballStatsTable/utils';
import { AmericanFootballStatsTableData } from '@/components/AmericanFootballStatsTable/types';

export const AmericanFootballStatsTable = () => {
  const token = useAuthToken();
  const {
    state: { fixtureId, fixtureSummary },
  } = useContext(ScoringContext);
  const {
    activeButtons: { quarter, type, team, statsType },
  } = useContext(StatsContext);
  const { data: fixtureConfig } = useFixtureConfig({ token, fixtureId });
  const [activeColumn, setActiveColumn] = useState<ActiveColumnType>(null);
  const colGroupRef = useRef<HTMLTableColElement>(null);
  const header = useMemo(
    () => generateAmericanFootballStatsHeader(statsType, type),
    [statsType, type]
  );
  const tableData = useMemo(() => {
    if (!fixtureSummary) return;
    return filterAmericanFootballTableData({
      activeQuarter: quarter,
      activeType: type,
      activeTeam: team,
      advancedSportStatistics: fixtureSummary.advancedSportStatistics,
    }) as AmericanFootballStatsTableData[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixtureSummary, quarter, type, team, statsType]);

  if (!tableData || tableData.length === 0) {
    return (
      <InfoMessage message={`American football stats data doesn't exist...`} />
    );
  }

  return (
    <StatsTableContainer>
      <StatsTable>
        <colgroup ref={colGroupRef}>
          <col />
          {header.map((data, index) => (
            <StyledCol
              key={`col-${index}`}
              index={index + 1}
              activeColumn={activeColumn}
            />
          ))}
        </colgroup>
        <StatsTableHead>
          <TableRow>
            <StatsTableStickyHeaderActionCell key='header-name'>
              Name
            </StatsTableStickyHeaderActionCell>
            {header?.map((action) => (
              <Tooltip
                title={action.value}
                key={action.value}
                placement='top'
                arrow
              >
                <StatsTableHeaderActionCell key={action.shortValue}>
                  {action.shortValue}
                </StatsTableHeaderActionCell>
              </Tooltip>
            ))}
          </TableRow>
        </StatsTableHead>
        <StatsTableBody
          activeColumn={activeColumn}
          setActiveColumn={setActiveColumn}
          colGroupRef={colGroupRef}
        >
          {tableData.map((statistics) => (
            <TableRowWithHover
              key={`table-cell-${statistics.playerId || statistics.teamId}`}
            >
              <StatsTableStickyDataCell>
                {statistics?.playerId
                  ? getPlayerName(
                      statistics?.playerId,
                      fixtureConfig,
                      fixtureSummary
                    )
                  : statistics?.teamId
                  ? getTeamName(
                      statistics?.teamId,
                      fixtureConfig,
                      fixtureSummary
                    )
                  : ''}
              </StatsTableStickyDataCell>
              {header.map((action) => (
                <StatsTableDataCell
                  key={`${statistics.playerId || statistics.teamId}-${
                    action.key
                  }`}
                >
                  {statistics.statistics[action.key] as string | number}
                </StatsTableDataCell>
              ))}
            </TableRowWithHover>
          ))}
        </StatsTableBody>
      </StatsTable>
    </StatsTableContainer>
  );
};
