import { Stack, TextField, Typography } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { ActionsDialog } from '@/components/ActionsDialog/ActionsDialog';
import { COMMON_STRING } from '@/constants/dictionary';
import { ActionDialogProps } from '@/components/ActionsDialog/types';
import { FixtureActionComment } from '@/service/types';
import { CommentButtons } from '@/components/Comment/CommentButtons';
export const CommentDialog = ({ action, onClose }: ActionDialogProps) => {
  const { fixtureActionComments } = action;
  const currentOriginalComment = (fixtureActionComments &&
    fixtureActionComments[0]) || {
    id: undefined,
    fixtureId: action.fixtureId,
    fixtureActionId: action.id,
    comment: '',
  };
  const [comment, setComment] = useState<FixtureActionComment>(
    currentOriginalComment
  );

  const [commentWhenOpened, setCommentWhenOpened] = useState(
    currentOriginalComment.comment
  );

  const didLocalCommentChange = () => {
    const actionComment = currentOriginalComment.comment;
    const newComment = comment.comment;
    return actionComment !== newComment;
  };
  const didOriginalCommentChange = () => {
    return commentWhenOpened !== currentOriginalComment.comment;
  };
  const syncOriginalComment = () => {
    setCommentWhenOpened(currentOriginalComment.comment);
    setComment(currentOriginalComment);
  };

  return (
    <ActionsDialog
      open={true}
      onClose={onClose}
      title={COMMON_STRING.ACTION_COMMENT}
      maxWidth='sm'
      fullWidth
      sx={{ justifyContent: 'left' }}
    >
      <Stack gap={2} width='100%'>
        <Stack flexDirection='row' gap={0.5}>
          <Typography>Seq num: </Typography>
          <Typography component='span' fontStyle='italic'>
            {action.fixtureSeqNum}
          </Typography>
        </Stack>
        <TextField
          id='comment'
          multiline
          value={comment.comment}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setComment({
              ...comment,
              comment: event.target.value,
            });
          }}
          minRows={6}
          maxRows={16}
        />
        <CommentButtons
          hasLocalChanges={didLocalCommentChange()}
          hasRemoteChanges={didOriginalCommentChange()}
          onSyncClick={() => syncOriginalComment()}
          action={action}
          onClose={onClose}
          comment={comment}
        />
      </Stack>
    </ActionsDialog>
  );
};
