import { FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Favicon from 'react-favicon';
import { useEffectOnce } from 'usehooks-ts';
import { COMMON_STRING } from '@/constants/dictionary';
import { DOCUMENT_TITLE, FAVICON_URLS } from '@/pages/constants';
import homeStyle from './Home.module.css';

const FIXTURE_ID = 'fixture-id';
export const Home = () => {
  const navigate = useNavigate();

  useEffectOnce(() => {
    document.title = DOCUMENT_TITLE;
  });

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    navigate(
      `/${new FormData(event.target as HTMLFormElement).get(FIXTURE_ID)}`
    );
  };

  return (
    <Paper elevation={1} sx={{ p: 2, width: '100%', maxWidth: 600 }}>
      <Favicon url={FAVICON_URLS.DEFAULT} />
      <form className={homeStyle.form} onSubmit={onSubmit}>
        <TextField
          fullWidth={true}
          variant='standard'
          type='text'
          name={FIXTURE_ID}
          id={FIXTURE_ID}
          label='Fixture ID'
        />
        <Button variant='contained' type='submit'>
          {COMMON_STRING.SUBMIT}
        </Button>
      </form>
    </Paper>
  );
};
