import { FC, Fragment, useContext } from 'react';
import { Box, Typography } from '@mui/material';

import { PreferencesContext } from '@/contexts/preferences/context';
import { SoundSettingsEdit } from './SoundSetting';

export const NotificationSounds: FC = () => {
  const { soundsPreferences } = useContext(PreferencesContext);

  return (
    <>
      <Box
        display='grid'
        gridTemplateColumns='minmax(max-content, 2fr) 9fr minmax(max-content, 1fr)'
        gridAutoRows='max-content'
        alignItems='center'
        gap={2}
      >
        <Typography variant='overline'>Sound</Typography>
        <Typography variant='overline'>Volume</Typography>
        <Typography variant='overline' textAlign='center'>
          Colour
        </Typography>
        {Object.values(soundsPreferences.sounds).map((sound) => (
          <SoundSettingsEdit sound={sound} key={sound.id} />
        ))}
      </Box>
    </>
  );
};
