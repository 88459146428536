import { Button, Divider, Stack } from '@mui/material';
import { FC, useContext } from 'react';
import { PreferencesContext } from '@/contexts/preferences/context';
import { PREFERENCES_STRING } from '@/constants/dictionary';
import { SectionTitleTypography } from '../PreferencesTypography';
import { NotificationMasterVolume } from './NotificationMasterVolume';
import { NotificationSounds } from './NotificationSounds';

export const SoundPreferences: FC = () => {
  const {
    isSoundsPreferencesAppDefault,
    isSoundsPreferencesUserDefault,
    actions: {
      saveUserDefaultSoundsPreferences,
      restoreUserDefaultSoundsPreferences,
      restoreAppDefaultSoundsPreferences,
    },
  } = useContext(PreferencesContext);

  return (
    <Stack gap={2}>
      <SectionTitleTypography>Master volume</SectionTitleTypography>
      <NotificationMasterVolume />

      <SectionTitleTypography>Sound list</SectionTitleTypography>
      <NotificationSounds />
      <Divider />
      <Stack
        direction={'row'}
        justifyContent='flex-end'
        flexWrap='wrap'
        gap={1}
      >
        <Button
          size='small'
          variant='text'
          disabled={isSoundsPreferencesAppDefault}
          onClick={restoreAppDefaultSoundsPreferences}
        >
          {PREFERENCES_STRING.APPLY_APP_DEFAULT}
        </Button>
        <Button
          size='small'
          variant='text'
          disabled={isSoundsPreferencesUserDefault}
          onClick={restoreUserDefaultSoundsPreferences}
        >
          {PREFERENCES_STRING.APPLY_USER_DEFAULT}
        </Button>
        <Button
          size='small'
          variant='contained'
          disabled={isSoundsPreferencesUserDefault}
          onClick={saveUserDefaultSoundsPreferences}
        >
          {PREFERENCES_STRING.SAVE_USER_DEFAULT}
        </Button>
      </Stack>
    </Stack>
  );
};
