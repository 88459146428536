import { konsole } from '@/utils/konsole';

export const COLUMNS = {
  SEQ: { name: 'Seq Num', width: '7%' },
  SEND_TYPE: { name: 'Send Type', width: '10%' },
  CLOCK_TIME: { name: 'Clock Time', width: '12%' },
  PERIOD: { name: 'Period', width: '6%' },
  ACTION_ICONS: { name: 'Action Icon', width: '3%' },
  ACTION: { name: 'Action', width: '14%' },
  TEAM: { name: 'Team', width: '14%' },
  PLAYER: { name: 'Player', width: '14%' },
  SCORE: { name: 'Score', width: '7%' },
  MORE: {
    name: 'More',
    width: '13%',
  },
};

export const SHEET_NAME = {
  MATCH_DATA: 'Matсh Data',
  FIXTURE: 'Fixture',
};

export const TABLE_CLASS_NAMES = {
  ROW: 'action-row',
  CELL: 'action-cell',
};

const colWidthsTotal = Object.values(COLUMNS).reduce((acc, column) => {
  const val = Number(column.width.slice(0, -1));
  return acc + val;
}, 0);
if (colWidthsTotal !== 100) {
  konsole.warn('ActionsTable column widths', colWidthsTotal);
}

export const BUTTON_NAME = {
  FLAG: 'flag-button',
  MORE: 'more-button',
  COMMENT: 'comment-button',
} as const;
