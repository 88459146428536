export interface FixtureChecklistElement {
  id: string;
  fixtureId: string;
  type: CheckListElementType;
  isChecked: boolean;
}
export const CHECKLIST_ELEMENT_TYPE = {
  ATTENDANCE_GEO: 1,
  ATTENDANCE_SELFIE: 2,
  MATCH_CONDITIONS: 3,
  TEAM_COLOURS: 4,
  LINEUPS: 5,
  ATTENDANCE_SCOREBOARD: 6,
  POST_MATCH_CHECK_COMPLETE: 7,
} as const;
export type CheckListElementType =
  typeof CHECKLIST_ELEMENT_TYPE[keyof typeof CHECKLIST_ELEMENT_TYPE];

export const CHECKLIST_ELEMENT_NAME = {
  [CHECKLIST_ELEMENT_TYPE.ATTENDANCE_GEO]: 'Attendance - geolocation',
  [CHECKLIST_ELEMENT_TYPE.ATTENDANCE_SELFIE]: 'Attendance - selfie',
  [CHECKLIST_ELEMENT_TYPE.MATCH_CONDITIONS]: 'Match conditions',
  [CHECKLIST_ELEMENT_TYPE.TEAM_COLOURS]: 'Team colours',
  [CHECKLIST_ELEMENT_TYPE.LINEUPS]: 'Lineups',
  [CHECKLIST_ELEMENT_TYPE.ATTENDANCE_SCOREBOARD]: 'Attendance - scoreboard',
  [CHECKLIST_ELEMENT_TYPE.POST_MATCH_CHECK_COMPLETE]:
    'Post match check complete',
} as const;
export type ChecklistElementName =
  typeof CHECKLIST_ELEMENT_NAME[keyof typeof CHECKLIST_ELEMENT_NAME];
