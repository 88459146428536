import { Paper, Stack, Box } from '@mui/material';
import { COLUMNS } from './constants';

export const ActionsTableHeader = () => {
  return (
    <Paper
      elevation={2}
      sx={{
        top: 0,
        position: 'sticky',
        zIndex: 1,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      }}
    >
      <Stack
        direction='row'
        sx={{
          '&>.MuiBox-root': {
            fontSize: '0.75rem',
            fontWeight: 700,
            lineHeight: 1.25,
            padding: (theme) => theme.spacing(0.5, 0, 0.5, 1),
            '&:first-of-type': {
              paddingLeft: (theme) => theme.spacing(2.5),
            },
            '&:last-of-type': {
              padding: (theme) => theme.spacing(0.5, 0, 0.5, 0),
            },
          },
        }}
      >
        <Box width={COLUMNS.SEQ.width}>{COLUMNS.SEQ.name}</Box>
        <Box width={COLUMNS.SEND_TYPE.width}>{COLUMNS.SEND_TYPE.name}</Box>
        <Box width={COLUMNS.CLOCK_TIME.width}>{COLUMNS.CLOCK_TIME.name}</Box>
        <Box width={COLUMNS.PERIOD.width}>{COLUMNS.PERIOD.name}</Box>
        <Box width={COLUMNS.ACTION_ICONS.width} textAlign='right'></Box>
        <Box width={COLUMNS.ACTION.width}>{COLUMNS.ACTION.name}</Box>
        <Box width={COLUMNS.TEAM.width}>{COLUMNS.TEAM.name}</Box>
        <Box width={COLUMNS.PLAYER.width}>{COLUMNS.PLAYER.name}</Box>
        <Box width={COLUMNS.SCORE.width}>{COLUMNS.SCORE.name}</Box>
        <Box width={COLUMNS.MORE.width} textAlign='right'></Box>
      </Stack>
    </Paper>
  );
};
