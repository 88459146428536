import { OptionsWithExtraProps } from 'notistack';
import { Theme } from '@mui/material';
import { FixtureAction } from '@/service/types';
import {
  NotificationsFilterType,
  VisualNotificationMember,
  VisualNotifications,
} from '@/contexts/visualNotifications/types';
import {
  DEFAULT_VISUAL_NOTIFICATIONS_SNACKBAR_OPTIONS,
  VISUAL_NOTIFICATIONS_FILTERS,
} from '@/contexts/visualNotifications/constants';

interface FindShowAndHideActionsProps {
  showFilters: NotificationsFilterType[];
  hideFilters: NotificationsFilterType[];
  fixtureActions: FixtureAction[];
}

export const generateInitialVisualNotificationsState = (
  fixtureActions: FixtureAction[]
) => {
  return {
    flagOnField: findShowAndHideActions({
      ...VISUAL_NOTIFICATIONS_FILTERS.FLAG_ON_FIELD,
      fixtureActions,
    }),
    possibleVar: findShowAndHideActions({
      ...VISUAL_NOTIFICATIONS_FILTERS.POSSIBLE_VAR,
      fixtureActions,
    }),
  } as VisualNotifications;
};

export const findShowAndHideActions = ({
  showFilters,
  hideFilters,
  fixtureActions,
}: FindShowAndHideActionsProps) => {
  const showAction = fixtureActions.find((fixtureAction) =>
    generateFindActionRules(showFilters, fixtureAction)
  )?.fixtureSeqNum;

  const hideAction = fixtureActions.find((fixtureAction) =>
    generateFindActionRules(hideFilters, fixtureAction)
  )?.fixtureSeqNum;

  return {
    showSnack: showAction ? showAction : -1,
    hideSnack: hideAction ? hideAction : -1,
    isActive: (showAction || -1) > (hideAction || -1),
  };
};

export const generateNewVisualNotificationsResults = (
  newAction: VisualNotificationMember,
  previousAction: VisualNotificationMember
): VisualNotificationMember => {
  const newSnack = {
    showSnack:
      newAction.showSnack > previousAction.showSnack
        ? newAction.showSnack
        : previousAction.showSnack,
    hideSnack:
      newAction.hideSnack > previousAction.hideSnack
        ? newAction.hideSnack
        : previousAction.hideSnack,
  };

  return {
    ...newSnack,
    isActive: newSnack.showSnack > newSnack.hideSnack,
  };
};

export const generateFindActionRules = (
  filters: NotificationsFilterType[],
  fixtureAction: FixtureAction
) =>
  filters.find((filter) =>
    !filter.sendType
      ? filter.actionType === fixtureAction.fixtureActionTypeId
      : filter.actionType === fixtureAction.fixtureActionTypeId &&
        filter.sendType === fixtureAction.sendTypeId
  );

export const generateSnackbarOptions = (
  type: keyof VisualNotifications,
  theme: Theme,
  options?: OptionsWithExtraProps<'info'>
): OptionsWithExtraProps<'info'> => ({
  ...DEFAULT_VISUAL_NOTIFICATIONS_SNACKBAR_OPTIONS,
  key: type,
  style: {
    backgroundColor: theme.palette.snackbarColors[type].backgroundColor,
    color: theme.palette.getContrastText(
      theme.palette.snackbarColors[type].backgroundColor
    ),
    minWidth: '25rem',
  },
  ...options,
});
