import { PropsWithChildren, useContext } from 'react';
import type { FixtureAction } from '@/service/types';
import { ScoringContext } from '@/contexts/scoring/context';
import {
  SCORING_WORKER_HOST_ACTION,
  UpdateActionFlagMsg,
} from '@/workers/scoring/types';
import {
  ActionFilter,
  useActionsFilters,
} from '../ActionFilters/useActionsFilters';
import {
  ActionsTableContext,
  ActionsTableContextType,
} from './ActionsTableContext';

export interface ActionsTableContextProviderProps extends PropsWithChildren {
  initialFilters?: ActionFilter[];
  actions?: FixtureAction[];
}

export const ActionsTableProvider = ({
  initialFilters,
  actions,
  children,
}: ActionsTableContextProviderProps) => {
  const { useDispatchManyWithResponse } = useContext(ScoringContext);

  const actionsFilters = useActionsFilters({ initialFilters, actions });
  const dispatchFlagUpdate = useDispatchManyWithResponse<UpdateActionFlagMsg>(
    SCORING_WORKER_HOST_ACTION.UPDATE_ACTION_FLAG
  );
  const providerValue: ActionsTableContextType = {
    ...actionsFilters,
    dispatchFlagUpdate,
  };

  return (
    <ActionsTableContext.Provider value={providerValue}>
      {children}
    </ActionsTableContext.Provider>
  );
};
