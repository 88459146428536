import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material';
import { CollectionStatusId, FixtureStatusId } from '@/service/constants';
interface StatusesSelectProps extends SelectProps {
  id: string;
  labelId: string;
  label: string;
  options?: {
    name: string;
    value: string | number | CollectionStatusId | FixtureStatusId;
  }[];
  disabled?: boolean;
}

export const StatusesSelect = ({
  id,
  label,
  labelId,
  value,
  options,
  onChange,
  disabled,
  ...props
}: StatusesSelectProps) => {
  return (
    <FormControl sx={{ flex: 1, minWidth: 250 }} disabled={disabled}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        value={value}
        onChange={onChange}
        id={id}
        labelId={labelId}
        label={label}
        {...props}
      >
        {options?.map(({ value, name }) => (
          <MenuItem key={name + value} value={value}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
