import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
} from '@mui/material';
import { FC, JSXElementConstructor } from 'react';
import { LoadingOverlay } from '../common/LoadingOverlay';

export type ContextMenuItem = {
  Icon: JSXElementConstructor<any>;
  label: string | JSX.Element;
  onClick: () => void;
  key?: string;
  isLoading?: boolean;
};
export type ContextMenuProps = {
  items: ContextMenuItem[];
};

export const ContextMenu: FC<ContextMenuProps> = ({ items }) => {
  return (
    <Paper elevation={10} sx={{ width: 'fit-content', maxWidth: '100%' }}>
      <MenuList variant='menu' sx={{ py: 0 }}>
        {items.map(
          (
            { onClick, Icon, label, key, isLoading = false },
            index,
            { length }
          ) => (
            <MenuItem
              key={key || label.toString()}
              divider={index < length - 1}
              onClick={onClick}
              disabled={isLoading}
            >
              <ListItemIcon>
                <Icon fontSize='small' />
              </ListItemIcon>
              <ListItemText
                disableTypography
                sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
              >
                {label}
              </ListItemText>
              <LoadingOverlay isLoading={isLoading} size={'1.5em'} />
            </MenuItem>
          )
        )}
      </MenuList>
    </Paper>
  );
};
