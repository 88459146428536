import {
  ChecklistWarning,
  ChecklistCheckboxState,
} from '@/contexts/checklist/types';
import {
  MATCH_ACTION_SEND_TYPE,
  SCORING_APP_STATUS,
} from '@/service/constants';
import {
  FixtureAction,
  FixtureConfig,
  FixtureSummary,
  Player,
} from '@/service/types';
import { CHECKLIST_ELEMENT_TYPE } from '@/service/types/checklist';
import { getDifferenceByProp } from '@/utils/array';
import { CHECKLIST_CRUCIAL_ACTION_TYPE_ID } from '@/utils/checklist';
import { CHECKLIST_TIME_THRESHOLD, CHECKLIST_WARNING } from './constants';
// Importing itself for nested functions spying in unit tests
import * as utils from './utils';

const {
  DEVICE_LOCATION,
  LINEUPS,
  MATCH_CONDITIONS,
  POST_MATCH_CHECK_COMPLETE,
  TEAM_COLOURS,
} = CHECKLIST_CRUCIAL_ACTION_TYPE_ID;

export function isDeviceLocationSent(actions: FixtureAction[]) {
  return actions.some(
    ({ fixtureActionTypeId }) => fixtureActionTypeId === DEVICE_LOCATION
  );
}
export function isMatchConditionsSent(actions: FixtureAction[]) {
  return actions.some(
    ({ fixtureActionTypeId }) => fixtureActionTypeId === MATCH_CONDITIONS
  );
}
export function isTeamColourSent(actions: FixtureAction[]) {
  return actions.some(
    ({ fixtureActionTypeId }) => fixtureActionTypeId === TEAM_COLOURS
  );
}
export function isPostMatchCheckCompleteSent(actions: FixtureAction[]) {
  const [latestPostMatchCheckAction] = actions
    .filter(
      ({ fixtureActionTypeId }) =>
        fixtureActionTypeId === POST_MATCH_CHECK_COMPLETE
    )
    .sort((a, b) => b.fixtureSeqNum - a.fixtureSeqNum);

  return (
    !!latestPostMatchCheckAction &&
    [MATCH_ACTION_SEND_TYPE.CONFIRMED, MATCH_ACTION_SEND_TYPE.UPDATED].includes(
      latestPostMatchCheckAction.sendTypeId
    )
  );
}
export function isLineupsSent(actions: FixtureAction[]) {
  return actions.some(
    ({ fixtureActionTypeId }) => fixtureActionTypeId === LINEUPS
  );
}

export function composeMissingPlayersNames(players: Player[]) {
  return players.reduce<string>((playersString, player, index) => {
    if (index !== 0) playersString += ', ';
    playersString += player.fullName;

    return playersString;
  }, '');
}

const missingPlayersMemo: {
  players: Player[];
  config?: FixtureConfig;
  summary?: FixtureSummary;
} = { players: [] };
export function getMissingPlayers(
  config: FixtureConfig,
  summary: FixtureSummary
): Player[] {
  if (
    missingPlayersMemo.config === config &&
    missingPlayersMemo.summary === summary
  ) {
    return missingPlayersMemo.players;
  }

  const homePlayersOutOfSquad = getDifferenceByProp<Player>(
    summary.homeTeam.players,
    config.homeSquad.players,
    'id'
  );
  const awayPlayersOutOfSquad = getDifferenceByProp<Player>(
    summary.awayTeam.players,
    config.awaySquad.players,
    'id'
  );
  missingPlayersMemo.config = config;
  missingPlayersMemo.summary = summary;

  return (missingPlayersMemo.players = [
    ...homePlayersOutOfSquad,
    ...awayPlayersOutOfSquad,
  ]);
}

export function calculateRemainingTime(startDate: Date) {
  return startDate.getTime() - new Date().getTime();
}

export interface CheckboxValidateParams {
  checkbox: ChecklistCheckboxState;
  remainingMinutes: number;
  actions?: FixtureAction[];
  config?: FixtureConfig;
  summary?: FixtureSummary;
}
export type CheckboxValidateResult = Partial<ChecklistCheckboxState> & {
  isReadyToCheck: boolean;
  warnings: ChecklistWarning[];
};

export function validateCheckbox({
  checkbox,
  remainingMinutes,
  actions,
  config,
  summary,
}: CheckboxValidateParams): CheckboxValidateResult {
  const result: CheckboxValidateResult = {
    isReadyToCheck: false,
    warnings: [],
    requiresConfirmation: false,
  };
  if (!actions) return result;
  const { type } = checkbox;
  switch (type) {
    case CHECKLIST_ELEMENT_TYPE.ATTENDANCE_GEO:
      result.isReadyToCheck = utils.isDeviceLocationSent(actions);
      break;
    case CHECKLIST_ELEMENT_TYPE.MATCH_CONDITIONS:
      result.isReadyToCheck = utils.isMatchConditionsSent(actions);
      break;
    case CHECKLIST_ELEMENT_TYPE.TEAM_COLOURS:
      result.isReadyToCheck = utils.isTeamColourSent(actions);
      break;
    case CHECKLIST_ELEMENT_TYPE.LINEUPS:
      if (!(config && summary)) return result;
      const isLineupActionSent = utils.isLineupsSent(actions);
      const missingPlayers = utils.getMissingPlayers(config, summary);
      result.isReadyToCheck = isLineupActionSent && !missingPlayers.length;
      if (missingPlayers.length > 0) {
        const squadWarning = Object.assign({}, CHECKLIST_WARNING.LINEUPS_SQUAD);
        squadWarning.message +=
          utils.composeMissingPlayersNames(missingPlayers);
        result.warnings = [squadWarning, ...result.warnings];
      }
      break;
    case CHECKLIST_ELEMENT_TYPE.POST_MATCH_CHECK_COMPLETE:
      result.isReadyToCheck = utils.isPostMatchCheckCompleteSent(actions);
      result.requiresConfirmation =
        checkbox.isChecked || !utils.isPostMatchCheckCompleteSent(actions);
      break;
  }
  const timeConstraints = CHECKLIST_TIME_THRESHOLD[type];
  const timeoutWarning = CHECKLIST_WARNING.TIMEOUT[type];
  if (
    !checkbox.isChecked &&
    !result.isReadyToCheck &&
    timeConstraints !== undefined &&
    remainingMinutes < timeConstraints &&
    timeoutWarning !== undefined
  ) {
    result.warnings = [...result.warnings, timeoutWarning];
  }

  return result;
}

export function isDisabled({
  checkbox,
  summary,
}: CheckboxValidateParams): boolean {
  if (checkbox.type === CHECKLIST_ELEMENT_TYPE.POST_MATCH_CHECK_COMPLETE) {
    return !!summary
      ? summary.currentStatus !== SCORING_APP_STATUS.COMPLETED
      : true;
  }
  return false;
}
