import { Box, BoxProps } from '@mui/material';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import { FC } from 'react';
import {
  FIXTURE_ACTION_SUBTYPE,
  FIXTURE_ACTION_TYPE,
} from '@/service/constants';
import { FixtureAction } from '@/service/types';
import { isInteger } from '@/utils/number';

type ActionIconsProp = {
  action: FixtureAction;
};

const CardIcon: FC<BoxProps> = (props) => {
  return <Box width='0.75em' height='1em' borderRadius='0.14em' {...props} />;
};

const ACTION_TYPE_ICON: Record<number, JSX.Element> = {
  [FIXTURE_ACTION_TYPE.GOAL]: <SportsSoccerIcon fontSize='inherit' />,
  [FIXTURE_ACTION_TYPE.YELLOW_CARD]: (
    <CardIcon
      sx={{ bgcolor: (theme) => theme.palette.iconsColors.yellowCard }}
    />
  ),
  [FIXTURE_ACTION_TYPE.RED_CARD]: (
    <CardIcon sx={{ bgcolor: (theme) => theme.palette.iconsColors.redCard }} />
  ),
};

const ACTION_SUBTYPE_ICON: Record<number, JSX.Element> = {
  [FIXTURE_ACTION_SUBTYPE.SECOND_YELLOW_CARD]: (
    <CardIcon
      sx={{
        backgroundImage: (theme) =>
          `linear-gradient(to bottom right, ${theme.palette.iconsColors.redCard} 50%, ${theme.palette.iconsColors.yellowCard} 50%)`,
      }}
    />
  ),
};

export const ActionIcons: FC<ActionIconsProp> = ({ action }) => {
  const actionTypeIcon =
    (isInteger(action.fixtureActionSubTypeId) &&
      ACTION_SUBTYPE_ICON[action.fixtureActionSubTypeId]) ||
    ACTION_TYPE_ICON[action.fixtureActionTypeId];
  if (!actionTypeIcon) return null;
  return (
    <Box
      width='1.50em'
      height='1.50em'
      sx={{
        border: (theme) => `1px solid ${theme.palette.iconsColors.borderColor}`,
      }}
      borderRadius='0.28em'
      display='flex'
      alignItems='center'
      justifyContent='center'
      fontSize='1rem'
    >
      {actionTypeIcon}
    </Box>
  );
};
