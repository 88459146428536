import { Button, Stack, Typography, useTheme } from '@mui/material';
import {
  COMMON_STRING,
  DELETE_CONFIRMATION_STRING,
} from '@/constants/dictionary';

interface CommentRemoveConfirmationProps {
  onRemove: VoidFunction;
  onClose: VoidFunction;
}

export const CommentRemoveConfirmation = ({
  onRemove,
  onClose,
}: CommentRemoveConfirmationProps) => {
  const theme = useTheme();
  return (
    <Stack maxWidth={250} p={1} gap={1}>
      <Typography
        variant='subtitle2'
        color={theme.palette.getContrastText(theme.palette.background.paper)}
      >
        {COMMON_STRING.CONFIRM}
      </Typography>
      <Typography variant='caption'>
        {DELETE_CONFIRMATION_STRING.COMMENT}
      </Typography>
      <Stack flexDirection='row' justifyContent='end' gap={1}>
        <Button size='small' variant='contained' onClick={onRemove}>
          {COMMON_STRING.CONFIRM}
        </Button>
        <Button size='small' variant='outlined' onClick={onClose}>
          {COMMON_STRING.CANCEL}
        </Button>
      </Stack>
    </Stack>
  );
};
