import {
  AmericanFootballFixtureState,
  MatchSummaryTeam,
  YardLine,
} from '@/service/types';
import { getShortTeamName } from '@/components/FixtureTabs/helpers/getTeamName';
import {
  DOWN_DESCRIPTION,
  GAME_STATE,
} from '@/components/FixtureSummary/constants';
import { COMMON_STRING } from '@/constants/dictionary';

interface FixtureSummaryUtilsProps {
  homeTeam: MatchSummaryTeam;
  awayTeam: MatchSummaryTeam;
}
interface GenerateGameStateProps extends FixtureSummaryUtilsProps {
  americanFootballStatistics: AmericanFootballFixtureState;
}

interface GenerateYardDescriptionProps extends FixtureSummaryUtilsProps {
  yardLine: YardLine;
}

export const generateGameState = ({
  americanFootballStatistics,
  homeTeam,
  awayTeam,
}: GenerateGameStateProps) => {
  const { gameState, downNumber, yardsLeft, yardLine } =
    americanFootballStatistics;

  switch (gameState) {
    case GAME_STATE.KICKOFF:
      return COMMON_STRING.KICKOFF;
    case GAME_STATE.REGULAR_SNAP:
      return `${
        DOWN_DESCRIPTION[downNumber]
      } & ${yardsLeft} ${generateYardDescription({
        yardLine,
        homeTeam,
        awayTeam,
      })}`;
    case GAME_STATE.PAT:
      return COMMON_STRING.PAT;
    default:
      return '';
  }
};

export const generateYardDescription = ({
  yardLine,
  homeTeam,
  awayTeam,
}: GenerateYardDescriptionProps) => {
  const { yard, currentYardLineZone } = yardLine;

  switch (currentYardLineZone) {
    case 0:
      return yard;
    case 1:
      return `${getShortTeamName(awayTeam)} ${yard}`;
    case 2:
      return `${getShortTeamName(homeTeam)} ${yard}`;
    default:
      return '';
  }
};
