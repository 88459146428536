import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useContext, useMemo } from 'react';
import {
  ScoresheetTableBodyRow,
  ScoresheetTableCell,
  ScoresheetTableHeaderCell,
} from '@/components/ScoresheetTables/common/ScoresheetTableStyledComponents';
import {
  LEADS_TABLE_CELLS,
  LEADS_TABLE_KEYS,
} from '@/components/ScoresheetTables/LeadsTable/constants';
import { generateLeadsTableCell } from '@/components/ScoresheetTables/LeadsTable/utils';
import { convertPascalCaseTextToSentence } from '@/utils';
import { ScoresheetTableProps } from '@/components/ScoresheetTables/common/types';
import { BasketballStatsTableData } from '@/components/BasketballStatsTable/types';
import { StatsContext } from '@/contexts/stats/context';
import {
  GameStatistics,
  PlayerGameStatistics,
  PlayerPeriodStatistics,
  TeamGameStatistics,
  TeamPeriodStatistics,
} from '@/service/types';
import { filterBasketballTableData } from '@/components/BasketballStatsTable/utils';
import { STATS_BUTTONS } from '@/contexts/stats/constants';
import { ScoringContext } from '@/contexts/scoring/context';

export const LeadsTable = ({ tableData }: ScoresheetTableProps) => {
  const {
    teamButtons,
    activeButtons: { quarter, team },
  } = useContext(StatsContext);
  const {
    state: { fixtureSummary },
  } = useContext(ScoringContext);

  const gameData = useMemo(() => {
    if (!fixtureSummary) return;
    return filterBasketballTableData({
      activeQuarter: quarter,
      activeType: STATS_BUTTONS.TYPE.GAME,
      activeTeam: team,
      activeStatsType: STATS_BUTTONS.STATS.GAME,
      advancedSportStatistics: fixtureSummary.advancedSportStatistics,
    }) as BasketballStatsTableData[];
  }, [fixtureSummary, quarter, team]);

  const tableKeys = Object.keys(
    LEADS_TABLE_CELLS
  ) as (keyof (TeamGameStatistics &
    PlayerGameStatistics &
    PlayerPeriodStatistics &
    GameStatistics &
    TeamPeriodStatistics))[];

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <ScoresheetTableHeaderCell key={'leads-keys-column'} />
            {teamButtons.map((team, index) => (
              <ScoresheetTableHeaderCell key={`leads-team-column-${index}`}>
                {team.value}
              </ScoresheetTableHeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableKeys.map((key, keyIndex) => (
            <ScoresheetTableBodyRow key={key}>
              <ScoresheetTableCell>
                {convertPascalCaseTextToSentence(key)}
              </ScoresheetTableCell>
              {key !== LEADS_TABLE_KEYS.LEAD_CHANGES &&
              key !== LEADS_TABLE_KEYS.TIMES_TIED
                ? teamButtons.map((teamButton, teamButtonIndex) =>
                    generateLeadsTableCell({
                      tableData: tableData?.find(
                        (team) => team.teamId === teamButton.id
                      ),
                      key: key,
                      index: `${keyIndex}-${teamButtonIndex}`,
                    })
                  )
                : generateLeadsTableCell({
                    gameData: gameData?.[0],
                    key: key,
                    index: `${keyIndex}`,
                  })}
            </ScoresheetTableBodyRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
