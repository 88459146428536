import React, { FC, useRef } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import basketballCourtImagePath from '@/assets/court/court.png';

interface CourtCanvasProps {
  courtX: number;
  courtY: number;
}

interface BasketballCourtDimensions {
  width: number;
  height: number;
}

interface CourtPosition {
  x: number;
  y: number;
  zone: string;
}

export const CourtCanvas: FC<CourtCanvasProps> = ({ courtX, courtY }) => {
  let canvasRef = useRef<HTMLCanvasElement | null>(null);
  let canvasCtxRef = useRef<CanvasRenderingContext2D | null>(null);
  const basketballCourtDimensions: BasketballCourtDimensions = {
    width: 1750,
    height: 2800,
  };
  const basketballCourtMargin = 25;
  const basketballCourtImage = new Image();

  useEffectOnce(() => {
    if (canvasRef.current) {
      canvasCtxRef.current = canvasRef.current.getContext('2d');
      let ctx = canvasCtxRef.current;

      const convertDimensionsToPixel = (
        x: number,
        y: number
      ): CourtPosition => {
        let pixelDimensions = { x: 0, y: 0, zone: '' } as CourtPosition;
        if (canvasRef.current) {
          let xCoefficient =
            canvasRef.current.width / basketballCourtDimensions.width;
          let yCoefficient =
            (canvasRef.current.height * 2 - basketballCourtMargin * 2) /
            basketballCourtDimensions.height;

          let pixelX = x * xCoefficient;
          let pixelY = y * yCoefficient;

          let center = getBasketballCourtCenterPoint();

          pixelDimensions.x = center.x + pixelX;
          pixelDimensions.y = center.y - pixelY;
        }
        return pixelDimensions;
      };

      const getBasketballCourtCenterPoint = (): CourtPosition => {
        if (canvasRef.current) {
          let x = canvasRef.current.width / 2;
          let y = canvasRef.current.height - basketballCourtMargin;

          return { x: x, y: y, zone: '' };
        }
        return { x: 0, y: 0, zone: '' };
      };

      const drawBasketballCourt = () => {
        if (canvasRef.current) {
          canvasRef.current.width = 425;
          canvasRef.current.height = 400;
          basketballCourtImage.onload = () => {
            if (ctx) {
              ctx.drawImage(basketballCourtImage, 0, 0);
              let courtPosition = convertDimensionsToPixel(courtX, courtY);
              drawPoint(courtPosition.x, courtPosition.y);
            }
          };
          basketballCourtImage.src = basketballCourtImagePath;
        }
      };

      const drawPoint = (x: number, y: number) => {
        ctx!.beginPath();
        ctx!.arc(x, y, 7, 0, 2 * Math.PI, true);
        ctx!.fill();
        ctx!.stroke();
        ctx!.beginPath();
        ctx!.arc(x, y, 8, 0, 2 * Math.PI, true);
        ctx!.strokeStyle = 'red';
        ctx!.stroke();
      };
      drawBasketballCourt();
    }
  });

  return <canvas ref={canvasRef} />;
};
