import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useContext } from 'react';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { Checklist } from '@/components/Checklist';
import { DICTIONARY } from '@/constants/dictionary';
import { PANEL } from '@/contexts/UIState/types';
import { ChecklistButton } from '@/components/Checklist/ChecklistButton';
import { ScoringContext } from '@/contexts/scoring/context';
import { UIStateContext } from '@/contexts/UIState/context';
import { KeyboardButton } from '../common/KeyboardButton';
import { ConnectionStatus } from '../ConnectionStatus';
import { LightDivider } from '../common/LightDivider';
import { DATA_COLLECTORS_BUTTON_LABEL, FIXTURE_INFO_BUTTON_LABEL } from '.';

export const FixtureNavbarItems = () => {
  const { state } = useContext(ScoringContext);
  const closeChecklist = () => setOpenedPanel(null);

  const {
    openedPanel,
    setOpenedPanel,
    isFixtureInfoVisible,
    setFixtureInfoVisible,
    isDataCollectorsVisible,
    setIsDataCollectorsVisible,
  } = useContext(UIStateContext);

  if (!state.fixtureId) return <></>;

  return (
    <>
      <ConnectionStatus
        label={'Scorer'}
        isConnected={state && state.isScorerConnected}
      />
      <LightDivider orientation='vertical' flexItem />

      <Tooltip
        title={
          <>
            {DICTIONARY.COMMON.FIXTURE_INFO}
            <KeyboardButton theKey='i' />
          </>
        }
      >
        <IconButton
          aria-label={FIXTURE_INFO_BUTTON_LABEL}
          color='inherit'
          onClick={() => setFixtureInfoVisible(!isFixtureInfoVisible)}
        >
          <InfoOutlined />
        </IconButton>
      </Tooltip>
      <LightDivider orientation='vertical' flexItem />
      <Tooltip
        title={
          <>
            {DICTIONARY.COMMON.DATA_COLLECTORS}
            <KeyboardButton theKey='d' />
          </>
        }
      >
        <IconButton
          aria-label={DATA_COLLECTORS_BUTTON_LABEL}
          color='inherit'
          onClick={() => setIsDataCollectorsVisible(!isDataCollectorsVisible)}
        >
          <SupervisedUserCircleIcon />
        </IconButton>
      </Tooltip>
      <LightDivider orientation='vertical' flexItem />

      <ChecklistButton />

      <Drawer
        anchor='right'
        open={openedPanel === PANEL.CHECKLIST}
        onClose={closeChecklist}
        keepMounted
      >
        <Checklist onClose={closeChecklist} />
      </Drawer>

      <LightDivider orientation='vertical' flexItem />
    </>
  );
};
