import {
  ScoresheetMainPlayerTableCell,
  ScoresheetPlayerTableCell,
  ScoresheetTableBodyRow,
} from '@/components/ScoresheetTables/common/ScoresheetTableStyledComponents';
import { getPlayer } from '@/components/FixtureTabs/helpers/getPlayerNameAndNumber';
import {
  FixtureConfig,
  FixtureSummary,
  PlayerGameStatistics,
  PlayerPeriodStatistics,
} from '@/service/types';

interface PlayerRowProps {
  playerId: string;
  statistics?: PlayerGameStatistics & PlayerPeriodStatistics;
  fixtureSummary: FixtureSummary | null;
  fixtureConfig?: FixtureConfig;
}

export const PlayerRow = ({
  playerId,
  statistics,
  fixtureSummary,
  fixtureConfig,
}: PlayerRowProps) => {
  const player = getPlayer(playerId, fixtureConfig, fixtureSummary);

  return (
    <ScoresheetTableBodyRow>
      <ScoresheetMainPlayerTableCell>
        {player && player.isStartingSquad && '*'}
        {player?.shirtNumber}
      </ScoresheetMainPlayerTableCell>
      <ScoresheetMainPlayerTableCell>
        {player?.fullName}
      </ScoresheetMainPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {statistics?.minutesPlayedString}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {statistics?.fieldGoalsMade}/{statistics?.fieldGoalAttempts}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {statistics?.fieldGoalsPercentage}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {statistics?.twoPointersMade}/{statistics?.twoPointerAttempts}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {statistics?.twoPointersPercentage}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {statistics?.threePointersMade}/{statistics?.threePointerAttempts}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {statistics?.threePointersPercentage}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {statistics?.freeThrowsMade}/{statistics?.freeThrowAttempts}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {statistics?.freeThrowsPercentage}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {statistics?.offensiveRebounds}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {statistics?.defensiveRebounds}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {statistics?.totalRebounds}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {statistics?.assists}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {statistics?.turnovers}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {statistics?.steals}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {statistics?.blocks}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {statistics?.blocksAgainst}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {statistics?.personalFouls}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {statistics?.foulsAgainst}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {statistics?.plusMinus}
      </ScoresheetPlayerTableCell>
      <ScoresheetPlayerTableCell>
        {statistics?.totalPoints}
      </ScoresheetPlayerTableCell>
    </ScoresheetTableBodyRow>
  );
};
