import { FixtureSummary, StatusReason } from '@/service/types';
import { StatusesEditDialogState } from '@/components/StatusesEditDialog/types';
import {
  DEFAULT_REASON,
  EMPTY_REASON,
  REASON_CODE,
} from '@/components/StatusesEditDialog/constants';
import {
  CollectionStatusId,
  CoverageLevelId,
  FixtureStatusId,
} from '@/service/constants';
import { convertPascalCaseTextToSentence } from '@/utils';

interface SetDefaultReasonValueProps {
  key: ReasonKeyType;
  newStatusValue: FixtureStatusId | CollectionStatusId | CoverageLevelId;
  defaultStatusValue?: FixtureStatusId | CollectionStatusId | CoverageLevelId;
  defaultReason?: StatusReason['reason'] | null;
  defaultReasonCode?: StatusReason['code'] | null;
}

type ReasonKeyType = 'fixtureStatus' | 'collectionStatus' | 'coverageLevel';

const REASON_KEYS: ReasonKeyType[] = [
  'fixtureStatus',
  'collectionStatus',
  'coverageLevel',
];

export const generateInitialStatusesState = (
  fixtureSummary: FixtureSummary | null
) => {
  const generateInitialReasonByKey = (key: ReasonKeyType) => ({
    [`${key}ReasonCode`]:
      fixtureSummary === null
        ? EMPTY_REASON.code
        : !fixtureSummary?.[`${key}ReasonCode`]
        ? DEFAULT_REASON.code
        : fixtureSummary?.[`${key}ReasonCode`],
    [`${key}Reason`]:
      fixtureSummary === null
        ? EMPTY_REASON.reason
        : !fixtureSummary?.[`${key}ReasonCode`]
        ? DEFAULT_REASON.reason
        : fixtureSummary?.[`${key}ReasonCode`] === REASON_CODE.OTHER
        ? fixtureSummary?.[`${key}Reason`]
        : EMPTY_REASON.reason,
  });

  return {
    fixtureStatus: fixtureSummary === null ? '' : fixtureSummary.fixtureStatus,
    collectionStatus:
      fixtureSummary === null ? '' : fixtureSummary.collectionStatus,
    coverageLevel: fixtureSummary === null ? 0 : fixtureSummary.coverageLevel,
    ...Object.fromEntries(
      REASON_KEYS.map((key) => generateInitialReasonByKey(key)).flatMap(
        Object.entries
      )
    ),
  } as StatusesEditDialogState;
};

export const statusesReducer = (
  state: StatusesEditDialogState,
  newValue: Partial<StatusesEditDialogState>
): StatusesEditDialogState => {
  return { ...state, ...newValue };
};

export const validateStatusesChanges = (
  fixtureSummary: FixtureSummary | null,
  statusesState: StatusesEditDialogState
) => {
  if (fixtureSummary === null) return true;

  const validateReasonByKey = (key: ReasonKeyType) =>
    statusesState?.[`${key}ReasonCode`] !== undefined &&
    (statusesState?.[`${key}ReasonCode`] === REASON_CODE.OTHER
      ? !!statusesState?.[`${key}Reason`]?.length
      : statusesState?.[`${key}ReasonCode`] !== undefined);

  const isFixtureStatusChanged =
    statusesState.fixtureStatus !== fixtureSummary?.fixtureStatus;

  const isCollectionStatusChanged =
    statusesState.collectionStatus !== fixtureSummary?.collectionStatus;
  const isCoverageLevelChanged =
    statusesState.coverageLevel !== fixtureSummary?.coverageLevel;

  const isValidChanges = {
    changes: [
      isFixtureStatusChanged,
      isCollectionStatusChanged,
      isCoverageLevelChanged,
    ],
    reasons: REASON_KEYS.map((key) => validateReasonByKey(key)),
  };

  const calculatedValidChanges = isValidChanges.changes.filter(
    (change) => change
  ).length;
  const calculatedValidReasons = isValidChanges.reasons.filter(
    (reason) => reason
  ).length;

  return !(
    calculatedValidChanges &&
    calculatedValidReasons &&
    calculatedValidReasons === isValidChanges.reasons.length
  );
};

export const setDefaultReasonValue = ({
  newStatusValue,
  key,
  defaultStatusValue,
  defaultReason,
  defaultReasonCode,
}: SetDefaultReasonValueProps) => {
  return newStatusValue === defaultStatusValue &&
    defaultReason &&
    defaultReasonCode
    ? {
        [`${key}Reason`]:
          defaultReasonCode !== REASON_CODE.OTHER
            ? DEFAULT_REASON.reason
            : (defaultReason as StatusReason['reason']),
        [`${key}ReasonCode`]: defaultReasonCode as StatusReason['code'],
      }
    : {
        [`${key}Reason`]: DEFAULT_REASON.reason,
        [`${key}ReasonCode`]: DEFAULT_REASON.code,
      };
};

export const generateReasonsForSubmit = (
  state: StatusesEditDialogState,
  fixtureSummary: FixtureSummary | null
) => {
  const generateReasonValueByKey = (key: ReasonKeyType) => ({
    [`${key}Reason`]:
      fixtureSummary?.[key] === state[key] ||
      state[`${key}ReasonCode`] !== REASON_CODE.OTHER
        ? EMPTY_REASON.reason
        : state[`${key}Reason`],
    [`${key}ReasonCode`]:
      fixtureSummary?.[key] === state[key]
        ? EMPTY_REASON.code
        : state[`${key}ReasonCode`],
  });

  return Object.fromEntries(
    REASON_KEYS.map((key) => generateReasonValueByKey(key)).flatMap(
      Object.entries
    )
  );
};

export const createStatusesSuccessMessage = (
  statusesState: StatusesEditDialogState,
  fixtureSummary: FixtureSummary | null
) => {
  if (fixtureSummary === null) return;
  const updatedItems = REASON_KEYS.map((key) =>
    statusesState[key] !== fixtureSummary[key]
      ? convertPascalCaseTextToSentence(key)
      : ''
  )
    .filter((updated) => !!updated)
    .join(', ');
  if (!updatedItems.length) return;
  return `${updatedItems} successfully updated`;
};
