import { Stack } from '@mui/system';
import { FC, useContext, useReducer } from 'react';
import { useAuthToken } from '@/contexts/auth/useAuthToken';
import { ScoringContext } from '@/contexts/scoring/context';
import { useFixtureConfig } from '@/service/hooks/useFixtureConfig';
import { FixtureAction } from '@/service/types';
import {
  SCORING_WORKER_HOST_ACTION,
  UpdateFixtureActionMsg,
} from '@/workers/scoring/types';
import {
  FIXTURE_ACTION_SUBTYPE,
  FIXTURE_ACTION_TYPE,
} from '@/service/constants';
import { ActionSelect } from './ActionSelect';
import { EditButtons } from './EditButtons';
import { PlayersSelect } from './PlayersSelect';
import {
  actionsReducer,
  generateInitialState,
  isEditActionSaveButtonDisabled,
} from './utils';

export type EditActionProps = {
  action: FixtureAction;
  onCancel: VoidFunction;
  onSuccess: VoidFunction;
  onError: (error?: unknown) => void;
};

export const EditAction: FC<EditActionProps> = ({
  action,
  onError,
  onSuccess,
  onCancel,
}) => {
  const token = useAuthToken();
  const isOwnGoalActionSubType =
    action.fixtureActionTypeId === FIXTURE_ACTION_TYPE.GOAL &&
    action.fixtureActionSubTypeId === FIXTURE_ACTION_SUBTYPE.OWN_GOAL;
  const {
    state: { fixtureId, fixtureSummary },
    useDispatchWithResponse,
  } = useContext(ScoringContext);
  const { data: fixtureConfig } = useFixtureConfig({ token, fixtureId });
  const [editedAction, updateEditedAction] = useReducer(
    actionsReducer,
    generateInitialState({
      fixtureConfig,
      fixtureSummary,
      action,
    })
  );
  const { dispatch: updateAction, isLoading } =
    useDispatchWithResponse<UpdateFixtureActionMsg>(
      SCORING_WORKER_HOST_ACTION.UPDATE_FIXTURE_ACTION
    );

  const isChangeableActionTypeId =
    !!fixtureConfig?.fixtureOptions.actionButtons.some(
      ({ changeableActionTypeIds, actionType }) =>
        (actionType.id === action.fixtureActionTypeId &&
          changeableActionTypeIds?.length) ||
        action.fixtureActionTypeId === FIXTURE_ACTION_TYPE.GOAL
    );

  const isPlayerRequired = !!fixtureConfig?.fixtureOptions.actionButtons.some(
    ({ playerRequired, actionType }) =>
      actionType.id === editedAction.fixtureActionType?.id && playerRequired
  );

  const submit = async () => {
    updateAction(
      {
        fixtureId: fixtureId,
        fixtureActionId: action.id,
        newPlayerId: editedAction.player?.id,
        newTeamId: editedAction.teamId,
        newFixtureActionTypeId: editedAction.fixtureActionType?.id,
        newFixtureActionSubtypeId: editedAction.fixtureSubActionType?.id,
      },
      {
        showSnackbar: false,
      }
    )
      .catch(onError)
      .then(onSuccess);
  };

  return (
    <Stack gap={1.5} flex='1 0 0'>
      {isChangeableActionTypeId && (
        <ActionSelect
          action={action}
          editedAction={editedAction}
          isOwnGoalActionSubType={isOwnGoalActionSubType}
          fixtureConfig={fixtureConfig}
          onChange={updateEditedAction}
        />
      )}
      {isPlayerRequired && (
        <PlayersSelect
          editedAction={editedAction}
          onChange={updateEditedAction}
          action={action}
        />
      )}
      <EditButtons
        handleClose={onCancel}
        onSubmit={submit}
        disabled={isEditActionSaveButtonDisabled({
          editedAction,
          action,
          isPlayerRequired,
          isChangeableActionTypeId,
        })}
        isLoading={isLoading}
      />
    </Stack>
  );
};
