import { FixtureAction } from '@/service/types';
import {
  isActionFlagged,
  isActionPlayerUnknown,
} from '@/service/utils/actionConditions';
import {
  FIXTURE_ACTION_TYPE as FAT,
  FIXTURE_ACTION_TYPE,
} from '@/service/constants';
import { SOUND_ID, SOUND_NAME } from '../notifications/constants';
import { SoundId } from '../notifications/types';
import {
  ActionConditionSound,
  ActionSound,
  SoundsPreferences,
  SoundsSettings,
} from './types';

export const LS_KEY = {
  THEME: 'MT-theme',
  SOUNDS_PREFERENCES: 'MT-sounds-preferences',
  ACTION_SOUNDS: 'MT-action-sounds',
  ACTION_CONDITION_SOUNDS: 'MT-action-condition-sounds',
} as const;

// SOUND PREFERENCES
export const SOUND_DEFAULT_COLOR = {
  [SOUND_ID.SIREN]: '#CC2B1D',
  [SOUND_ID.DING_DING]: '#FFB84D',
  [SOUND_ID.WHISTLE]: '#65992C',
  [SOUND_ID.CROWD]: '#4994EC',
  [SOUND_ID.FAIL]: '#CB67E8',
  [SOUND_ID.FLICK]: '#6670F0',
} as const;

export const getDefaultSoundsSettings = (soundsIds: SoundId[]) => {
  return soundsIds.reduce<SoundsSettings>((acc, soundId) => {
    acc[soundId] = {
      id: soundId,
      name: SOUND_NAME[soundId],
      color: SOUND_DEFAULT_COLOR[soundId],
      volume: 1,
      muted: false,
    };
    return acc;
  }, {} as SoundsSettings);
};

export const SOUND_DEFAULT_PREFERENCES: SoundsPreferences = {
  volume: 1,
  muted: false,
  sounds: getDefaultSoundsSettings(Object.values(SOUND_ID)),
};

// ACTION SOUNDS PREFERENCES
export const DEFAULT_ACTION_SOUNDS: ActionSound[] = [
  [104, SOUND_ID.CROWD],
  [105, SOUND_ID.CROWD],
  [106, SOUND_ID.CROWD],

  [6, SOUND_ID.WHISTLE],
  [7, SOUND_ID.WHISTLE],
  [13, SOUND_ID.WHISTLE],
  [51, SOUND_ID.WHISTLE],
  [61, SOUND_ID.WHISTLE],
  [107, SOUND_ID.WHISTLE],
  [108, SOUND_ID.WHISTLE],
  [110, SOUND_ID.WHISTLE],
  [115, SOUND_ID.WHISTLE],

  [1, SOUND_ID.DING_DING],
  [2, SOUND_ID.DING_DING],
  [3, SOUND_ID.DING_DING],
  [18, SOUND_ID.DING_DING],
  [56, SOUND_ID.DING_DING],
  [66, SOUND_ID.DING_DING],
  [67, SOUND_ID.DING_DING],
];

export const ACTION_TYPE_PRIORITY: FIXTURE_ACTION_TYPE[] = [
  FAT['2PT_SHOT'],
  FAT['2PT_MADE'],
  FAT['2PT_MISSED'],
  FAT['3PT_SHOT'],
  FAT['3PT_MADE'],
  FAT['3PT_MISSED'],
  FAT.FT_AWARDED,
  FAT.FT_MADE,
  FAT.FT_MISSED,
  FAT.PLAYER_FOUL,
  FAT.BENCH_FOUL,
  FAT.COACH_FOUL,
  FAT.OFFENSIVE_REBOUND,
  FAT.DEFENSIVE_REBOUND,
  FAT.OFFENSIVE_TEAM_REBOUND,
  FAT.DEFENSIVE_TEAM_REBOUND,
  FAT.ASSIST,
  FAT.GOAL,
  FAT.PENALTY_AWARDED,
  FAT.RED_CARD,
  FAT.YELLOW_CARD,
  FAT.CORNER_TAKEN,
  FAT.CORNER_AWARDED,
  FAT.SUBSTITUTION_IN,
  FAT.SUBSTITUTION_OUT,
  FAT.VIDEO_CHECK_RESULT,
  FAT.VIDEO_CHECK_UNDERWAY,
];

export const ACTION_CONDITION_ID = {
  IS_FLAGGED: 0,
  IS_PLAYER_UNKNOWN: 1,
} as const;
export type ActionConditionId =
  (typeof ACTION_CONDITION_ID)[keyof typeof ACTION_CONDITION_ID];

export const ACTION_CONDITION_NAME: Record<ActionConditionId, string> = {
  [ACTION_CONDITION_ID.IS_FLAGGED]: 'Action flagged',
  [ACTION_CONDITION_ID.IS_PLAYER_UNKNOWN]: 'Unknown player',
} as const;

export const ACTION_CONDITION_FUNCTION: Record<
  ActionConditionId,
  (action: FixtureAction) => boolean
> = {
  [ACTION_CONDITION_ID.IS_FLAGGED]: isActionFlagged,
  [ACTION_CONDITION_ID.IS_PLAYER_UNKNOWN]: isActionPlayerUnknown,
};

export const DEFAULT_ACTION_CONDITION_SOUNDS: ActionConditionSound[] = [
  [ACTION_CONDITION_ID.IS_FLAGGED, SOUND_ID.FLICK],
  [ACTION_CONDITION_ID.IS_PLAYER_UNKNOWN, SOUND_ID.FLICK],
];

export const STATE_CHANGE_SOUND = {
  SCORER_DISCONNECTED: SOUND_ID.SIREN,
} as const;
export type StateChangeSound =
  (typeof STATE_CHANGE_SOUND)[keyof typeof STATE_CHANGE_SOUND];
