import { StatsCompareItem } from '@/components/StatsCompareTable';
import { COMMON_STRING } from '@/constants/dictionary';
import { FIXTURE_ACTION_TYPE } from '@/service/constants';
import { getGameActionAggregate } from '@/service/utils/getActionAggregates';
import { FixturePostMatchCheckStatistics, FixtureSummary } from '../types';

export interface SoccerSummaryStats {
  homeTeam: StatsCompareItem[];
  awayTeam: StatsCompareItem[];
}

export const getSoccerSummaryStats = (
  fixtureSummary: FixtureSummary,
  externalStats: FixturePostMatchCheckStatistics[]
): SoccerSummaryStats => {
  const internalRedCards = getGameActionAggregate(
    fixtureSummary,
    FIXTURE_ACTION_TYPE.RED_CARD
  );
  const internalYellowCards = getGameActionAggregate(
    fixtureSummary,
    FIXTURE_ACTION_TYPE.YELLOW_CARD
  );
  const internalCorners = getGameActionAggregate(
    fixtureSummary,
    FIXTURE_ACTION_TYPE.CORNER_TAKEN
  );
  const internalGoals = getGameActionAggregate(
    fixtureSummary,
    FIXTURE_ACTION_TYPE.GOAL
  );
  const internalSubstitutions = getGameActionAggregate(
    fixtureSummary,
    FIXTURE_ACTION_TYPE.SUBSTITUTION_IN
  );
  const externalHomeData = externalStats.find(
    (data) => data.statsType === COMMON_STRING.STATS.EXTERNAL_HOME
  );
  const externalAwayData = externalStats.find(
    (data) => data.statsType === COMMON_STRING.STATS.EXTERNAL_AWAY
  );

  return {
    homeTeam: [
      {
        name: 'Red cards',
        internalValue: internalRedCards.homeTeam?.actionAggregate ?? 0,
        externalValue: externalHomeData?.redCard?.overallValue ?? null,
      },
      {
        name: 'Yellow cards',
        internalValue: internalYellowCards.homeTeam?.actionAggregate ?? 0,
        externalValue: externalHomeData?.yellowCard?.overallValue ?? null,
      },
      {
        name: 'Corners taken',
        internalValue: internalCorners.homeTeam?.actionAggregate ?? 0,
        externalValue: externalHomeData?.corner?.overallValue ?? null,
      },
      {
        name: 'Goals',
        internalValue: internalGoals.homeTeam?.actionAggregate ?? 0,
        externalValue: externalHomeData?.goal?.overallValue ?? null,
      },
      {
        name: 'Substitutions',
        internalValue: internalSubstitutions.homeTeam?.actionAggregate ?? 0,
        externalValue: externalHomeData?.substitution?.overallValue ?? null,
      },
    ],
    awayTeam: [
      {
        name: 'Red cards',
        internalValue: internalRedCards.awayTeam?.actionAggregate ?? 0,
        externalValue: externalAwayData?.redCard?.overallValue ?? null,
      },
      {
        name: 'Yellow cards',
        internalValue: internalYellowCards.awayTeam?.actionAggregate ?? 0,
        externalValue: externalAwayData?.yellowCard?.overallValue ?? null,
      },
      {
        name: 'Corners taken',
        internalValue: internalCorners.awayTeam?.actionAggregate ?? 0,
        externalValue: externalAwayData?.corner?.overallValue ?? null,
      },
      {
        name: 'Goals',
        internalValue: internalGoals.awayTeam?.actionAggregate ?? 0,
        externalValue: externalAwayData?.goal?.overallValue ?? null,
      },
      {
        name: 'Substitutions',
        internalValue: internalSubstitutions.awayTeam?.actionAggregate ?? 0,
        externalValue: externalAwayData?.substitution?.overallValue ?? null,
      },
    ],
  };
};
