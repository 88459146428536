import {
  calculateRemainingTime,
  isDisabled,
  validateCheckbox,
} from '@/components/Checklist/utils';
import {
  CHECKLIST_ELEMENT_NAME,
  CHECKLIST_ELEMENT_TYPE,
} from '@/service/types/checklist';
import { DEFAULT_CHECKLIST_CHECKBOX_STATE } from './constants';
import {
  ChecklistCheckboxState,
  ChecklistContextState,
  ChecklistReducerAction,
  CHECKLIST_REDUCER_ACTION,
} from './types';

export const getDefaultCheckboxes = (): ChecklistCheckboxState[] =>
  Object.values(CHECKLIST_ELEMENT_TYPE)
    .map(
      (checklistElementType): ChecklistCheckboxState => ({
        isChecked: false,
        isDisabled: true,
        isReadyToCheck: false,
        name: CHECKLIST_ELEMENT_NAME[checklistElementType],
        type: checklistElementType,
        warnings: [],
        requiresConfirmation: false,
        ...DEFAULT_CHECKLIST_CHECKBOX_STATE[checklistElementType],
      })
    )
    .sort((a, b) => a.type - b.type);

export const getChecklistDefaultState = (): ChecklistContextState => {
  return getDefaultCheckboxes();
};

export const checklistReducer = (
  state: ChecklistContextState,
  dispatchedAction: ChecklistReducerAction
): ChecklistContextState => {
  const { action, payload } = dispatchedAction;
  const checkboxes = state.length > 0 ? state : getDefaultCheckboxes();
  switch (action) {
    case CHECKLIST_REDUCER_ACTION.CHECKBOXES:
      return checkboxes.map((checkbox) => ({
        ...checkbox,
        isChecked: payload.includes(checkbox.type),
      }));
    case CHECKLIST_REDUCER_ACTION.VALIDATE:
      const { actions, config, summary } = payload;
      const remainingMinutes = Math.floor(
        calculateRemainingTime(new Date(config.fixture.startDateUTC)) /
          (1000 * 60)
      );

      return checkboxes.map((checkbox) => {
        const validationParams = {
          checkbox,
          remainingMinutes,
          actions,
          config,
          summary,
        };
        const validationResult = validateCheckbox(validationParams);

        return {
          ...checkbox,
          isDisabled: isDisabled(validationParams),
          ...validationResult,
        };
      });

    default:
      return state;
  }
};
