import { ENVIRONMENT } from '@/constants/environment';
import { noOp } from '@/utils';
import { Konsole } from './Konsole';
import { SS_KEY, WELCOME_MESSAGE } from './constants';

export function getSessionLogValue() {
  return sessionStorage.getItem(SS_KEY);
}

const initialLogSessionValue = getSessionLogValue();
let _isLogEnabled = !!Number(initialLogSessionValue || 0);

type LogChangeCallback = (v?: boolean) => void;
const _onLogEnabledChangeCallbacks: LogChangeCallback[] = [];
export function addLogChangeCallback(cb: LogChangeCallback) {
  _onLogEnabledChangeCallbacks.push(cb);
}

export function removeLogChangeCallback(cb: LogChangeCallback) {
  _onLogEnabledChangeCallbacks.splice(
    _onLogEnabledChangeCallbacks.indexOf(cb),
    1
  );
}

export function getIsLogEnabled() {
  return _isLogEnabled;
}

export function setIsLogEnabled(v: boolean) {
  _isLogEnabled = v;
  sessionStorage.setItem(SS_KEY, v ? '1' : '0');
  _onLogEnabledChangeCallbacks.length &&
    _onLogEnabledChangeCallbacks.forEach((cb) => {
      cb(v);
    });
}

if (window && !window._mt) {
  window._mt = Object.create(
    {},
    {
      log: {
        get: getIsLogEnabled,
        set: setIsLogEnabled,
      },
    }
  );
}
const { LOCAL, DEV, PRODUCTION, SANDBOX, STAGING } = ENVIRONMENT;
const MODE = import.meta.env.MODE as ENVIRONMENT;
if (MODE === LOCAL && !initialLogSessionValue) {
  setIsLogEnabled(true);
}

if (
  [DEV, PRODUCTION, SANDBOX, STAGING].includes(MODE) &&
  !getSessionLogValue()
) {
  console.log(WELCOME_MESSAGE, Konsole.messageStyle);
}

const konsoleProxyHandler: ProxyHandler<Konsole> = {
  get: function (target, prop) {
    if (!getIsLogEnabled()) return noOp;
    return Reflect.get(target, prop);
  },
};

export const konsole = new Proxy(new Konsole(), konsoleProxyHandler);
