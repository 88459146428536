import { Dialog, DialogContent } from '@mui/material';

import { DialogTitleWithClose } from '@/components/common/DialogTitleWithClose';
import { Preferences } from '@/components/Preferences/Preferences';

interface PreferencesDialogProps {
  open: boolean;
  onClose: VoidFunction;
}

export const PreferencesDialog = ({
  open,
  onClose,
}: PreferencesDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      PaperProps={{
        sx: {
          height: '100%',
        },
        elevation: 5,
      }}
    >
      <DialogTitleWithClose close={onClose}>Preferences</DialogTitleWithClose>

      <DialogContent dividers sx={{ display: 'flex', padding: 0 }}>
        <Preferences />
      </DialogContent>
    </Dialog>
  );
};
