import { useMemo } from 'react';
import { FixtureConfig } from '../types';

import { useFixtureConfig } from './useFixtureConfig';

export type FixtureInfoDisplay = [FIXTURE_INFO_LABEL, string];

export enum FIXTURE_INFO_LABEL {
  NAME = 'Name',
  TYPE = 'Type',
  START_TIME = 'Start Time',
  START_DATE = 'Start Date',
  SPORT = 'Sport',
  COMPETITION = 'Competition',
  VENUE = 'Venue',
  CITY = 'City',
  COUNTRY = 'Country',
}

export const getFixtureDetails = (
  fixture: FixtureConfig['fixture']
): Array<FixtureInfoDisplay> => {
  const date = new Date(fixture.startDateUTC);
  const dateString = date.toLocaleDateString(undefined, {
    dateStyle: 'medium',
  });
  const timeString = date.toLocaleTimeString(undefined, {
    timeStyle: 'short',
  });
  const { location } = fixture.venue;
  return [
    [FIXTURE_INFO_LABEL.NAME, fixture.name],
    [FIXTURE_INFO_LABEL.TYPE, fixture.type],
    [FIXTURE_INFO_LABEL.START_TIME, timeString],
    [FIXTURE_INFO_LABEL.START_DATE, dateString],
    [
      FIXTURE_INFO_LABEL.SPORT,
      fixture.stage.season.competition?.sport.name || '',
    ],
    [
      FIXTURE_INFO_LABEL.COMPETITION,
      fixture.stage.season.competition?.name || '',
    ],
    [FIXTURE_INFO_LABEL.VENUE, fixture.venue.name],
    [FIXTURE_INFO_LABEL.CITY, location.name],
    [
      FIXTURE_INFO_LABEL.COUNTRY,
      (location.country && location.country.name) || '',
    ],
  ];
};

interface UseFixtureConfigParams {
  token: string | undefined;
  fixtureId: string;
}

export const useFixtureDetails = ({
  token,
  fixtureId,
}: UseFixtureConfigParams) => {
  const { data, error, isLoading, mutate } = useFixtureConfig({
    token,
    fixtureId,
  });

  const fixtureDetails = useMemo(
    () => (data ? getFixtureDetails(data.fixture) : null),
    [data]
  );

  return {
    data: fixtureDetails,
    error,
    isLoading,
    mutate,
  };
};
