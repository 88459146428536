import { FIXTURE_ACTION_FLAG_STATE } from '@/service/constants';

export const COMMON_STRING = {
  CHECKLIST: 'Checklist',
  FIXTURE_INFO: 'Fixture Info',
  DATA_COLLECTORS: 'Data Collectors',
  NOTIFICATIONS: 'Notifications',
  USER_AVATAR_TOOLTIP: 'Settings',
  FILTER_BY: 'Filter by',
  ADD_FILTER: 'Add filter',
  CLEAR_FILTERS: 'Clear filters',
  UNDO_FILTER: 'Undo filter',
  FILTERS: 'Filters',
  EXPORT_XLSX: 'Export XLSX',
  EXPORT_CSV: 'Export CSV',
  COPY: 'Copy',
  SHOW: 'Show',
  ACTION_COMMENT: 'Action comment',
  COMMENT: 'Comment',
  EDIT_ACTION: 'Edit Action',
  FLAG: {
    [FIXTURE_ACTION_FLAG_STATE.UNCONFIRMEND]: 'Confirm flag',
    [FIXTURE_ACTION_FLAG_STATE.CONFIRMED]: 'Unconfirm flag',
  },
  DEVICE_LOCATION: 'Device Location',
  COURT_POSITION: 'Court Position',
  APPLY: 'Apply',
  REMOVE: 'Remove',
  ADD: 'Add',
  SAVE: 'Save',
  CANCEL: 'Cancel',
  GO_TO_EXTERNAL_STATISTICS: 'Go To External Statistics',
  GO_TO_TOP: 'Go To Top',
  REFRESH: 'Refresh',
  STATS: {
    INTERNAL_HOME: 'Internal Home',
    INTERNAL_AWAY: 'Internal Away',
    EXTERNAL_HOME: 'External Home',
    EXTERNAL_AWAY: 'External Away',
  },
  SUBMIT: 'Submit',
  SELECT_ALL: 'Select All',
  NONE: 'None',
  ACTION_JSON: 'Action JSON',
  CLOSE: 'Close',
  OVERWRITE: 'Overwrite',
  UPDATE: 'Update',
  SYNC: 'Sync',
  OK: 'OK',
  NAME: 'Name',
  SURNAME: 'Surname',
  LAST_5_RATINGS: 'Last 5 ratings',
  COMPLETED_MATCHES: 'Completed matches',
  FLAG_ON_FIELD: 'Flag on field',
  NO_VAR: 'NO VAR',
  POSSIBLE_VAR_ACTIVATED: 'Possible VAR activated - market closed',
  CONFIRM: 'Confirm',
  GAME_STATE: 'Game state',
  KICKOFF: 'Kickoff',
  PAT: 'PAT',
  REPORT_GENERATED: 'Report generated',
  DOWNLOAD: 'Download',
  OPEN_IN_A_NEW_TAB: 'Open in a new tab',
  TOTALS: 'Totals',
  COACH: 'Team/Coach',
  ALL_QUARTERS: 'All quarters',
  DID_NOT_PARTICIPATED: 'DNP',
} as const;

export const SUCCESS_STRING = {
  COPIED_TO_CLIPBOARD: 'Copied to clipboard',
  FLAG_UPDATED: 'Flag updated',
  ACTION_SUCCESSFULLY_ADDED: 'Action successfully added',
};

export const DELETE_CONFIRMATION_STRING = {
  COMMENT: 'Are you sure you want to remove this comment?',
};
export const ERROR_STRING = {
  FIXTURE_ID_MISSING: 'Missing fixture id',
  FEATURE_NOT_SUPPORTED: 'Sorry, this is not yet supported',
} as const;

export const PREFERENCES_STRING = {
  SAVE_USER_DEFAULT: 'Save as My Default',
  APPLY_USER_DEFAULT: 'Apply My Default',
  APPLY_APP_DEFAULT: 'Apply App Default',
};

export const PERIODS_STRING = {
  3: '1st half extra time',
  4: '2nd half extra time',
  5: 'Penalty shootout',
};

export const DICTIONARY = {
  COMMON: COMMON_STRING,
  ERROR: ERROR_STRING,
  SUCCESS: SUCCESS_STRING,
  PREFERENCES: PREFERENCES_STRING,
  DELETE: DELETE_CONFIRMATION_STRING,
  PERIODS: PERIODS_STRING,
} as const;
