import { useState, useEffect } from 'react';
import { useSessionStorage } from 'usehooks-ts';
import { konsole } from '@/utils/konsole';
import { FixtureAction } from '@/service/types';
import { AnyPrimitive } from '@/types/common';
import { FILTERS_HISTORY_MAX_LENGTH } from './constants';
import { filterActions } from './utils';

export interface ActionFilter {
  property: keyof FixtureAction;
  value: AnyPrimitive;
  displayName: string;
  displayValue: string;
  exclude?: boolean;
}

export interface UseActionsFiltersProps {
  actions?: FixtureAction[];
  initialFilters?: ActionFilter[];
}
export const SS_HISTORY_KEY = 'MT-filters-history';

export const useActionsFilters = ({
  actions,
  initialFilters,
}: UseActionsFiltersProps) => {
  const [displayActions, setDisplayActions] = useState<FixtureAction[]>(
    actions || []
  );
  const [filtersHistory, setFiltersHistory] = useSessionStorage<
    ActionFilter[][]
  >(SS_HISTORY_KEY, []);

  const [filters, setNewFilters] = useState<ActionFilter[]>(
    initialFilters ?? filtersHistory.at(-1) ?? []
  );

  useEffect(() => {
    if (!actions) return;
    if (!filters.length) return setDisplayActions(actions);
    setDisplayActions(filterActions(filters, actions));
  }, [actions, filters]);

  const undoFilters = () => {
    if (!filtersHistory || !filtersHistory.length) return;
    const newHistory = [...filtersHistory];
    newHistory.pop();
    const prevFilters = newHistory.at(-1) || [];
    setNewFilters(prevFilters);
    setFiltersHistory(newHistory);
    konsole.groupCollapsed('Filters');
    konsole.table('New Filters', prevFilters);
    konsole.log('Filters history', newHistory);
    konsole.groupEnd();
  };
  const setFilters = (newFilters: ActionFilter[]) => {
    const newHistory = [...filtersHistory, newFilters];
    newHistory.length > FILTERS_HISTORY_MAX_LENGTH && newHistory.shift();
    setFiltersHistory(newHistory);
    setNewFilters(newFilters);
    konsole.groupCollapsed('Filters');
    konsole.table('New Filters', newFilters);
    konsole.log('Filters history', newHistory);
    konsole.groupEnd();
  };

  const onFilterRemove = (filterIndex: number) => {
    if (!filters || !filters.length) return;
    const newFilters = [...filters];
    newFilters.splice(filterIndex, 1);
    setFilters(newFilters);
  };

  const clearFilters = () => {
    setFilters([]);
  };

  return {
    actions,
    displayActions,
    filters,
    filtersHistory,
    undoFilters,
    setFilters,
    onFilterRemove,
    clearFilters,
  };
};
