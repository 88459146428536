import { FC, MouseEvent, useCallback, useContext, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PowerSettingsNew from '@mui/icons-material/PowerSettingsNew';
import Settings from '@mui/icons-material/Settings';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import { useOktaAuth } from '@okta/okta-react';
import Menu, { MenuProps } from '@mui/material/Menu';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { ThemeToggle } from '@/components/ThemeToggle';
import { DICTIONARY } from '@/constants/dictionary';
import { PreferencesDialog } from '@/components/PreferencesDialog/PreferencesDialog';
import { UIStateContext } from '@/contexts/UIState/context';
import { PANEL } from '@/contexts/UIState/types';
import { KeyboardButton } from '../common/KeyboardButton';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    autoFocus={false}
    elevation={10}
    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiMenu-list': {
    padding: 0,
  },
  '& .MuiMenuItem-root': {
    padding: theme.spacing(1.5, 1.5),
  },
  '& .MuiMenuItem-root.disableHover': {
    cursor: 'unset',
  },
  '& .MuiMenuItem-root.disableHover:hover': {
    backgroundColor: 'inherit',
  },
}));

export const UserMenu: FC = () => {
  const [menuAnchorEl, setMenuAnchorElUser] = useState<null | HTMLElement>(
    null
  );
  const { openedPanel, setOpenedPanel } = useContext(UIStateContext);
  const { oktaAuth, authState } = useOktaAuth();
  const username = authState?.idToken?.claims.name ?? '';
  const email = authState?.idToken?.claims.email ?? '';

  const userInitials = useCallback(() => {
    const [[first], [second]] = username.split(' ');
    return first + second;
  }, [username]);

  const onAvatarClick = (event: MouseEvent<HTMLElement>) =>
    setMenuAnchorElUser(event.currentTarget);

  const closeMenu = () => setMenuAnchorElUser(null);

  const openPreferences = () => {
    setOpenedPanel(PANEL.PREFERENCES);
    closeMenu();
  };

  const closePreferences = () => setOpenedPanel(null);

  const logout = async () => oktaAuth.signOut();

  return (
    <>
      <Tooltip title={DICTIONARY.COMMON.USER_AVATAR_TOOLTIP}>
        <IconButton color={'inherit'} onClick={onAvatarClick}>
          <Avatar>{userInitials()}</Avatar>
        </IconButton>
      </Tooltip>
      <StyledMenu
        id='menu-appbar'
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={closeMenu}
      >
        <Box px={1.5} pt={1.5}>
          <Typography fontWeight={500}>{username}</Typography>
          <Typography>{email}</Typography>
        </Box>
        <MenuItem divider disableRipple className='disableHover'>
          <ThemeToggle />
        </MenuItem>

        <MenuItem divider onClick={openPreferences}>
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText
            disableTypography
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexGrow: 1,
            }}
          >
            Preferences <KeyboardButton theKey='p' />
          </ListItemText>
        </MenuItem>

        <MenuItem onClick={logout}>
          <ListItemIcon>
            <PowerSettingsNew />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </StyledMenu>
      <PreferencesDialog
        open={openedPanel === PANEL.PREFERENCES}
        onClose={closePreferences}
      />
    </>
  );
};
