import { createContext } from 'react';
import { FixtureAction } from '@/service/types';
import { UpdateActionFlagMsg } from '@/workers/scoring/types';
import { UseDispatchManyWithResponse } from '@/contexts/scoring/createUseDispatchManyWithResponse';
import { ActionFilter } from '../ActionFilters/useActionsFilters';

export type ActionsTableContextType = {
  actions: FixtureAction[] | undefined;
  displayActions: FixtureAction[];
  filters: ActionFilter[];
  filtersHistory: ActionFilter[][];
  undoFilters: () => void;
  setFilters: (newFilters: ActionFilter[]) => void;
  onFilterRemove: (filterIndex: number) => void;
  clearFilters: () => void;
  dispatchFlagUpdate: UseDispatchManyWithResponse<UpdateActionFlagMsg>;
};

export const ActionsTableContext = createContext<ActionsTableContextType>({
  actions: undefined,
  displayActions: [],
  filters: [],
  filtersHistory: [],
  undoFilters: () => {},
  setFilters: () => {},
  onFilterRemove: () => {},
  clearFilters: () => {},
  dispatchFlagUpdate: {
    isLoading: false,
    pendingRequests: [],
    dispatch: () => Promise.reject(),
  },
});
